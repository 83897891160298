import React, { Component } from 'react'
import { Input, Text, Button, Modal } from '@bufferapp/ui'
import { Form, FormBlock, Checkbox } from '../../../account-components'

import * as Styles from './style'

class DeleteAccountForm extends Component {
  state = {
    confirmDelete: false,
    reason: '',
    details: '',
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  handleConfirmDelete = (e) => {
    this.setState({ confirmDelete: e.target.checked })
  }

  renderOptions = () => {
    const items = [
      {
        id: 'dontUseAnymore',
        title: "I don't use the product anymore",
      },
      {
        id: 'expensive',
        title: 'Too expensive',
        selected: false,
      },
      {
        id: 'missingFeatures',
        title: 'Missing features',
      },
      {
        id: 'difficultToUse',
        title: 'Difficult to use',
      },
      {
        id: 'other',
        title: 'Other',
      },
    ]

    return items.map((item, index) => {
      return (
        <option value={item.id} key={index}>
          {item.title}
        </option>
      )
    })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  handleSelectOnChange = (e) => {
    e.preventDefault()
    this.setState({ reason: e.target.value || '' })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  handleInputOnChange = (e) => {
    e.preventDefault()
    this.setState({ details: e.target.value || '' })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  handleSubmit = (e) => {
    e.preventDefault()
    const { reason, details } = this.state
    // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onSubmit({
      reason,
      details,
    })
  }

  render() {
    return (
      <Modal>
        <Styles.ModalWrapper>
          <Form
            header={{
              title: "You're about to delete your Buffer account",
            }}
            onSubmit={this.handleSubmit}
          >
            <FormBlock withBackground>
              <Styles.SelectWrapper>
                <label htmlFor="reason">
                  What is the main reason why you&apos;re deleting your Buffer
                  account?
                </label>
                <select
                  id="reason"
                  name="reason"
                  onChange={this.handleSelectOnChange}
                >
                  {/* @ts-expect-error TS(2322) FIXME: Type 'true' is not assignable to type 'string | nu... Remove this comment to see the full error message */}
                  <option value="" defaultValue>
                    Select a reason
                  </option>
                  {this.renderOptions()}
                </select>
              </Styles.SelectWrapper>
              <Input
                // @ts-expect-error TS(2322) FIXME: Type '{ name: string; label: string; onChange: (e:... Remove this comment to see the full error message
                name="details"
                label="Could you provide more detail about your answer above?"
                onChange={this.handleInputOnChange}
              />
            </FormBlock>
            <FormBlock inline align="left">
              <Text type="p">
                Please keep in mind that{' '}
                <strong>deleting your Buffer account is permanent</strong> and
                will delete all account data stored with Buffer. This includes
                publishing, engagement, and analytics. However, we will not
                remove any historical posts made to a channel.
              </Text>
              {/* @ts-expect-error TS(2741) FIXME: Property 'checked' is missing in type '{ name: str... Remove this comment to see the full error message */}
              <Checkbox
                name="confirmDelete"
                label="Yes, I'd like to delete my account"
                onChange={this.handleConfirmDelete}
              />
            </FormBlock>
            <FormBlock align="right" inline condensed>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
              <Button
                type="text"
                label="Cancel"
                // @ts-expect-error TS(2339) FIXME: Property 'onCancel' does not exist on type 'Readon... Remove this comment to see the full error message
                onClick={this.props.onCancel}
              />
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; disabled: boo... Remove this comment to see the full error message */}
              <Button
                type="primary"
                label="Delete my account"
                disabled={!this.state.confirmDelete}
                onClick={() => {}}
              />
            </FormBlock>
          </Form>
        </Styles.ModalWrapper>
      </Modal>
    )
  }
}

export default DeleteAccountForm
