import styled from 'styled-components'

export const ModalHeader = styled.div`
  padding: 0 16px 16px 16px;
  width: 100%;
  display: flex;
`

export const IconWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;
  svg {
    fill: #b8b8b8;
  }
`

export const LeftIconWrapper = styled.div`
  padding: 2px 8px 0 0;

  svg {
    fill: #e0364f;
  }
`

export const IllustrationWrapper = styled.div`
  height: 230px;
  background-image: url('https://s3.amazonaws.com/static.buffer.com/images/account/fb-missing-permissions%402x.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`
