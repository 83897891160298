import React from 'react'
import PropTypes from 'prop-types'

import { handleBalanceCopy, handleDiscountCopy } from './utils'
import type { BalanceAndDicountProps } from './types'
import { NoticeWrapper } from './styles'

export const BalanceAndDiscount = ({
  balance,
  discount,
}: BalanceAndDicountProps): JSX.Element => {
  return (
    <NoticeWrapper data-testid="balanceAndDiscount">
      {(balance !== 0 || discount) && (
        <ul>
          {balance !== 0 && handleBalanceCopy(balance)}
          {discount && (
            <li>
              You have a discount of{' '}
              <strong>{handleDiscountCopy(discount)}</strong>.
            </li>
          )}
        </ul>
      )}
    </NoticeWrapper>
  )
}

BalanceAndDiscount.propTypes = {
  balance: PropTypes.number,
  discount: PropTypes.shape({
    type: PropTypes.oneOf(['percent', 'amount']),
    value: PropTypes.number,
    duration: PropTypes.oneOf(['forever', 'once', 'repeating']),
    durationInMonths: PropTypes.number,
  }),
}

BalanceAndDiscount.defaultProps = {
  balance: 0,
  discount: null,
}

export default BalanceAndDiscount
