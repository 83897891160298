import React from 'react'
import * as Styles from './style'

// @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
const Checkbox = ({ name, label, checked, onChange }) => {
  return (
    <Styles.InputWrapper>
      <Styles.LabelStyled>
        <Styles.InputStyled
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <Styles.TextStrongStyled>{label}</Styles.TextStrongStyled>
      </Styles.LabelStyled>
    </Styles.InputWrapper>
  )
}

export default Checkbox
