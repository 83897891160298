import React from 'react'
import { Modal, Button, Text } from '@bufferapp/ui'

import { H3, Strong } from '../Text'
import { ModalContent, ModalFooter, Title } from './Modal'
import styled from 'styled-components'
import ToggleSwitch from './ToggleSwitch'
import { HC_UTM_PARAMS } from '../../../shared-utils/constants'

const LeftAlign = styled.section`
  margin-right: auto;
`

const PlanPicker = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const ModalWrapper = styled.section`
  padding-top: 1rem;
  margin-top: -1rem;
  max-height: calc(80vh - 80px);
  overflow: scroll;
  width: 100%;
`

const PlanCard = styled.section<{
  selected: boolean
}>`
  background: white;
  border: ${(props) =>
    props.selected ? '2px solid #0047FF' : '1px solid #b8b8b8'};
  border-radius: 4px;
  height: 535px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
`

const Feature = styled.li`
  &:before {
    content: '';
    display: inline-block;
    background-image: url(https://static.buffer.com/images/account/checkmark.svg);
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const FeatureList = styled.ul`
  margin: 0;
  margin-top: 1rem;
  padding: 0;
`

const PlanImage = styled.img`
  margin: 2rem 0;
  max-height: 118px;
`

const Pricing = styled.section`
  margin-top: auto;
  margin-bottom: 1rem;
`

const Price = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: #3d3d3d;
  margin-bottom: 0.5rem;
`

const Interval = styled.span`
  font-size: 0.75rem;
  color: #3d3d3d;
`

const Billing = styled.span`
  font-size: 0.75rem;
  color: #3d3d3d;
  display: block;
`

const Wrapper = styled.section`
  display: flex;
  justify-content: space-around;
  margin: 0.75rem 0 1.75rem;
`

const CYCLE_ABBREVIATIONS = {
  month: 'mo',
  year: 'yr',
}

const Content = styled(ModalContent)`
  & > :first-child {
    margin-bottom: 1rem;
    display: block;
  }
`

const Plan = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  name,
  // @ts-expect-error TS(7031) FIXME: Binding element 'image' implicitly has an 'any' ty... Remove this comment to see the full error message
  image,
  // @ts-expect-error TS(7031) FIXME: Binding element 'features' implicitly has an 'any'... Remove this comment to see the full error message
  features,
  // @ts-expect-error TS(7031) FIXME: Binding element 'month' implicitly has an 'any' ty... Remove this comment to see the full error message
  month,
  // @ts-expect-error TS(7031) FIXME: Binding element 'year' implicitly has an 'any' typ... Remove this comment to see the full error message
  year,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedCycle' implicitly has an ... Remove this comment to see the full error message
  selectedCycle,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isCurrentPlan' implicitly has an ... Remove this comment to see the full error message
  isCurrentPlan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selected' implicitly has an 'any'... Remove this comment to see the full error message
  selected,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectPlan' implicitly has an 'an... Remove this comment to see the full error message
  selectPlan,
}) => {
  const price = selectedCycle === 'month' ? month : year
  let label = 'Select Plan'
  if (isCurrentPlan) {
    label = 'Current Plan'
  } else if (selected) {
    label = 'Plan Selected'
  }
  return (
    <PlanCard selected={selected}>
      {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
      <Text>
        <H3>{name}</H3>
      </Text>
      <PlanImage src={image} />
      <FeatureList>
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'feature' implicitly has an 'any' type. */}
        {features.map((feature, index) => (
          <Feature key={index}>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
            <Text>
              <Strong>{feature}</Strong>
            </Text>
          </Feature>
        ))}
      </FeatureList>
      <Pricing>
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
        <Text>
          <Price>${price}</Price>
          {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <Interval>/{CYCLE_ABBREVIATIONS[selectedCycle]}</Interval>
        </Text>
        <Billing>
          {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
          <Text>Billed {selectedCycle}ly</Text>
        </Billing>
      </Pricing>
      {/* @ts-expect-error TS(2740) FIXME: Type '{ fullWidth: true; label: string; type: stri... Remove this comment to see the full error message */}
      <Button
        fullWidth
        label={label}
        type="secondary"
        disabled={isCurrentPlan}
        onClick={selectPlan}
      />
    </PlanCard>
  )
}

export default class ChangePlanModal extends React.Component {
  state = {
    cycle: this.getCurrentPlanInterval(),
    selectedPlan: {
      id: this.getCurrentPlanId(),
      cycle: this.getCurrentPlanInterval(),
    },
    confirmationStep: false,
  }

  PLANS = {
    pro: {
      id: 'pro',
      name: 'Pro',
      year: 336,
      month: 35,
      features: [
        '8 social accounts',
        'In-depth social analytics',
        'Strategy recommendations',
        'Unlimited reports',
      ],
      image: 'https://static.buffer.com/images/account/analyze-pro.svg',
    },
    premium: {
      id: 'premium',
      name: 'Premium',
      year: 480,
      month: 50,
      features: [
        '10 social accounts',
        'Includes Stories analytics',
        'Strategy recommendations',
        'Unlimited reports',
        'Report white labeling',
      ],
      image: 'https://static.buffer.com/images/account/analyze-premium.svg',
    },
  }

  changeCycle() {
    this.setState({
      cycle: this.state.cycle === 'month' ? 'year' : 'month',
    })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  selectPlan(id) {
    this.setState({
      selectedPlan: {
        id,
        cycle: this.state.cycle,
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        price: this.PLANS[id][this.state.cycle],
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        name: this.PLANS[id].name,
      },
    })
  }

  getCurrentPlanId() {
    // @ts-expect-error TS(2339) FIXME: Property 'plan' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { plan } = this.props

    if (plan) {
      if ('id' in plan) {
        return plan.id.match(/\bpro\b/) ? 'pro' : 'premium'
      }
    }

    return ''
  }

  getCurrentPlanInterval() {
    // @ts-expect-error TS(2339) FIXME: Property 'plan' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { plan } = this.props

    if (plan) {
      if ('interval' in plan) {
        return plan.interval
      }
    }

    return 'month'
  }

  hasChangedPlans() {
    const currentPlanId = this.getCurrentPlanId()
    const interval = this.getCurrentPlanInterval()
    const selectedPlan = this.state.selectedPlan
    return !(
      currentPlanId === selectedPlan.id && interval === selectedPlan.cycle
    )
  }

  changePlan() {
    this.setState({
      confirmationStep: true,
    })
  }

  confirm() {
    // @ts-expect-error TS(2339) FIXME: Property 'changePlan' does not exist on type 'Read... Remove this comment to see the full error message
    this.props.changePlan(
      this.state.selectedPlan.id,
      this.state.selectedPlan.cycle,
    )
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'plan' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { plan, close, changingPlan } = this.props
    const planId = this.getCurrentPlanId()
    const currentPlan = planId ? this.PLANS[planId] : null
    if (this.state.confirmationStep) {
      return (
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; action: {}; }' is not... Remove this comment to see the full error message
        <Modal action={{}}>
          <Title>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
            <Text>
              <H3>Are you sure?</H3>
            </Text>
          </Title>
          <Content>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: (s... Remove this comment to see the full error message */}
            <Text>
              You’re about to switch to the{' '}
              {/* @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{ id: stri... Remove this comment to see the full error message */}
              <Strong>{this.state.selectedPlan.name}</Strong> plan with a{' '}
              <Strong>{this.state.selectedPlan.cycle}ly</Strong> billing cycle
              of{' '}
              <Strong>
                {/* @ts-expect-error TS(2339) FIXME: Property 'price' does not exist on type '{ id: str... Remove this comment to see the full error message */}
                ${this.state.selectedPlan.price}/{this.state.selectedPlan.cycle}
              </Strong>
              , with a new billing period starting today.
            </Text>
            <div />
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
            <Text>
              Any payments you’ve made will be prorated and credited to your
              account.
            </Text>
          </Content>
          <ModalFooter>
            <LeftAlign>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
              <Button
                type="text"
                label="Learn more about billing"
                onClick={() =>
                  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
                  (window.location = `https://support.buffer.com/article/542-changing-your-subscription-or-billing-details?${HC_UTM_PARAMS}`)
                }
              />
            </LeftAlign>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
            <Button
              type="text"
              label="Cancel"
              onClick={close}
              disabled={changingPlan}
            />
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; disabled: any... Remove this comment to see the full error message */}
            <Button
              type="primary"
              label={changingPlan ? 'One moment...' : "Yes, I'm sure"}
              disabled={changingPlan}
              onClick={this.confirm.bind(this)}
            />
          </ModalFooter>
        </Modal>
      )
    }
    return (
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; width: string; action... Remove this comment to see the full error message
      <Modal width="wide" action={{}}>
        <ModalWrapper>
          <Title>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
            <Text>
              <H3>My Analyze Plan</H3>
            </Text>
          </Title>
          <ModalContent>
            <Wrapper>
              {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: (s... Remove this comment to see the full error message */}
              <Text>
                You&apos;re currently on the{' '}
                <Strong>{currentPlan ? currentPlan.name : 'legacy'}</Strong>{' '}
                plan and billed <Strong>{plan.interval}ly</Strong>
              </Text>
              <ToggleSwitch
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                onClick={this.changeCycle.bind(this)}
                enabled={this.state.cycle === 'year'}
                leftLabel="Monthly"
                rightLabel="Yearly"
              />
            </Wrapper>
            <PlanPicker>
              <Plan
                selectPlan={() => this.selectPlan('pro')}
                {...this.PLANS.pro}
                selectedCycle={this.state.cycle}
                isCurrentPlan={
                  planId === 'pro' && plan.interval === this.state.cycle
                }
                selected={
                  this.state.selectedPlan.id === 'pro' &&
                  this.state.selectedPlan.cycle === this.state.cycle
                }
              />
              <Plan
                selectPlan={() => this.selectPlan('premium')}
                {...this.PLANS.premium}
                selectedCycle={this.state.cycle}
                isCurrentPlan={
                  planId === 'premium' && plan.interval === this.state.cycle
                }
                selected={
                  this.state.selectedPlan.id === 'premium' &&
                  this.state.selectedPlan.cycle === this.state.cycle
                }
              />
            </PlanPicker>
          </ModalContent>
        </ModalWrapper>
        <ModalFooter>
          <LeftAlign>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
            <Button
              type="text"
              label="Learn more about these plans"
              onClick={() =>
                // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
                (window.location = 'https://buffer.com/pricing/analyze')
              }
            />
          </LeftAlign>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
          <Button type="text" label="Cancel" onClick={close} />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
          <Button
            type="primary"
            label={'Change Plan & Billing'}
            onClick={this.changePlan.bind(this)}
            disabled={!this.hasChangedPlans()}
          />
        </ModalFooter>
      </Modal>
    )
  }
}
