import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, FormBlock, Form } from '../../../../account-components'
import { Button, Input } from '@bufferapp/ui'

import Modal from '../Modal'
import { UpdateConfirmationFormWrapper } from './style'

class UpdateConfirmationForm extends Component {
  state = {
    error: '',
    passwordAdded: false,
  }

  onChange = () => {
    this.setState({
      error: '',
      passwordAdded: true,
    })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onConfirmationSubmit = (e) => {
    e.preventDefault()
    const previousPassword = e.target[0].value
    // @ts-expect-error TS(2339) FIXME: Property 'newPassword' does not exist on type 'Rea... Remove this comment to see the full error message
    const { newPassword } = this.props

    if (previousPassword === newPassword) {
      this.setState({
        error:
          'Your new password must be different from your previous password.',
      })
      return
    }

    if (previousPassword) {
      // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
      const { onSubmit } = this.props
      if (onSubmit) onSubmit(e)
    } else {
      this.setState({
        error: 'A password is required.',
      })
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'onCancel' does not exist on type 'Readon... Remove this comment to see the full error message
    const { onCancel, modalCopy } = this.props
    const { error, passwordAdded } = this.state

    return (
      <Modal>
        <UpdateConfirmationFormWrapper>
          <Card>
            <Form
              header={{
                title: 'Authentication Required',
                subTitle: modalCopy,
              }}
              onSubmit={this.onConfirmationSubmit}
            >
              <FormBlock withBackground>
                <Input
                  // @ts-expect-error TS(2322) FIXME: Type '{ id: string; label: string; name: string; t... Remove this comment to see the full error message
                  id="currentPassword"
                  label="Password"
                  name="current-password"
                  type="password"
                  hasError={!!error}
                  help={error}
                  onChange={this.onChange}
                  autoComplete="off"
                />
              </FormBlock>
              <FormBlock align="right" inline condensed>
                {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
                <Button type="text" label="Cancel" onClick={onCancel} />
                {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
                <Button
                  type="primary"
                  label="Confirm"
                  onClick={() => {}}
                  disabled={!passwordAdded}
                />
              </FormBlock>
            </Form>
          </Card>
        </UpdateConfirmationFormWrapper>
      </Modal>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
UpdateConfirmationForm.propTypes = {
  modalCopy: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default UpdateConfirmationForm
