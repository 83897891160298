import React from 'react'
import { Text } from '@bufferapp/ui'
import { AddressBlockWrapper, AddressWrapper, TextCta } from '../styles'
import useGetPortalSession from '../hooks/useGetPortalSession'
import PropTypes from 'prop-types'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useAccount } from '../../../../../context/Account'

export type AddressProps = {
  address: {
    line1: string
    line2: string
    city: string
    state: string
    postalCode: string
    country: string
  } | null
}

const Address = ({ address }: AddressProps): JSX.Element => {
  const account = useAccount()
  const { currentOrganization } = account
  const { openBillingPortal, errorMessage } = useGetPortalSession()

  return (
    <AddressBlockWrapper>
      <Text type="h3">Address</Text>
      {errorMessage && (
        <Text type="help" hasError>
          {errorMessage}
        </Text>
      )}
      {address ? (
        <AddressWrapper>
          {address.line1 && <Text type="p">{address.line1}</Text>}
          {address.line2 && <Text type="p">{address.line2}</Text>}
          {address.country && (
            <Text type="p">
              {address.city} {address.state} {address.postalCode}{' '}
              {address.country}
            </Text>
          )}
        </AddressWrapper>
      ) : (
        <>
          <Text type="label" color="gray">
            You haven&apos;t added your address yet.&nbsp;
          </Text>
          <TextCta
            onClick={(): void => {
              openBillingPortal(true)
              BufferTracker.billingPortalSessionCreated({
                organizationId: currentOrganization?.id,
                customerId: currentOrganization?.billing?.gateway?.gatewayId,
                ctaApp: 'account',
                ctaView: 'billing',
                ctaLocation: 'billingInformation',
                ctaButton: 'addYourAddress',
                ctaVersion: '1',
              })
            }}
          >
            <Text type="label" color="blue">
              Add Your Address
            </Text>
          </TextCta>
        </>
      )}
    </AddressBlockWrapper>
  )
}

Address.prototype = {
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }),
}

export default Address
