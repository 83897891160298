import styled from 'styled-components'

export const ChannelBody = styled.section`
  width: 512px;
`

export const LabelWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`
