// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export function validPasswordLongitude(value) {
  return value.length >= 8
}

// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export function containsUppercase(value) {
  const result = value.match(/[A-Z]+/)
  return result && result.length > 0
}

// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export function containsNumberOrSymbol(value) {
  const result = value.match(/[^A-Za-z\s]+/)
  return result && result.length > 0
}

// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export function validEmail(value) {
  // @see http://emailregex.com/
  return value.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}
