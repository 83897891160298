import { type CallHistoryMethodAction, push } from 'connected-react-router'
import { matchPath } from 'react-router-dom'
import type { ChannelThirdPartyService } from '../gql/graphql'

// Routes utils
// @ts-expect-error TS(7031) FIXME: Binding element 'pathname' implicitly has an 'any'... Remove this comment to see the full error message
export const getMatch = ({ pathname, route }) =>
  matchPath(pathname, {
    path: route,
  })

// @ts-expect-error TS(7031) FIXME: Binding element 'pathname' implicitly has an 'any'... Remove this comment to see the full error message
export const getParams = ({ pathname, route }) => {
  const match = getMatch({ pathname, route })
  return match?.params || null
}

export const goTo = (path: string): CallHistoryMethodAction => push(path)

// Miscellaneous routes
export const generic = {
  route: '/',
  goTo: (): CallHistoryMethodAction => push('/'),
}

export const analyzePage = {
  route: '/analyze',
  goTo: (): CallHistoryMethodAction => push('/analyze'),
}

export const billingPage = {
  route: '/billing',
  goTo: (): CallHistoryMethodAction => push('/billing'),
}

export const billingStripePortalPage = {
  route: '/billing/portal',
  goTo: (): CallHistoryMethodAction => push('/billing/portal'),
}

export const newPlansPage = {
  route: '/new-plans',
  goTo: (): CallHistoryMethodAction => push('/new-plans'),
}

export const organizationPage = {
  route: '/organization',
  goTo: (): CallHistoryMethodAction => push('/organization'),
}

export const betaProgramPage = {
  route: '/beta',
  goTo: (): CallHistoryMethodAction => push('/beta'),
}

export const alphaProgramPage = {
  route: '/alpha',
  goTo: (): CallHistoryMethodAction => push('/alpha'),
}

export const accountDeletedConfirmationPage = {
  route: '/account-deleted-confirmation',
  goTo: (): CallHistoryMethodAction => push('/account-deleted-confirmation'),
}

// Channels Routes
export const channelsPage = {
  route: '/channels',
  goTo: (): CallHistoryMethodAction => push('/channels'),
}

export const channelsConnectPage = {
  route: '/channels/connect',
  goTo: (): CallHistoryMethodAction => push('/channels/connect'),
}

// Referral Routes
export const referralsPage = {
  route: '/referrals',
  goTo: (): CallHistoryMethodAction => push('/referrals'),
}

export const oAuthConnectionPage = {
  route: '/channels/authorization/:service',
  goTo: (service: ChannelThirdPartyService): CallHistoryMethodAction =>
    push(`/channels/authorization/${service}`),
}

// Onboarding Routes
// @ts-expect-error TS(7006) FIXME: Parameter 'product' implicitly has an 'any' type.
export const getProductOnboardingRoute = (product): string => {
  const base = '/onboarding'

  const routes = {
    base,
    publishing: `${base}/publishing`,
    analytics: `${base}/analytics`,
    engagement: `${base}/engagement`,
    startpage: `${base}/startpage`,
    buffer: `${base}/buffer`,
    survey: `${base}/survey`,
  }

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return routes[product] || base
}

export const onboardingPage = {
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  route: getProductOnboardingRoute(),
  getRoute: getProductOnboardingRoute,
  goTo: (product: string): CallHistoryMethodAction =>
    push(getProductOnboardingRoute(product)),
}
