import React from 'react'
import PropTypes from 'prop-types'

import { Button, Text, Modal, Notification } from '@bufferapp/ui'
import { Locked, Info } from '@bufferapp/ui/Icon'

import { Card, CardBody, CardFooter } from '../../../account-components'

import { StripeCreditCardForm } from '../CreditCardForm'
import { Strong } from '../../../billing-details/components/Text'

import { hasActiveTrial } from '../../../web/src/pages/Billing/utils/planHelpers'

import { HC_UTM_PARAMS } from '../../../shared-utils/constants'

import {
  CardDetails,
  CardDot,
  CardPreview,
  CardType,
  Content,
  DotsWrapper,
  ExpiryDate,
  ExpiryDateLabel,
  ModalHeader,
  Notice,
  NotificationWrapper,
  Wrapper,
  TrialMessagingWrapper,
  NoticeWrapper,
} from './styles'

const Dots = () => (
  <DotsWrapper>
    <CardDot />
    <CardDot />
    <CardDot />
    <CardDot />
  </DotsWrapper>
)

const ADDITIONAL_MESSAGING_FOR_TRIAL_USERS =
  'You are currently on a Teams Plan Trial. You will be charged at the end of the trial to the payment method on file.'

const CreditCardDetails = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'cardDetails' implicitly has an 'a... Remove this comment to see the full error message
  cardDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'closeCreditCardFormModal' implici... Remove this comment to see the full error message
  closeCreditCardFormModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'creditCardSuccessMessage' implici... Remove this comment to see the full error message
  creditCardSuccessMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentOrganization' implicitly h... Remove this comment to see the full error message
  currentOrganization,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isLoading' implicitly has an 'any... Remove this comment to see the full error message
  isLoading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSubmitting' implicitly has an '... Remove this comment to see the full error message
  isSubmitting,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openCreditCardFormModal' implicit... Remove this comment to see the full error message
  openCreditCardFormModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'resetCreditCardNotification' impl... Remove this comment to see the full error message
  resetCreditCardNotification,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showCreditCardFormModal' implicit... Remove this comment to see the full error message
  showCreditCardFormModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'withColor' implicitly has an 'any... Remove this comment to see the full error message
  withColor,
  // @ts-expect-error TS(7031) FIXME: Binding element 'withColor' implicitly has an 'any... Remove this comment to see the full error message
  showPaymentPastDue,
}) => {
  const renderButtonLabel = () => {
    if (isSubmitting) {
      return 'Saving Card...'
    } else if (isLoading) {
      return 'Loading...'
    } else if (!cardDetails) {
      return 'Add Card'
    } else {
      return 'Update Card'
    }
  }

  const isOnTrial = hasActiveTrial(currentOrganization)

  return (
    <Card addMargin title="Payment Method">
      {showPaymentPastDue && (
        <NoticeWrapper>
          <Info />
          <Text type="p">
            Your last payment failed. Please review your payment method.
          </Text>
        </NoticeWrapper>
      )}
      <SuccessNotification
        // @ts-expect-error TS(2322) FIXME: Type '{ creditCardSuccessMessage: any; resetCredit... Remove this comment to see the full error message
        creditCardSuccessMessage={creditCardSuccessMessage}
        resetCreditCardNotification={resetCreditCardNotification}
      />

      <Content>
        <CardBody>
          <CardDetails withColor={withColor}>
            {cardDetails ? (
              <React.Fragment>
                <CardType>
                  {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
                  <Text>{cardDetails.brand}</Text>
                </CardType>
                <CardPreview>
                  <Dots /> <Dots /> <Dots />{' '}
                  <Strong>{cardDetails.last4}</Strong>
                </CardPreview>
                <ExpiryDate>
                  <ExpiryDateLabel>Expires</ExpiryDateLabel>
                  <Strong>
                    {cardDetails.expMonth}/{cardDetails.expYear}
                  </Strong>
                </ExpiryDate>
              </React.Fragment>
            ) : currentOrganization?.privileges?.canManageBilling ? (
              <>
                <Text type="p">
                  Add a payment method so you can use advanced features or more
                  channels. This payment method will be available for all team
                  members with purchasing rights to use.
                  {isOnTrial && (
                    <TrialMessagingWrapper>
                      {ADDITIONAL_MESSAGING_FOR_TRIAL_USERS}
                    </TrialMessagingWrapper>
                  )}
                </Text>
              </>
            ) : (
              <Notice>
                <Text type="p">
                  Please confirm your email address to update your payment
                  method. Adding a payment method allows you to use advanced
                  features or add more channels. You can visit our{' '}
                  <a
                    href={`https://support.buffer.com/article/504-verifying-your-buffer-email-address?${HC_UTM_PARAMS}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    help guide
                  </a>{' '}
                  for more info.
                </Text>
              </Notice>
            )}
          </CardDetails>
        </CardBody>
        <CardFooter>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ disabled: boolean; type: string; label: st... Remove this comment to see the full error message */}
          <Button
            disabled={
              !currentOrganization?.privileges?.canManageBilling ||
              !currentOrganization?.canAddPaymentDetails
            }
            type="secondary"
            label={cardDetails ? 'Update Method' : 'Add Method'}
            onClick={openCreditCardFormModal}
          />
        </CardFooter>
      </Content>
      {showCreditCardFormModal && (
        // @ts-expect-error TS(2322)
        <Modal
          action={{ label: '' }}
          closeButton={{ callback: closeCreditCardFormModal }}
        >
          <Wrapper>
            <ModalHeader>
              <h3>Enter your payment method</h3>
              <Locked />
            </ModalHeader>
            <StripeCreditCardForm
              buttonLabel={renderButtonLabel()}
              closeAction={closeCreditCardFormModal}
              closeButtonLabel={'Cancel'}
              // todo: check if this can be local state
              isSubmitting={isSubmitting}
              isLoading={isLoading}
            />
          </Wrapper>
        </Modal>
      )}
    </Card>
  )
}

const SuccessNotification = React.memo(function SuccessNotification({
  // @ts-expect-error TS(2339) FIXME: Property 'creditCardSuccessMessage' does not exist... Remove this comment to see the full error message
  creditCardSuccessMessage,
  // @ts-expect-error TS(2339) FIXME: Property 'resetCreditCardNotification' does not ex... Remove this comment to see the full error message
  resetCreditCardNotification,
}) {
  if (!creditCardSuccessMessage) return null

  return (
    <NotificationWrapper>
      <Notification
        text={creditCardSuccessMessage}
        onClose={resetCreditCardNotification}
      />
    </NotificationWrapper>
  )
})

CreditCardDetails.propTypes = {
  cardDetails: PropTypes.object,
  closeCreditCardFormModal: PropTypes.func,
  creditCardSuccessMessage: PropTypes.string,
  currentOrganization: PropTypes.object,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  openCreditCardFormModal: PropTypes.func,
  resetCreditCardNotification: PropTypes.func,
  showCreditCardFormModal: PropTypes.bool,
  withColor: PropTypes.bool,
  showPaymentPastDue: PropTypes.bool,
}

CreditCardDetails.defaultProps = {
  withColor: false,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'Named... Remove this comment to see the full error message
SuccessNotification.propTypes = {
  resetCreditCardNotification: PropTypes.func,
  creditCardSuccessMessage: PropTypes.string,
}

export default CreditCardDetails
