import styled from 'styled-components'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import LockedIcon from '@bufferapp/ui/Icon/Icons/Locked'
import CircleCheckmarkIcon from '../../../../../../account-components/CircleCheckmarkIcon'

export const ChannelStatusWrapper = styled.div`
  display: flex;
  flex-basis: 10rem;
  justify-content: flex-end;
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0;
    cursor: pointer;
  }

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`

export const WarningIconStyled = styled(WarningIcon)`
  margin-right: 0.5rem;
`

export const LockedIconStyled = styled(LockedIcon)`
  margin-right: 0.5rem;
`

export const CircleCheckmarkIconStyled = styled(CircleCheckmarkIcon)`
  margin-right: 0.5rem;
`
