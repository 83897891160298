import { useSplitEnabled } from '@bufferapp/features'
import { Button, Link, Notice, Text } from '@bufferapp/ui'
import { Buffer as BufferIcon } from '@bufferapp/ui/Icon'
import React from 'react'
import { useAccount } from '../../../../context/Account'

import type { AppLink, AppStore, Plan } from '../../../../../types'
import {
  getAppsStoreLinks,
  getCurrentPlanFromAccount,
  getGatewayStore,
  hasActiveTrial,
  isFreePlan,
  isTieredPlan,
  shouldShowUpgradePaths,
} from '../../../Billing/utils/planHelpers'
import { CustomLink, NoticeContent, TextStyled } from './style'
import { getTrialStartDateInEpochsMs } from '../../../../../../shared-utils'
import { TRIAL_CHANNELS_LIMIT } from '../../utils/channels'

const UPGRADE_PLAN_CTA = 'channels-pricingNotice-upgradePlan-1'
const CHANGE_PLAN_CTA = 'channels-pricingNotice-changePlan-1'

const openPlanSelector = (cta: string): void => {
  const { MODALS, actions } = window?.appshell || {}
  actions.openModal(MODALS.planSelector, {
    cta,
    upgradePathName: 'channels-upgrade',
  })
}

const getBannerMobileCopy = (args: {
  plan: Plan
  store: AppStore
  appInfo: AppLink
}): JSX.Element => {
  const { plan, store, appInfo } = args
  return (
    <React.Fragment>
      You’re on the <strong>{plan.name}</strong> Plan. Your subscription is
      managed through the <strong>{store}</strong>. Open the{' '}
      {appInfo.link ? (
        <Link newTab fontWeight={700} href={appInfo.link}>
          {appInfo.app}
        </Link>
      ) : (
        appInfo.app
      )}{' '}
      app to add or remove channels.
    </React.Fragment>
  )
}

const getBannerCopy = (args: {
  plan: Plan
  channelLimit: number
  lockedChannelCount: number
  isOnTrial: boolean
  newTrialLimitEnabled: boolean
}): JSX.Element => {
  const {
    plan,
    channelLimit,
    lockedChannelCount,
    isOnTrial,
    newTrialLimitEnabled = false,
  } = args
  const { name, interval, prices } = plan
  const billedInterval = interval === 'year' ? 'month billed yearly' : interval
  const intervalName = interval === 'year' ? 'Yearly' : 'Monthly'
  const monthlyChannePrice = isTieredPlan(plan)
    ? prices.monthlyChannelPrice
    : prices.baseMonthlyPrice

  if (isFreePlan(plan)) {
    return (
      <React.Fragment>
        You’re on the <strong>{name}</strong> Plan. Connect up to {channelLimit}{' '}
        channels for free. To add additional channels, you will need to upgrade
        your plan.
      </React.Fragment>
    )
  }

  // Covers all paid plans on trial
  if (isOnTrial) {
    const agencyTrialCopy = (
      <>
        <strong>
          ${prices?.monthlyFlatFee} per {billedInterval} for{' '}
          {prices?.channelTierUnit} channels.
        </strong>{' '}
        You can add additional channels for just{' '}
      </>
    )
    if (newTrialLimitEnabled) {
      return (
        <React.Fragment>
          You’re on a free trial. During your trial, you can connect up to{' '}
          {TRIAL_CHANNELS_LIMIT}
          channels. If you choose to stay on this plan after your trial, it’ll
          cost {isTieredPlan(plan) ? agencyTrialCopy : ''}
          <strong>
            ${monthlyChannePrice} per channel per {billedInterval}
          </strong>{' '}
          -{' '}
          <CustomLink
            href="#"
            onClick={(): void => openPlanSelector(CHANGE_PLAN_CTA)}
          >
            Change Plan
          </CustomLink>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        You’re on a free trial for the <strong>{name}</strong> Plan. During your
        trial, you can connect as many channels as you’d like. If you choose to
        stay on this plan after your trial, it’ll cost{' '}
        {isTieredPlan(plan) ? agencyTrialCopy : ''}
        <strong>
          ${monthlyChannePrice} per channel per {billedInterval}
        </strong>{' '}
        -{' '}
        <CustomLink
          href="#"
          onClick={(): void => openPlanSelector(CHANGE_PLAN_CTA)}
        >
          Change Plan
        </CustomLink>
      </React.Fragment>
    )
  }

  // Covers all paid plans with locked channels
  if (lockedChannelCount) {
    return (
      <React.Fragment>
        You’re on the <strong>{name}</strong> plan with{' '}
        <strong>
          {channelLimit} supported {channelLimit !== 1 ? 'channels' : 'channel'}
        </strong>
        .{' '}
        <strong>
          {lockedChannelCount} of your connected channels{' '}
          {lockedChannelCount !== 1 ? 'are' : 'is'} locked
        </strong>
        . Unlock them by adding more channels to your plan for just{' '}
        <strong>
          ${monthlyChannePrice}/channel/month with {intervalName} billing{' '}
        </strong>{' '}
        -{' '}
        <CustomLink
          href="#"
          onClick={(): void => openPlanSelector(CHANGE_PLAN_CTA)}
        >
          Change Plan
        </CustomLink>
      </React.Fragment>
    )
  }

  // Covers Agency plan with no additional channels purchased
  if (isTieredPlan(plan) && channelLimit === prices.channelTierUnit) {
    return (
      <React.Fragment>
        You’re on the <strong>{name}</strong> Plan. This includes up to{' '}
        {prices?.channelTierUnit} channels and if needed, add additional
        channels for just{' '}
        <strong>
          ${monthlyChannePrice} per channel per {billedInterval}
        </strong>
        .{' '}
      </React.Fragment>
    )
  }

  // Covers all paid plans including Agency with more than 10 channels purchased
  return (
    <React.Fragment>
      You’re on the <strong>{name}</strong> Plan with {channelLimit} channel
      {channelLimit !== 1 ? 's' : ''} available. Add more channels to your plan
      for just{' '}
      <strong>
        ${monthlyChannePrice} per channel per {billedInterval}
      </strong>{' '}
      -{' '}
      <CustomLink
        href="#"
        onClick={(): void => openPlanSelector(CHANGE_PLAN_CTA)}
      >
        Change Plan
      </CustomLink>
    </React.Fragment>
  )
}

const PricingNotice = (): JSX.Element | null => {
  const account = useAccount()
  const currentPlan = getCurrentPlanFromAccount(account)
  const epochsMs = getTrialStartDateInEpochsMs(account)
  const { isEnabled: newTrialLimitEnabled } = useSplitEnabled(
    'geid-limit-channel-connection-trial',
    {
      signUpDate: epochsMs,
      isOnTrial: hasActiveTrial(account.currentOrganization),
    },
  )

  if (!currentPlan) {
    return null
  }

  const lockedChannelCount =
    account?.currentOrganization?.channels?.filter(function (e) {
      return e.isLocked
    }).length || 0

  const gatewayStore = getGatewayStore(account.currentOrganization)
  const appStoreLinks = getAppsStoreLinks(account.currentOrganization)
  const isOnTrial = hasActiveTrial(account.currentOrganization)

  const bannerCopy = getBannerCopy({
    plan: currentPlan,
    channelLimit: account.currentOrganization.limits.channels,
    lockedChannelCount,
    isOnTrial,
    newTrialLimitEnabled,
  })
  const bannerMobileCopy = getBannerMobileCopy({
    plan: currentPlan,
    store: gatewayStore,
    appInfo: appStoreLinks,
  })
  const shouldShowBillingActions = shouldShowUpgradePaths(
    account.currentOrganization,
    currentPlan,
  )
  const shouldShowMobileCopy = !shouldShowBillingActions
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
    <Notice type="note" disableAnimation>
      <NoticeContent>
        <div>
          <BufferIcon></BufferIcon>
        </div>
        <div>
          <TextStyled type="p">
            <strong>Get to know your plan</strong>
          </TextStyled>
          {shouldShowMobileCopy ? (
            <Text type="p">{bannerMobileCopy}</Text>
          ) : (
            <Text type="p">{bannerCopy}</Text>
          )}
        </div>
        {isFreePlan(currentPlan) ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; label... Remove this comment to see the full error message
          <Button
            data-testid="account-channels-pricingnotice-upgradeplan"
            type="primary"
            label="Upgrade Plan"
            onClick={(): void => openPlanSelector(UPGRADE_PLAN_CTA)}
          />
        ) : null}
      </NoticeContent>
    </Notice>
  )
}

export default PricingNotice
