import styled from 'styled-components'
import { gray } from '@bufferapp/ui/style/colors'

export const AccordionWrapper = styled.div<{
  addMargin?: boolean
}>`
  width: 100%;
  border: 1px solid ${gray};
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  margin-bottom: ${(props) => props.addMargin && '1.5rem'};
`
