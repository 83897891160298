import React from 'react'
import { AnalyzeLogo, PublishLogo } from '../../../../account-components'
import { StripeCreditCardForm } from '../../../../credit-card/components/CreditCardForm'

import { ReviewAndConfirm, SelectPlan } from '../Views'
import { CreditCardWrapper } from './styles'

const Body = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'creditCard' implicitly has an 'an... Remove this comment to see the full error message
  creditCard,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentView' implicitly has an 'a... Remove this comment to see the full error message
  currentView,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cycle' implicitly has an 'any' ty... Remove this comment to see the full error message
  cycle,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSubmittingCreditCard' implicitl... Remove this comment to see the full error message
  isSubmittingCreditCard,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planForReview' implicitly has an ... Remove this comment to see the full error message
  planForReview,
  // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
  product,
  // @ts-expect-error TS(7031) FIXME: Binding element 'saveCardDetails' implicitly has a... Remove this comment to see the full error message
  saveCardDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectPlanForReview' implicitly h... Remove this comment to see the full error message
  selectPlanForReview,
  // @ts-expect-error TS(7031) FIXME: Binding element 'updateChangePlanModalView' implic... Remove this comment to see the full error message
  updateChangePlanModalView,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentPlan' implicitly has an 'a... Remove this comment to see the full error message
  currentPlan,
}) => {
  if (currentView === 'review') {
    const logoMapping = {
      analyze: <AnalyzeLogo width={'89'} height={'21'} />,
      publish: <PublishLogo width={'85'} height={'20'} />,
    }
    return (
      <ReviewAndConfirm
        // @ts-expect-error TS(2322) FIXME: Type '{ product: any; logo: any; upgradedPlanDetai... Remove this comment to see the full error message
        product={product}
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        logo={logoMapping[product]}
        upgradedPlanDetails={planForReview}
        cycle={cycle}
      />
    )
  }

  if (currentView === 'creditCard') {
    return (
      <CreditCardWrapper>
        <StripeCreditCardForm
          submitAction={saveCardDetails}
          closeAction={() => {
            updateChangePlanModalView('plans')
          }}
          buttonLabel={
            isSubmittingCreditCard ? 'Saving card...' : 'Add a credit card'
          }
          closeButtonLabel={'Select a different plan'}
          isSubmitting={isSubmittingCreditCard}
          isLoading={isSubmittingCreditCard}
          saveCardDetails={saveCardDetails}
        />
      </CreditCardWrapper>
    )
  }

  return (
    <SelectPlan
      selectPlanForReview={selectPlanForReview}
      cycle={cycle}
      product={product}
      // @ts-expect-error TS(2322) FIXME: Type '{ selectPlanForReview: any; cycle: any; prod... Remove this comment to see the full error message
      planForReview={planForReview}
      updateChangePlanModalView={updateChangePlanModalView}
      currentPlan={currentPlan}
    />
  )
}

export default Body
