import React from 'react'
import PropTypes from 'prop-types'
import { CardRow, PageCardRow } from '../CardRow'
import Text from '@bufferapp/ui/Text'
import { ChannelBody, LabelWrapper } from './styles'

const Row = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'channel' implicitly has an 'any' ... Remove this comment to see the full error message
  channel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'canEdit' implicitly has an 'any' ... Remove this comment to see the full error message
  canEdit,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectChannelToReconnect' implici... Remove this comment to see the full error message
  selectChannelToReconnect,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectChannelToRemove' implicitly... Remove this comment to see the full error message
  selectChannelToRemove,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setShowChannelUnlockingModal' imp... Remove this comment to see the full error message
  setShowChannelUnlockingModal,
}) => {
  if (channel.service === 'startPage') {
    return (
      <PageCardRow
        id={channel.id}
        name={channel.name}
        service={channel.service}
        // @ts-expect-error TS(2322) FIXME: Type '{ id: any; name: any; service: any; serviceI... Remove this comment to see the full error message
        serviceId={channel.serviceId}
        avatar={channel.avatar}
        isLocked={channel.isLocked}
        selectChannelToRemove={selectChannelToRemove}
        descriptor={channel.descriptor || 'Page'}
      />
    )
  } else {
    // Temporary fix; should be centralized on backend for
    // all clients (web & mobile) in near future
    const needsRefreshing =
      channel.shouldShowPinterestUpgradeBanner ||
      channel.shouldShowLinkedInUpgradeBanner ||
      channel.shouldShowTiktokUpgradeBanner

    return (
      <CardRow
        id={channel.id}
        displayName={channel.displayName}
        name={channel.name}
        service={channel.service}
        type={channel.type}
        serviceId={channel.serviceId}
        descriptor={channel.descriptor || 'Profile'}
        avatar={channel.avatar}
        isDisconnected={channel.isDisconnected}
        needsRefreshing={needsRefreshing}
        isLocked={channel.isLocked}
        selectChannelToRemove={selectChannelToRemove}
        setShowChannelUnlockingModal={setShowChannelUnlockingModal}
        canEdit={canEdit}
        location={channel?.locationData?.location}
        serverUrl={channel?.serverUrl}
      />
    )
  }
}

const ChannelsBody = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'canEdit' implicitly has an 'any' ... Remove this comment to see the full error message
  canEdit,
  // @ts-expect-error TS(7031) FIXME: Binding element 'channels' implicitly has an 'any'... Remove this comment to see the full error message
  channels,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectChannelToReconnect' implici... Remove this comment to see the full error message
  selectChannelToReconnect,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectChannelToRemove' implicitly... Remove this comment to see the full error message
  selectChannelToRemove,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setShowChannelUnlockingModal' imp... Remove this comment to see the full error message
  setShowChannelUnlockingModal,
}) => {
  // @ts-expect-error TS(7034) FIXME: Variable 'connectedChannels' implicitly has type '... Remove this comment to see the full error message
  const connectedChannels = []
  // @ts-expect-error TS(7034) FIXME: Variable 'lockedChannels' implicitly has type 'any... Remove this comment to see the full error message
  const lockedChannels = []

  // @ts-expect-error TS(7006) FIXME: Parameter 'c' implicitly has an 'any' type.
  channels.forEach((c) => {
    return !c.isLocked ? connectedChannels.push(c) : lockedChannels.push(c)
  })

  return (
    <ChannelBody>
      <>
        {/* @ts-expect-error TS(7005) FIXME: Variable 'connectedChannels' implicitly has an 'an... Remove this comment to see the full error message */}
        {connectedChannels.map((channel) => (
          <Row
            key={`cardrow-${channel.id}`}
            channel={channel}
            canEdit={canEdit}
            selectChannelToReconnect={selectChannelToReconnect}
            selectChannelToRemove={selectChannelToRemove}
            setShowChannelUnlockingModal={setShowChannelUnlockingModal}
          />
        ))}
        {lockedChannels.length > 0 && (
          <>
            <LabelWrapper>
              <Text type="label">Locked Channels</Text>
            </LabelWrapper>
            {/* @ts-expect-error TS(7005) FIXME: Variable 'lockedChannels' implicitly has an 'any[]... Remove this comment to see the full error message */}
            {lockedChannels.map((channel) => (
              <Row
                key={`cardrow-${channel.id}`}
                channel={channel}
                canEdit={canEdit}
                selectChannelToReconnect={selectChannelToReconnect}
                selectChannelToRemove={selectChannelToRemove}
                setShowChannelUnlockingModal={setShowChannelUnlockingModal}
              />
            ))}
          </>
        )}
      </>
    </ChannelBody>
  )
}

ChannelsBody.propTypes = {
  canEdit: PropTypes.bool,
  channels: PropTypes.array,
  selectChannelToReconnect: PropTypes.func,
  selectChannelToRemove: PropTypes.func,
  setShowChannelUnlockingModal: PropTypes.func,
}

export default ChannelsBody
