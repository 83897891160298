import styled from 'styled-components'

import { white, gray, grayDarker, blue } from '@bufferapp/ui/style/colors'

export const Label = styled.label<{ selected: boolean }>`
  display: block;
  width: 100%;
  background-color: ${white};
  padding: 16px;
  border: ${(props) =>
    props.selected ? '1px solid blue' : `1px solid ${gray}`};
  border-radius: 4px;
  cursor: pointer;

  &:first-of-type {
    margin-bottom: 8px;
  }
`

export const RadioWrapper = styled.div`
  display: flex;
  margin-right: 8px;
  margin-top: 3px;
`

export const ContentWrapper = styled.div`
  display: flex;
`

export const Header = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: ${grayDarker};
    margin-left: 10px;
  }
`

export const RadioBox = styled.input`
  appearance: none;
  font: inherit;
  color: ${gray};
  width: 16px;
  height: 16px;
  border: 0.15em solid ${gray};
  border-radius: 100px;
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    border: 6px solid ${blue};
  }

  :checked::before {
    transform: scale(1);
  }
`
