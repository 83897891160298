import styled from 'styled-components'

export const Notification = styled.div`
  background: rgb(61, 61, 61);
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 5px;
  border-radius: 4px;
  position: absolute;
  right: 15px;
  top: 15px;
  color: rgb(255, 255, 255);
  z-index: 999999;
  padding: 16px;

  font-size: 14px;
  line-height: 19px;
`
