import React from 'react'

import { Text } from '@bufferapp/ui'
import { borderRadius } from '@bufferapp/ui/style/borders'
import { fontSize } from '@bufferapp/ui/style/fonts'
import Warning from '@bufferapp/ui/Icon/Icons/Warning'
import Cross from '@bufferapp/ui/Icon/Icons/Cross'

import styled from 'styled-components'

const NoticeWrapper = styled.div`
  border: 1px solid #a59638;
  color: #625920;
  background: #fdf8d8;
  border-radius: ${borderRadius};
  font-size: ${fontSize};
  padding: 16px 16px;
  display: flex;
`

const WarningIcon = styled(Warning)`
  display: block;
  margin-right: 12px;
`

const CloseLink = styled.button`
  color: #625920;
  border: 0;
  background: 0;
  padding: 0;
  margin: 0;
  opacity: 0.8;
  height: 16px;
  &:hover {
    color: #625920;
    opacity: 1;
  }
`

const CloseButon = () => {
  return (
    <CloseLink>
      <Cross />
    </CloseLink>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
function Notice({ children }) {
  return (
    <NoticeWrapper>
      <WarningIcon />
      {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
      <Text>{children}</Text>
      <CloseButon />
    </NoticeWrapper>
  )
}

export default Notice
