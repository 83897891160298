// Font families
export const fontFamily = '"Roboto", sans-serif'

// Font weights
export const fontWeightThin = 100
export const fontWeightExtraLight = 200
export const fontWeightLight = 300
export const fontWeight = 400 // Normal
export const fontWeightMedium = 500
export const fontWeightSemiBold = 600
export const fontWeightBold = 700

// Line heights
export const lineHeight = 1.5
export const lineHeightNormal = 'normal'

// Font sizes
export const fontSize = '14px'
export const fontSizeH1 = '32px'
export const fontSizeH2 = '24px'
export const fontSizeH3 = '18px'
