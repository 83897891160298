import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, DropdownMenu, Text, Button } from '@bufferapp/ui'
import { grayDark } from '@bufferapp/ui/style/colors'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'

import StatusSection from '../StatusSection'

import { TextStyled, ChannelRow, ButtonWrapper } from './styles'

import { fallbackAvatarUrl } from '../../ChannelsPage/ChannelsPage'
import { START_PAGE_URL } from '../../../../utils/redirect'

export const PageCardRow = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'avatar' implicitly has an 'any' t... Remove this comment to see the full error message
  avatar,
  // @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
  id,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isLocked' implicitly has an 'any'... Remove this comment to see the full error message
  isLocked,
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  name,
  // @ts-expect-error TS(7031) FIXME: Binding element 'service' implicitly has an 'any' ... Remove this comment to see the full error message
  service,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectChannelToRemove' implicitly... Remove this comment to see the full error message
  selectChannelToRemove,
  // @ts-expect-error TS(7031) FIXME: Binding element 'descriptor' implicitly has an 'an... Remove this comment to see the full error message
  descriptor,
}) => {
  const redirectToSettings = () =>
    window.location.replace(`${START_PAGE_URL}${id}/settings`)
  const redirectToStartPage = () =>
    window.location.replace(`${START_PAGE_URL}${id}`)

  const handleUnpublishChannel = [
    {
      id: '2',
      title: 'Unpublish Channel',
      onItemClick: redirectToSettings,
    },
  ]

  const customDropdownMenuItems = [
    {
      id: '1',
      title: 'Edit in Start Page',
      onItemClick: redirectToStartPage,
    },
    ...(!isLocked ? handleUnpublishChannel : []),
    {
      id: '3',
      title: 'Delete Channel',
      onItemClick: () => {
        selectChannelToRemove({
          id,
          name,
          avatar,
          service,
          descriptor,
        })
      },
    },
  ]

  return (
    <ChannelRow data-testid={`cardrow-${id}`} isLocked={isLocked}>
      <Avatar
        src={avatar}
        alt={name}
        network={service}
        fallbackUrl={fallbackAvatarUrl}
      />
      <div>
        <TextStyled type="p" title={name}>
          {name}
        </TextStyled>
        <Text type="p">Start Page</Text>
      </div>
      {isLocked && (
        <ButtonWrapper>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
          <Button
            type="secondary"
            onClick={redirectToStartPage}
            label="Publish to Connect"
          />
        </ButtonWrapper>
      )}

      {!isLocked && <StatusSection />}

      <div>
        <DropdownMenu
          // @ts-expect-error TS(2322) FIXME: Type '{ ariaLabel: string; ariaLabelPopup: string;... Remove this comment to see the full error message
          ariaLabel="Channel Menu"
          ariaLabelPopup="Channel Menu"
          menubarItem={<MoreIcon color={grayDark} />}
          items={customDropdownMenuItems}
          xPosition="right"
        />
      </div>
    </ChannelRow>
  )
}

PageCardRow.propTypes = {
  avatar: PropTypes.string,
  descriptor: PropTypes.string,
  id: PropTypes.string,
  isLocked: PropTypes.bool,
  name: PropTypes.string,
  service: PropTypes.string,
  selectChannelToRemove: PropTypes.func,
}
