import React from 'react'
import PropTypes from 'prop-types'
import { fontSizeSmall, fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { grayDarker, white } from '@bufferapp/ui/style/colors'

import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const Tag = ({ text, size, weight, color, backgroundColor, uppercase }) => (
  <Styles.TagWrapper
    backgroundColor={backgroundColor}
    color={color}
    size={size}
    uppercase={uppercase}
    weight={weight}
  >
    {text}
  </Styles.TagWrapper>
)

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  weight: PropTypes.number,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  uppercase: PropTypes.bool,
}

Tag.defaultProps = {
  text: '',
  size: fontSizeSmall,
  weight: fontWeightMedium,
  color: white,
  backgroundColor: grayDarker,
  uppercase: false,
}

export default Tag
