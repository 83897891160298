import React from 'react'
import {
  AnimatedList,
  Card,
  BlockWrapper,
} from '../../../../../../account-components'
import ExampleChannel from './components/ExampleChannel'

const SkeletonChannels = () => (
  <BlockWrapper>
    <Card>
      <AnimatedList>
        <ExampleChannel displaySkeleton />
        <ExampleChannel displaySkeleton />
        <ExampleChannel displaySkeleton />
        <ExampleChannel displaySkeleton />
        <ExampleChannel displaySkeleton />
        <ExampleChannel displaySkeleton />
        <ExampleChannel displaySkeleton />
        <ExampleChannel displaySkeleton lastChannelOnList />
      </AnimatedList>
    </Card>
  </BlockWrapper>
)

export default SkeletonChannels
