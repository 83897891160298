import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@bufferapp/ui'

// @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const Reconnect = ({ text, canEdit, onRefreshConnectionClick }) => (
  // @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; onClick: any; ... Remove this comment to see the full error message
  <Button
    type="primary"
    size="small"
    onClick={onRefreshConnectionClick}
    label={text || 'Refresh'}
    disabled={!canEdit}
  />
)

Reconnect.propTypes = {
  canEdit: PropTypes.bool,
  onRefreshConnectionClick: PropTypes.func,
  text: PropTypes.string,
}

export default Reconnect
