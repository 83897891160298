/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import styled from 'styled-components'

import { Notification, Select as UISelect, Text } from '@bufferapp/ui'

import { Card, FormBlock } from '../../../account-components'

import { Channel } from './Channel'
import ChannelListModal from './ChannelListModal'
import EmptyChannelListModal from './EmptyChannelListModal'
import { H3 } from './Text'
import { OverQuota } from './OverQuota'
import Notice from './Notice'
import { useSelector } from 'react-redux'

const Heading = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  font-size: 12px;

  > *:first-child {
    margin-right: auto;
  }

  > *:nth-last-child(2) {
    margin-left: 24px;
  }

  > *:last-child {
    margin-left: 24px;
  }
`

const Content = styled.main`
  font-weight: 500;
  padding-top: 1rem;
`

const CardTitle = styled.header`
  padding-left: 1rem;
`

const ChannelListWrapper = styled.section`
  margin-top: 8px;
  position: relative;

  > :first-child {
    position: absolute;
    right: 0;
    top: -48px;
  }
`

class Select extends UISelect {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  handleSelectOption = (option, event): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error TS(2339) FIXME: Property 'onSelectClick' does not exist on type 'R... Remove this comment to see the full error message
    this.props.onSelectClick(option, event)
    this.setState({
      isOpen: false,
    })
  }
}
class ChannelList extends React.Component {
  componentDidMount(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error TS(2339) FIXME: Property 'getChannelList' does not exist on type '... Remove this comment to see the full error message
    // eslint-disable-next-line react/prop-types
    this.props.getChannelList()
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      open,
      // @ts-expect-error TS(2339) FIXME: Property 'limit' does not exist on type 'Readonly<... Remove this comment to see the full error message
      limit,
      // @ts-expect-error TS(2339) FIXME: Property 'channels' does not exist on type 'Readon... Remove this comment to see the full error message
      channels,
      // @ts-expect-error TS(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message
      message,
      // @ts-expect-error TS(2339) FIXME: Property 'removeMessage' does not exist on type 'R... Remove this comment to see the full error message
      removeMessage,
      // @ts-expect-error TS(2339) FIXME: Property 'close' does not exist on type 'Readonly<... Remove this comment to see the full error message
      close,
      // @ts-expect-error TS(2339) FIXME: Property 'addChannels' does not exist on type 'Rea... Remove this comment to see the full error message
      addChannels,
      // @ts-expect-error TS(2339) FIXME: Property 'reconnect' does not exist on type 'Reado... Remove this comment to see the full error message
      reconnect,
      // @ts-expect-error TS(2339) FIXME: Property 'addAccount' does not exist on type 'Read... Remove this comment to see the full error message
      addAccount,
      // @ts-expect-error TS(2339) FIXME: Property 'removeChannel' does not exist on type 'R... Remove this comment to see the full error message
      removeChannel,
      // @ts-expect-error TS(2339) FIXME: Property 'facebookPages' does not exist on type 'R... Remove this comment to see the full error message
      facebookPages,
      // @ts-expect-error TS(2339) FIXME: Property 'instagramPages' does not exist on type '... Remove this comment to see the full error message
      instagramPages,
      // @ts-expect-error TS(2339) FIXME: Property 'twitterAccount' does not exist on type '... Remove this comment to see the full error message
      twitterAccount,
      // @ts-expect-error TS(2339) FIXME: Property 'linkedinAccount' does not exist on type ... Remove this comment to see the full error message
      linkedinAccount,
      // @ts-expect-error TS(2339) FIXME: Property 'logout' does not exist on type 'Readonly... Remove this comment to see the full error message
      logout,
      // @ts-expect-error TS(2339) FIXME: Property 'service' does not exist on type 'Readonl... Remove this comment to see the full error message
      service,
    } = this.props
    let pages = []
    if (service === 'facebook') {
      pages = facebookPages
    } else if (service === 'instagram') {
      pages = instagramPages
    } else if (service === 'twitter') {
      pages = twitterAccount
    } else if (service === 'linkedin') {
      pages = linkedinAccount
    }
    let overQuota = false
    if (limit && channels.length > limit) {
      overQuota = true
    }

    // @ts-expect-error TS(7031) FIXME: Binding element 'credentials' implicitly has an 'a... Remove this comment to see the full error message
    const showAdsNotice = channels.some(({ credentials, serviceType }) => {
      if (serviceType === 'instagram' || serviceType === 'facebook') {
        return credentials && !credentials.sharedOwner
      }
      return false
    })
    const items = [
      { id: 'instagram', title: 'Instagram', selected: false },
      { id: 'facebook', title: 'Facebook', selected: false },
      { id: 'twitter', title: 'Twitter', selected: false },
      { id: 'linkedin', title: 'Linkedin', selected: false },
    ]

    return (
      <React.Fragment>
        {message && <Notification text={message} onClose={removeMessage} />}
        <ChannelListWrapper>
          <Select
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            xPosition="right"
            // @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
            onSelectClick={({ id }): void => addAccount(id)}
            // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
            disabled={channels.some((channel) => channel.disabled)}
            label="Add Social Channel"
            type="secondary"
            items={items}
          />
          <Card>
            <Content>
              <CardTitle>
                {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
                <Text>
                  <H3>Social Channels</H3>
                </Text>
              </CardTitle>
              <FormBlock inline align="left" condensed withBorder>
                {showAdsNotice && (
                  <Notice>
                    To see insights for your boosted posts, click “Allow” below
                    to grant Buffer access to your ad data.
                  </Notice>
                )}
              </FormBlock>
              {overQuota && <OverQuota />}
              <FormBlock inline align="left" condensed withBorder>
                <Heading>
                  {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
                  <Text>
                    {limit ? channels.length + '/' + limit : '-/-'} Connected
                  </Text>
                  {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
                  <Text>Ads Permission</Text>
                  {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
                  <Text>Refresh</Text>
                  {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
                  <Text>Remove</Text>
                </Heading>
              </FormBlock>
            </Content>
            <FormBlock inline align="right">
              {/* @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type. */}
              {channels.map((channel) => (
                <Channel
                  key={channel._id}
                  {...channel}
                  reconnect={reconnect}
                  removeChannel={removeChannel}
                />
              ))}
            </FormBlock>
          </Card>
        </ChannelListWrapper>
        {open && !!pages.length && (
          <ChannelListModal
            limit={limit}
            allowedAmount={
              // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
              limit - channels.filter((channel) => !channel.isLocked)
            }
            service={service}
            close={close}
            pages={pages}
            addChannels={addChannels}
          />
        )}
        {open && !pages.length && (
          <EmptyChannelListModal
            service={service}
            close={close}
            logout={logout}
          />
        )}
      </React.Fragment>
    )
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ChannelListWithFlips = (props): JSX.Element => {
  const { featureFlips } = useSelector((state) => state.account)
  return <ChannelList {...props} featureFlips={featureFlips} />
}

export default ChannelListWithFlips
