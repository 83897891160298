import styled from 'styled-components'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'
import { gray } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  border: 1px solid ${gray};
  border-radius: 4px;
  align-items: center;
`

export const InfoIconStyled = styled(InfoIcon)`
  margin-right: 8px;
`
