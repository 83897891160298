import styled from 'styled-components'

const CardBody = styled.div`
  padding: 1rem;

  label {
    display: inline-block;
    margin-bottom: 8px;
  }

  h3 {
    margin-bottom: 8px;
  }
`

export default CardBody
