import React from 'react'
import PropTypes from 'prop-types'
import {
  BodyWrapper,
  TableWrapper,
  TableColumn as Col,
  TableRow as Row,
} from '../../../../account-components'

// @ts-expect-error TS(7031) FIXME: Binding element 'cycle' implicitly has an 'any' ty... Remove this comment to see the full error message
const ReviewAndConfirm = ({ cycle, upgradedPlanDetails, logo }) => (
  <BodyWrapper>
    <TableWrapper>
      <Row>
        <Col>Buffer Product</Col>
        <Col>Plan</Col>
        <Col> Price</Col>
      </Row>
      <Row>
        <Col>{logo}</Col>
        <Col>{upgradedPlanDetails.name}</Col>
        <Col>
          ${upgradedPlanDetails.price.toLocaleString()}/
          {cycle === 'year' ? 'yr' : 'mo'}
        </Col>
      </Row>
    </TableWrapper>
  </BodyWrapper>
)

ReviewAndConfirm.propTypes = {
  cycle: PropTypes.string,
  upgradedPlanDetails: PropTypes.object,
}

export default ReviewAndConfirm
