import styled from 'styled-components'
import { ProgressBar } from '@bufferapp/ui'
import { green, grayLight } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div``

export const Count = styled.span<{
  channelsMinCount: number
}>`
  height: 8px;
  width: 27px;
  border-radius: 8px;
  background-color: ${grayLight};
  margin-right: 2px;

  display: inline-block;

  &:nth-child(-n + ${(props) => props.channelsMinCount}) {
    background-color: ${green};
  }
`

export const ProgressBarStyled = styled(ProgressBar)`
  width: 120px !important;
  background-color: ${grayLight}!important;

  div {
    background-color: ${green}!important;
  }
`
