import React from 'react'
import { Avatar, Text } from '@bufferapp/ui'
import { Bold, Error } from './Text'
import { AvatarAndName, Name, ServiceType } from './styles'
import type { ChannelHeaderProps } from '../../types'

const ChannelHeader = ({
  avatar,
  credentials,
  name,
  serviceType,
  location,
}: ChannelHeaderProps): JSX.Element => {
  const serviceLabel =
    serviceType === 'instagram'
      ? 'Instagram Business or Creator'
      : serviceType.charAt(0).toUpperCase() + serviceType.slice(1)

  return (
    <AvatarAndName>
      <Avatar src={avatar} alt={name} network={serviceType} />
      <Name>
        <Bold>
          <Text type="span">
            {name}
            {credentials && credentials.invalid && (
              <Error> (Needs refreshing)</Error>
            )}
          </Text>
        </Bold>
        <ServiceType>
          <Text type="span">{serviceLabel}</Text>
          {location && <Text type="span">{location}</Text>}
        </ServiceType>
      </Name>
    </AvatarAndName>
  )
}

export default ChannelHeader
