import React from 'react'
import { Avatar, Text } from '@bufferapp/ui'
import { NameStyle, TextStyled, ChannelRow } from './styles'
import { fallbackAvatarUrl } from '../../ChannelsPage/ChannelsPage'

interface ChannelSummaryProps {
  avatar: string
  descriptor: string
  id: string
  name: string
  service: string
}

const ChannelSummary = ({
  avatar,
  descriptor,
  id,
  name,
  service,
}: ChannelSummaryProps): JSX.Element => {
  return (
    <ChannelRow data-testid={`cardrow-${id}`}>
      <Avatar
        src={avatar}
        alt={name}
        network={service}
        fallbackUrl={fallbackAvatarUrl}
      />
      <NameStyle>
        <TextStyled type="p" title={name}>
          {name}
        </TextStyled>
        <Text type="p">{descriptor}</Text>
      </NameStyle>
    </ChannelRow>
  )
}

export default ChannelSummary
