import {
  blue,
  yellowDarker,
  yellowLight,
  yellowLightest,
} from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const ProductAccordionBody = styled.div`
  display: flex;
`

export const AccordionBodyColumn = styled.div<{
  alignItems?: string
  justifyContent?: string
}>`
  display: flex;
  flex: 1;
  font-size: 14px;
  align-items: ${(props): string =>
    props.alignItems ? props.alignItems : 'flex-start'};
  justify-content: ${(props): string =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  flex-direction: column;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`

export const Link = styled.a`
  display: inline-flex;
  flex-direction: row;
  margin-left: 0.3rem;
  color: ${blue};
  cursor: pointer;
`

export const LinkWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(61, 61, 61);
`

export const LoaderWrapper = styled.div`
  margin: 2rem 0;
`

export const Container = styled.div`
  margin-bottom: 0.5rem;

  display: flex;
  flex-direction: column;

  a {
    color: inherit;
    font-weight: bold;
  }

  > div > div {
    background-color: ${yellowLightest};
    border-color: ${yellowLight};
  }

  ul {
    line-height: 1.4rem;
    list-style: none;
  }

  p,
  li {
    color: ${yellowDarker};
  }

  .legacy-upgrade-link {
    color: ${blue};
    font-weight: bold;
  }
`
