/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState } from 'react'

type ChannelConnectionRedirect = {
  startRedirect: boolean
  setStartRedirect: (value: boolean) => void
  serviceToRedirect: string
  setServiceToRedirect: (value: string) => void
}

export const ChannelConnectionContext = createContext({
  startRedirect: false,
  setStartRedirect: () => {},
  serviceToRedirect: '',
  setServiceToRedirect: () => {},
} as ChannelConnectionRedirect)

export const ChannelConnectionContextProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [startRedirect, setStartRedirect] = useState(false)
  const [serviceToRedirect, setServiceToRedirect] = useState('')

  return (
    <ChannelConnectionContext.Provider
      value={{
        startRedirect,
        setStartRedirect,
        serviceToRedirect,
        setServiceToRedirect,
      }}
    >
      {children}
    </ChannelConnectionContext.Provider>
  )
}
