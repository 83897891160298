import styled from 'styled-components'
import { grayLight, grayLighter, red } from '@bufferapp/ui/style/colors'

export const Body = styled.div`
  padding: 16px;
  width: 100%;
  background-color: ${grayLighter};
  border-top: ${`1px solid ${grayLight}`};
  border-bottom: ${`1px solid ${grayLight}`};

  p {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
`
export const ModalHeader = styled.div`
  padding: 0 16px 16px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const IconWrapper = styled.div`
  cursor: pointer;
  svg {
    fill: #b8b8b8;
  }
`

export const Warning = styled.div`
  p {
    color: ${red};
  }
`
