import styled from 'styled-components'

export const Footer = styled.footer<{
  padding: boolean
}>`
  width: 100%;
  box-sizing: border-box;
  background: white;
  padding: ${(props) => (props.padding ? '0 1rem 1rem' : '0 1rem')};
  margin-top: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > span,
  & > p {
    margin: 0 auto 0 0;
  }

  & + * {
    display: none;
  }

  div[type='primary'],
  div[type='text'] {
    margin-left: 0.5rem;
  }
`
