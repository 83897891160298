const environmentPath = window._ENV === 'development' ? 'local.' : ''

export const redirectToLogin = (): void => {
  window.location.href = `https://login.${environmentPath}buffer.com`
}

export const START_PAGE_URL = 'https://start-page.buffer.com/'

export function redirectToUrl(redirectURI: string): void {
  window.location.assign(redirectURI)
}
