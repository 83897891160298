import { connect } from 'react-redux'
import CardDetails from './components/CardDetails'
import { actions } from './reducer'

// default export = container
export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'creditCard' does not exist on type 'Defa... Remove this comment to see the full error message
    ...state.creditCard,
    currentOrganization: state.account.currentOrganization,
  }),
  (dispatch) => ({
    openCreditCardFormModal: () => dispatch(actions.openCreditCardFormModal()),
    closeCreditCardFormModal: () =>
      dispatch(actions.closeCreditCardFormModal()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
    showCreditCardNotification: (message) =>
      dispatch(actions.showCreditCardNotification(message)),
    resetCreditCardNotification: () =>
      dispatch(actions.resetCreditCardNotification()),
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    creditCardFailure: () => dispatch(actions.creditCardFailure()),
  }),
)(CardDetails)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
