import styled from 'styled-components'
import { grayLight } from '../../styles'

export const AppMenuStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 248px;
  margin: 0 0 0 0;
  padding: 16px;
  border-right: 1px solid ${grayLight};
  height: 100%;
`
