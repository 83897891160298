import styled from 'styled-components'

export const ModalHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ModalTitle = styled.h3<{
  align?: string
}>`
  margin: ${(props) => (props.align === 'center' ? '0 auto' : '')};
`

export const ModalFooterContent = styled.div`
  width: 100%;

  a {
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;

    &:visited {
      color: #2c4bff;
    }
  }
`

export const CreditCardWrapper = styled.div`
  width: 80%;
  max-width: 330px;
`
