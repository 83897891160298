import styled, { css } from 'styled-components'
import {
  gray,
  white,
  grayLighter,
  grayDark,
  grayDarker,
  blueLightest,
  blueDarker,
  greenLightest,
  greenDark,
} from '@bufferapp/ui/style/colors'

const HIGHLIGHTED_CARD_BACKGROUND = `linear-gradient(180deg, #F4F6FF 0%, #F9F4FF 100%);`
const STANDARD_CARD_BACKGROUND = `linear-gradient(180deg, #F4F4F4 0%, rgba(247, 248, 253, 0.74) 100%);`

export const Card = styled.div<{
  isNormal: boolean
  isDisabled: boolean
  isHighlighted: boolean
}>`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 1rem;
  padding: 3rem 1rem;
  background-color: lightgrey;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    #f4f4f4 0%,
    rgba(247, 248, 253, 0.74) 100%
  );

  background: ${(props) =>
    props.isHighlighted
      ? `${HIGHLIGHTED_CARD_BACKGROUND}`
      : `${STANDARD_CARD_BACKGROUND}`};
  cursor: ${(props) => (props.isDisabled ? `cross` : `normal`)};
  opacity: ${(props) => (props.isDisabled ? 0.3 : 1)};

  ${(props) =>
    props.isNormal &&
    css`
      background: ${white};
      border: 3px solid ${grayLighter};
    `}
`

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 70px;
`

export const Title = styled.h1`
  font-size: 24px;
`

export const PriceWrapper = styled.div<{
  isFirstCard?: boolean
}>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 0.5rem;
  align-items: flex-start;
  padding: 2.5rem 0 1.5rem;

  ${(props) =>
    props.isFirstCard &&
    css`
      flex: none;
      margin-bottom: 2rem;
    `}
`

export const Price = styled.h1<{
  isRedacted?: boolean
}>`
  display: flex;
  font-size: 32px;

  ${(props) =>
    props.isRedacted &&
    css`
      color: ${gray};
      font-size: 22px;
      font-weight: 400;
      text-decoration-line: line-through;
    `}
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex: 2 1 0;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 32px;
`

export const LineWrapper = styled.div<{
  isIncluded: boolean
  isHighlighted: boolean
}>`
  display: flex;
  font-size: 14px;
  color: ${(props) => (props.isIncluded ? `${grayDarker}` : `${gray}`)};
  font-weight: ${(props) => (props.isHighlighted ? 'bold' : 'normal')};

  > span {
    margin-left: 0.1rem;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding-bottom: 1rem;
`

export const Tag = styled.div`
  display: flex;
  padding: 0.3rem 0.5rem;
  align-items: center;
  text-align: center;
  background-color: ${blueLightest};
  border-radius: 4px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${blueDarker};
`

export const PricingNote = styled.div<{
  isSavings: boolean
}>`
  padding: 0.1rem 0.4rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-end;
  align-self: flex-start;

  color: ${(props) => (props.isSavings ? `${greenDark}` : `${grayDark}`)};
  background: ${(props) => (props.isSavings ? `${greenLightest}` : `${white}`)};
  font-size: 12px;
  border-radius: 4px;
`

export const BilledAtList = styled.ul`
  padding-left: 16px;
`
