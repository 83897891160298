import type {
  Account,
  CommonTrackingProperties,
} from '../web/src/pages/Channels/auth-flows/shared/SelectChannelList/utils'

export function getCommonTrackingPropertiesFromAccount(
  account: Account,
): CommonTrackingProperties | null {
  return account?.currentOrganization?.commonTrackingProperties || null
}
