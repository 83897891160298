import {
  actions as asyncDataFetchActions,
  actionTypes as asyncDataFetchActionsTypes,
  // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
} from '@bufferapp/async-data-fetch'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.DELETE_ACCOUNT:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'deleteMyAccount',
          args: {
            password: action.password,
            reason: action.reason,
          },
        }),
      )
      break
    case `deleteMyAccount_${asyncDataFetchActionsTypes.FETCH_SUCCESS}`:
      // store.dispatch(push('/account-deleted-confirmation'))
      // we should redirect to logout to clear cookies
      window.location.href = 'https://login.buffer.com/login?logout=true'
      break
    case actionTypes.VERIFY_ACCOUNT:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'verifyAccount',
          args: {
            password: action.password,
          },
        }),
      )
      break
    default:
      break
  }
  return next(action)
}
