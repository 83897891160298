import React from 'react'
import {
  grayDark,
  redDark,
  green,
  yellowDark,
} from '@bufferapp/ui/style/colors'
import { Text, Tooltip } from '@bufferapp/ui'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import Reconnect from '../Reconnect'

import {
  ChannelStatusWrapper,
  CircleCheckmarkIconStyled,
  LockedIconStyled,
  StatusWrapper,
  WarningIconStyled,
} from './styles'

type StatusArgs = {
  icon: JSX.Element
  text?: string
}

const Status = ({ icon, text }: StatusArgs): JSX.Element => (
  <StatusWrapper>
    {icon} <Text type="p">{text}</Text>
  </StatusWrapper>
)

type StatusSectionArgs = {
  canEdit?: boolean
  isDisconnected?: boolean
  isLocked?: boolean
  onRefreshConnectionClick?: () => void
  needsRefreshing?: boolean
}

const StatusSection = ({
  canEdit,
  isDisconnected,
  isLocked,
  onRefreshConnectionClick,
  needsRefreshing,
}: StatusSectionArgs): JSX.Element => {
  const shouldDisplayDisconnectedStatus = isDisconnected && !isLocked

  // TODO: Implement more centralized, ideal fix from backend to handle
  // channels that need refreshing
  const shouldDisplayNeedsRefreshing =
    !shouldDisplayDisconnectedStatus && needsRefreshing
  const isActive = !isDisconnected && !isLocked

  return (
    <ChannelStatusWrapper>
      {isLocked && (
        // @ts-expect-error TS(2322)
        <Tooltip
          position="left"
          label={
            <span>
              <strong>Locked.</strong> You’ve reached the maximum number of
              channels allowed for your plan. Upgrade to continue using this
              channel.
            </span>
          }
        >
          <Status
            icon={
              <LockedIconStyled data-testid="locked-icon" color={grayDark} />
            }
          />
        </Tooltip>
      )}
      {shouldDisplayNeedsRefreshing && (
        // @ts-expect-error TS(2322)
        <Tooltip
          position="left"
          label={
            <span>
              <strong>Needs refreshing.</strong> Please refresh this connection
              to continue sending your posts as usual.
            </span>
          }
        >
          <Status
            icon={
              <WarningIcon
                data-testid="needs-refreshing-icon"
                color={yellowDark}
              />
            }
          />
        </Tooltip>
      )}
      {isActive && !needsRefreshing && (
        // @ts-expect-error TS(2322)
        <Tooltip
          position="left"
          label={
            <span>
              <strong>Active.</strong> You&apos;re all set. 👍
            </span>
          }
        >
          <Status
            icon={
              <CircleCheckmarkIconStyled
                data-testid="active-icon"
                color={green}
              />
            }
          />
        </Tooltip>
      )}
      {shouldDisplayDisconnectedStatus && (
        <React.Fragment>
          <StatusWrapper>
            <Reconnect
              canEdit={canEdit}
              onRefreshConnectionClick={onRefreshConnectionClick}
            />
          </StatusWrapper>
          <StatusWrapper>
            {/* @ts-expect-error TS(2322) */}
            <Tooltip
              position="left"
              label={
                <span>
                  <strong>Expired.</strong> Refresh your connection to resume
                  using this channel.
                </span>
              }
            >
              <Status
                icon={
                  <WarningIconStyled
                    data-testid="warning-icon"
                    color={redDark}
                  />
                }
              />
            </Tooltip>
          </StatusWrapper>
        </React.Fragment>
      )}
    </ChannelStatusWrapper>
  )
}

export default StatusSection
