import React from 'react'
import PropTypes from 'prop-types'
import { ModalHeader } from '../../../../account-components'
import { Locked } from '@bufferapp/ui/Icon'

import ToggleSwitch from '../../../../billing-details/components/Modals/ToggleSwitch'

import { ModalHeaderContent, ModalTitle } from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'currentView' implicitly has an 'a... Remove this comment to see the full error message
const Header = ({ currentView, planName, cycle, toggleCycleCallback }) => {
  const headingMapping = {
    plans: {
      text: `My ${planName} plan`,
    },
    review: {
      text: `Review ${planName} plan changes`,
    },
    creditCard: {
      text: `Securely add your credit card details`,
      align: 'center',
      icon: <Locked />,
    },
  }

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const header = headingMapping[currentView]

  return (
    <ModalHeader>
      <ModalHeaderContent>
        <ModalTitle align={header.align}>
          {header.text} {header.icon}
        </ModalTitle>
        {currentView === 'plans' && (
          <ToggleSwitch
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            enabled={cycle === 'year'}
            leftLabel="Monthly"
            rightLabel="Yearly"
            onClick={toggleCycleCallback}
          />
        )}
      </ModalHeaderContent>
    </ModalHeader>
  )
}

Header.propTypes = {
  handleCloseModal: PropTypes.func,
  handleResetPlanForReview: PropTypes.func,
  selectPlanForReview: PropTypes.func,
  isChangingPlan: PropTypes.bool,
  planForReview: PropTypes.object,
  product: PropTypes.string,
}

export default Header
