import React from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { SidebarHeader, SidebarListItem } from '@bufferapp/ui'
import { black } from '@bufferapp/ui/style/colors'
import {
  Channels,
  Person,
  Dollar,
  Building,
  HeartFilled,
} from '@bufferapp/ui/Icon'
import { useSplitEnabled } from '@bufferapp/features'
import { useAccount } from '../../context/Account'
import FlaskIcon from '@bufferapp/ui/Icon/Icons/Flask'

import { AppMenuStyled } from './style'
import BufferIcon from '@bufferapp/ui/Icon/Icons/Buffer'

// @ts-expect-error TS(7031) FIXME: Binding element 'navigate' implicitly has an 'any'... Remove this comment to see the full error message
const AppMenu = ({ navigate, pathname }) => {
  const account = useAccount()
  const { currentOrganization } = account
  const channelCountBadge = currentOrganization?.channels?.length || 0

  const { isEnabled: isAlphaProgramFeatureEnabled } = useSplitEnabled(
    'alpha-program-customers',
  )

  return (
    <AppMenuStyled>
      <SidebarHeader title="Settings" />
      <SidebarListItem
        title="Account"
        icon={<Person color={black} />}
        selected={pathname === '/'}
        onItemClick={(): void => navigate('/')}
      />
      <SidebarListItem
        title="Billing"
        icon={<Dollar color={black} />}
        selected={pathname === '/billing'}
        onItemClick={(): void => navigate('/billing')}
      />
      <SidebarListItem
        title="Channels"
        icon={<Channels color={black} />}
        selected={pathname === '/channels' || pathname === '/channels/connect'}
        badges={channelCountBadge.toString()}
        onItemClick={(): void => navigate('/channels')}
      />
      <SidebarListItem
        title="Organization"
        icon={<Building color={black} />}
        selected={pathname === '/organization'}
        onItemClick={(): void => navigate('/organization')}
      />
      <SidebarListItem
        title="Beta Features"
        icon={<FlaskIcon color={black} />}
        selected={pathname === '/beta'}
        onItemClick={(): void => navigate('/beta')}
      />
      {isAlphaProgramFeatureEnabled && (
        <SidebarListItem
          title="Alpha Features"
          icon={<BufferIcon color={black} />}
          selected={pathname === '/alpha'}
          onItemClick={(): void => navigate('/alpha')}
        />
      )}
      <SidebarListItem
        title="Refer a Friend"
        icon={<HeartFilled color={black} />}
        selected={pathname === '/referrals'}
        onItemClick={(): void => navigate('/referrals')}
      />
    </AppMenuStyled>
  )
}

AppMenu.propTypes = {
  navigate: PropTypes.func,
  pathname: PropTypes.string,
}

export default connect(
  (state) => ({
    pathname: state.router.location.pathname,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'route' implicitly has an 'any' type.
    navigate: (route) => dispatch(push(route)),
  }),
)(AppMenu)
