import type { OnboardingConfig } from '../../../onboarding/config/getOnboardingConfig'

export const queryParams = () => {
  return new Proxy(new URLSearchParams(window.location.search), {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | symbol' is not assignab... Remove this comment to see the full error message
    get: (searchParams, prop) => searchParams.get(prop),
  })
}

export const LOCAL_ACCOUNT_PORT = '8080'

export function createQueryToTriggerChannelConnectionSuccessModal(
  channelIds: string[],
): string {
  const channelsIdsParam = channelIds
    ? `${channelIds.map((id) => `&channelId=${id}`).join('')}`
    : ''

  return `?channelConnectionSuccess=true${channelsIdsParam}`
}

export const getRedirectUrlAfterChannelConnected = (
  channelIds: string[],
  onboardingConfig: OnboardingConfig,
): string => {
  const baseURL = onboardingConfig.nextUrl
  const isPublishConfig = onboardingConfig.key === 'publishing'

  const queryParams =
    createQueryToTriggerChannelConnectionSuccessModal(channelIds)

  return isPublishConfig ? `${baseURL}${queryParams}` : `${baseURL}`
}

export function createBaseURL(): string {
  return `https://${window.location.hostname}`
}
