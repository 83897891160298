import styled from 'styled-components'

export const TableRow = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px 4px 0 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    color: #b8b8b8;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #cacaca;
  }

  &:last-of-type {
    border-radius: 4px;
  }
`
