import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import { fontWeightBold } from '@bufferapp/ui/style/fonts'
import { BlockWrapper } from '../../../../account-components'

export const TextStyled = styled(Text)`
  font-weight: ${fontWeightBold} !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1.5rem;
`

export const NameStyle = styled.div`
  margin-left: 1.25rem;
  overflow: hidden;
`

export const WarningMessageWrapper = styled.div`
  width: 512px;
  margin-bottom: 16px;
`

export const ButtonsWrapper = styled.div`
  > div:first-child {
    margin-right: 8px;
  }
`

export const BlockWrapperStyled = styled(BlockWrapper)`
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  > p {
    margin-right: auto;
    flex: 1;
  }
`

export const NoticeWrapper = styled(BlockWrapper)`
  div[type='note'] {
    background: white;
  }
`

export const ChannelsListWrapper = styled.div`
  padding-bottom: 24px;
`
