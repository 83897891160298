import styled from 'styled-components'

export const TitleContainer = styled.div`
  margin: 32px 0 16px;
  width: 512px;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
