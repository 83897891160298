const CYCLE_ABBREVIATIONS = {
  month: 'mo',
  year: 'yr',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'planCycle' implicitly has an 'any' type... Remove this comment to see the full error message
export function getPlanCycleAbbreviation(planCycle) {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return CYCLE_ABBREVIATIONS[planCycle]
}

const ESSENTIALS_DETAILS = [
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: true,
    text: 'Access to Analytics & Engagement',
  },
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: false,
    text: 'Only pay for active channels',
  },
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: false,
    text: 'Access to Twitter Threads',
  },
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: false,
    text: 'Access to Ideas',
  },
]

const TEAM_DETAILS = [
  {
    isIncluded: true,
    isHighlighted: true,
    isKeyFeature: false,
    text: 'Everything on Essentials, plus:',
  },
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: true,
    text: 'Unlimited team members & clients',
  },
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: false,
    text: 'Draft collaboration tools',
  },
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: false,
    text: 'Custom access & permissions',
  },
]

const AGENCY_DETAILS = [
  {
    isIncluded: true,
    isHighlighted: true,
    isKeyFeature: false,
    text: 'Everything on Team, plus:',
  },
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: true,
    text: 'Custom access & permissions',
  },
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: false,
    text: 'Agency-friendly pricing',
  },
  {
    isIncluded: true,
    isHighlighted: false,
    isKeyFeature: false,
    text: '50% discount on extra channels',
  },
]

export function createPlanCardsList() {
  return [
    {
      planName: 'essentials',
      description:
        'For professionals who use publishing, analytics & engagement tools.',
      details: ESSENTIALS_DETAILS,
      isHighlighted: false,
      baseMonthlyPrice: 6,
      baseYearlyPrice: 5,
    },
    {
      planName: 'team',
      description:
        'For teams who want unlimited user seating, collaboration, and reporting.',
      details: TEAM_DETAILS,
      isHighlighted: true,
      baseMonthlyPrice: 12,
      baseYearlyPrice: 10,
    },
    {
      planName: 'agency',
      description: 'For marketing agencies with 10 or more social channels.',
      details: AGENCY_DETAILS,
      isHighlighted: false,
      baseMonthlyPrice: 120,
      baseYearlyPrice: 100,
    },
  ]
}
