import styled, { css } from 'styled-components'
import {
  white,
  blue,
  blueLight,
  blueDark,
  gray,
  grayLight,
  grayDark,
} from '@bufferapp/ui/style/colors'

export const ComparisonTableWrapper = styled.table`
  width: 80%;
  margin-top: 1.5rem;
  border-collapse: collapse;
  border: none;
  border-collapse: separate;
  border-spacing: 0 0;

  flex-direction: column;

  h2 {
    margin: 0;
    font-size: 20px;
  }
`

export const TableSection = styled.tbody`
  width: 100%;
`

export const TableRow = styled.tr`
  display: flex;
  justify-content: center;

  td {
    &:nth-child(1) {
      border-top: 1px solid rgba(213, 219, 255, 0.5);
    }
  }

  // The last cell of each plans data column in section
  &:last-of-type {
    td {
      div {
        border-radius: 0 0 5px 5px;
      }
    }
  }
`

export const TableColumnHeader = styled.th<{
  isLeftColumn?: boolean
}>`
  display: flex;
  flex: 2 2 0;
  margin: 0;
  padding: 0 29px 0 0;
  // max-width: 280px;
  align-items: end;

  ${(props) =>
    props.isLeftColumn &&
    css`
      flex: 3 3 0;
      // max-width: 400px;
    `}

  text-align: center;
  vertical-align: bottom;
  align-items: bottom;

  h2 {
    padding: 10px 10px 5px;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }

  &:last-of-type {
    padding: 0;
  }
`

export const TableRowHeader = styled.th`
  display: flex;
  height: 60px;
  margin: 0;
  padding: 0 29px 0 0;
  flex: 3 3 0;
  // max-width: 400px;

  align-items: center;
  text-align: center;
  border-top: 1px solid rgba(213, 219, 255, 0.5);
  vertical-align: top;
`

export const TableColumnData = styled.td`
  flex: 2 2 0;
  margin: 0;
  padding: 0 29px 0 0;
  // max-width: 280px;
  text-align: center;
  height: 60px;
  border-top: 1px solid rgba(213, 219, 255, 0.5);
  vertical-align: top;

  &:last-of-type {
    padding: 0;
  }
`

export const CellConatiner = styled.div`
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  height: 100%;

  background: ${white};
  div {
    display: flex;
    padding: 0 5%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      text-align: center;
      padding: 5px;
    }
  }
`

export const HeaderCellConatiner = styled.div<{
  hasBorder: boolean
}>`
  margin: 0;
  padding: 1.5rem 0.5rem;
  text-align: center;
  box-sizing: border-box;
  height: 100%;

  border-radius: 5px 5px 0 0;
  background: ${white};
  ${(props) =>
    props.hasBorder &&
    css`
      border-top: 3px solid ${blue};
    `}

  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 0;
  justify-content: flex-start;
  flex-direction: column;

  h2 {
    font-size: 16px;
  }

  span {
    font-size: 14px;
    padding-bottom: 10px;
  }

  p {
    font-size: 14px;
    text-align: center;
    color: ${grayDark};
  }

  a {
    font-size: 18px;
  }
`

export const FeatureCellContainer = styled.div`
  span {
    display: flex;
  }

  h3 {
    font-weight: 600;
    text-align: left;
    margin-bottom: 7px;
    line-height: 1.2rem;
    margin-right: 10px;
  }

  p {
    font-size: 14px;
    text-align: left;

    line-height: 1.2rem;
  }
`

export const TextContainer = styled.div<{
  center?: boolean
}>`
  display: flex;
  flex-direction: column;
  justitfy-content: space-between;
  text-align: left;
  // flex: 1 1 0px;
  width: 100%;

  ${(props) =>
    props.center &&
    css`
      align-items: center;
      text-align: center;
    `}
`

export const TextHeading = styled.div`
  display: flex;
  margin-bottom: 3px;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: normal;

  span {
    margin-right: 5px;
  }

  svg {
    fill: ${gray};
  }
`

export const TagWrapper = styled.div`
  margin-top: -3px;
`

export const IconWrapper = styled.span<{
  checkMark: boolean
}>`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  padding: 4px;

  background: #6b81ff;
  background: ${(props) => (props.checkMark ? `${blueLight}` : `${grayLight}`)};
  border-radius: 50px;
  justify-content: center;

  svg {
    width: 12px;
    height: 12px;
    fill: ${(props) => (props.checkMark ? `${white}` : `${grayDark}`)};
  }
`

export const CTALink = styled.a`
  display: flex;
  margin: 1rem 0;
  padding: 0.5rem 2rem;
  align-self: center;

  border-radius: 4px;
  background: ${blue};
  color: ${white};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    background-color: ${blueDark};
    border-color: ${blueDark};
    color: ${white};

    transition-property: background-color, border-color, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }

  span {
    padding: 0;
  }
`
