export const blue = '#2c4bff'
export const blueDarker = '#1f35b3'
export const blueLighter = '#6b81ff'
export const gray = '#b8b8b8'
export const grayDark = '#636363'
export const grayDarker = '#3d3d3d'
export const grayDefault = '#77797a'
export const grayLight = '#e0e0e0'
export const grayLighter = '#f5f5f5'
export const green = '#87c211'
export const orange = '#ff702c'
export const pink = '#d925ac'
export const purple = '#9c2bff'
export const red = '#9d2637'
export const redLight = '#fdf2f4'
export const teal = '#00c8cf'
export const white = '#ffffff'
export const yellow = '#fade2a'

export const boxShadow = '#abb7ff'
export const grayShadow = '0px 1px 4px rgba(0, 0, 0, 0.16)'

export const facebook = '#3b5998'
export const googleplus = '#dd4b39'
export const instagram = '#e53c5f'
export const linkedin = '#007bb6'
export const pinterest = '#bd081c'
export const twitter = '#55acee'
