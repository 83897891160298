import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as Styles from './style'

const FormBlockStyled = styled.div<{
  withBackground: boolean
  withBorder: boolean
  inline: boolean
  align: string
  condensed: boolean
}>`
  ${Styles.FormBlockBase};
  ${(props) => props.withBackground && Styles.withBackground};
  ${(props) => props.withBorder && Styles.withBorder};
  ${(props) => props.inline && Styles.inline};
  ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    Styles[props.align]};
  ${(props) => props.condensed && Styles.condensed};
`

const FormBlock = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'align' implicitly has an 'any' ty... Remove this comment to see the full error message
  align,
  // @ts-expect-error TS(7031) FIXME: Binding element 'inline' implicitly has an 'any' t... Remove this comment to see the full error message
  inline,
  // @ts-expect-error TS(7031) FIXME: Binding element 'withBorder' implicitly has an 'an... Remove this comment to see the full error message
  withBorder,
  // @ts-expect-error TS(7031) FIXME: Binding element 'withBackground' implicitly has an... Remove this comment to see the full error message
  withBackground,
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031) FIXME: Binding element 'condensed' implicitly has an 'any... Remove this comment to see the full error message
  condensed,
}) => (
  <FormBlockStyled
    align={align}
    inline={inline}
    withBorder={withBorder}
    withBackground={withBackground}
    condensed={condensed}
  >
    {children}
  </FormBlockStyled>
)

FormBlock.propTypes = {
  align: PropTypes.oneOf(['left', 'right', 'center']),
  inline: PropTypes.bool,
  withBorder: PropTypes.bool,
  withBackground: PropTypes.bool,
  condensed: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

FormBlock.defaultProps = {
  align: 'center',
  inline: false,
  withBorder: false,
  withBackground: false,
  condensed: false,
  children: null,
}

export default FormBlock
