import styled from 'styled-components'

const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid #e8e8e8;

  div {
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
`

export default CardFooter
