import React from 'react'
import styled from 'styled-components'
import { Text } from '@bufferapp/ui'

import { H3 } from './Text'

const CardTitleHeader = styled.header`
  padding-left: 1rem;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const CardTitle = ({ children }) => (
  <CardTitleHeader>
    {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
    <Text>
      <H3>{children}</H3>
    </Text>
  </CardTitleHeader>
)

export default CardTitle
