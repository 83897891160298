import { gql } from '@apollo/client'

export const CREATE_SETUP_INTENT = gql`
  mutation billingCreateSetupIntent($organizationId: String) {
    billingCreateSetupIntent(organizationId: $organizationId) {
      ... on BillingCreateSetupIntentResponse {
        success
        clientSecret
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const UPDATE_PAYMENT_METHOD = gql`
  mutation billingUpdateCustomerPaymentMethod(
    $organizationId: String
    $paymentMethodId: String
    $attribution: AttributionInput
  ) {
    billingUpdateCustomerPaymentMethod(
      organizationId: $organizationId
      paymentMethodId: $paymentMethodId
      attribution: $attribution
    ) {
      ... on BillingResponse {
        success
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const DISMISS_OBJECT = gql`
  mutation DismissObject(
    $organizationId: String!
    $dismissedObject: DismissedObjectInput!
  ) {
    addOrUpdateDismissedObject(
      organizationId: $organizationId
      dismissedObject: $dismissedObject
    ) {
      ... on AddOrUpdateDismissedObjectResponse {
        success
      }
      ... on AddOrUpdateDismissedObjectError {
        cause
        userFriendlyMessage
      }
    }
  }
`
