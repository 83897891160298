import styled from 'styled-components'

export const Grey = styled.span`
  color: #636363;
`

export const H3 = styled.h3`
  color: #3d3d3d;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
`

export const Strong = styled.span`
  color: #3d3d3d;
  font-weight: bold;
`
