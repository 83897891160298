import React from 'react'
import PropTypes from 'prop-types'

import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ModalBody = ({ children }) => {
  return <Styles.Body>{children}</Styles.Body>
}

ModalBody.propTypes = {
  children: PropTypes.node,
}

export default ModalBody
