import React from 'react'

// Scrolls to an elements anchor when a hash is passed in
// Details on why this hook was created instead of react-router-hash-link
// found in this PR: https://github.com/bufferapp/buffer-account/pull/1117
export default function useScrollToHash(hash: string) {
  function scrollTo(hash: string) {
    const elementId: string = hash.replace('#', '')
    const element: HTMLElement | null = document.getElementById(elementId)
    if (window.location.hash === hash) {
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  React.useEffect(() => {
    scrollTo(hash)
  }, [])
}
