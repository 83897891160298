import styled from 'styled-components'
import { grayLight } from '@bufferapp/ui/style/colors'

export const ModalBody = styled.div`
  width: 100%;
  padding: 0 1rem;
  border-bottom: 1px solid ${grayLight};
  padding-bottom: 16px;

  h2 {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
  }
`

export const ErrorWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
`
