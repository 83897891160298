export function isValidTaxIdStatus(taxIdStatus: string) {
  switch (taxIdStatus) {
    case 'verified':
      return true
      break
    case 'unverified':
      return true
      break
    case 'pending':
      return true
      break
    default:
      return false
      break
  }
}
