/**
 * Colors
 */
export const blue = '#2c4bff'
export const blueDark = '#1F35B3'
export const blueDarker = '#121E66'
export const blueLighter = '#6B81FF'
export const purple = '#9C2BFF'
export const pink = '#D925AC'
export const teal = '#00C8CF'
export const red = '#9D2637'
export const redLight = '#FDF2F4'
export const orange = '#FF702C'
export const yellow = '#FADE2A'
export const green = '#87C211'

export const black = '#000000'
export const white = '#FFFFFF'
export const grayLighter = '#F5F5F5'
export const grayLight = '#E0E0E0'
export const gray = '#B8B8B8'
export const grayDark = '#636363'
export const grayDarker = '#3D3D3D'
export const grayDefault = '#77797a'

export const boxShadow = '#ABB7FF'
export const grayShadow = '0px 1px 4px rgba(0, 0, 0, 0.16)'

export const facebook = '#3b5998'
export const googleplus = '#dd4b39'
export const instagram = '#e53c5f'
export const linkedin = '#007bb6'
export const pinterest = '#bd081c'
export const twitter = '#55acee'

export const notificationRed = '#E97284'
export const notificationGreen = '#87C221'

/**
 * Borders
 */
export const borderRadius = '4px'

export const fontFamily = '"Roboto", sans-serif'

/**
 * Font weights
 */
export const fontWeightThin = 100
export const fontWeightExtraLight = 200
export const fontWeightLight = 300
export const fontWeight = 400 // Normal
export const fontWeightMedium = 500
export const fontWeightSemiBold = 600
export const fontWeightBold = 700

/**
 * Line heights
 */
export const lineHeight = 1.5

/**
 * Font sizes
 */
export const fontSize = '14px'
export const fontSizeH1 = '32px'
export const fontSizeH2 = '24px'
export const fontSizeH3 = '18px'
