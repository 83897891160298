import React from 'react'
import PropTypes from 'prop-types'
import { grayDarker } from '@bufferapp/ui/style/colors'

import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const ModalTitle = ({ text, color }) => {
  return <Styles.ModalTitle color={color}>{text}</Styles.ModalTitle>
}

ModalTitle.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
}

ModalTitle.defaultProps = {
  color: grayDarker,
}

export default ModalTitle
