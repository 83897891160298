import React from 'react'
import styled from 'styled-components'
import { redDark } from '@bufferapp/ui/style/colors'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import Text from '@bufferapp/ui/Text'

export const StyledError = styled.p<{
  isInline?: boolean
}>`
  margin: 0px;
  display: inline-block;
  align-items: center;
  width: 100%;
  overflow: ${({ isInline }) => (isInline ? 'hidden' : '')};
  text-overflow: ellipsis;
  font-size: 14px;

  span {
    color: ${redDark};
  }

  svg {
    color: ${redDark};
    height: 12px;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'isInline' implicitly has an 'any'... Remove this comment to see the full error message
export const Error = ({ isInline, error }) => (
  <StyledError isInline={isInline} aria-live="polite">
    {error && (
      <>
        <WarningIcon />
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
        <Text>{error}</Text>
      </>
    )}
  </StyledError>
)

export const PageWrapper = styled.div`
  width: 100%;
  padding: 16px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
