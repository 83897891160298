export const handleChannelUpdates = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentChannel' implicitly has an... Remove this comment to see the full error message
  currentChannel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'updateChannelsCallback' implicitl... Remove this comment to see the full error message
  updateChannelsCallback,
  // @ts-expect-error TS(7031) FIXME: Binding element 'updatedChannels' implicitly has a... Remove this comment to see the full error message
  updatedChannels,
}) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
  const channelsWithUpdatedLockStates = updatedChannels.map((channel) => {
    if (channel.id === currentChannel.id) {
      return {
        ...currentChannel,
        isLocked: !channel.isLocked,
      }
    } else {
      return channel
    }
  })

  updateChannelsCallback(channelsWithUpdatedLockStates)
}
