import styled from 'styled-components'
import { grayLighter } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${grayLighter};
  display: flex;
  flex-direction: row;
`
export const ContentWrapper = styled.div`
  width: 100%;
  padding: 1rem 1rem 7rem;
  background-color: '#F5F5F5';
  display: flex;
  flex-direction: column;
  align-items: center;
`
