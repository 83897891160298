import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'

import { DetailsWrapper, VerifiedStatus, TaxStatusText } from '../styles'
import { isValidTaxIdStatus } from '../utils'

export type TaxIdProps = {
  taxIds: Array<{
    country?: string
    value: string
    verification: {
      status: string
      verifiedAddress?: string
      verifiedName?: string
    }
  }>
}
export type TaxStatusDetailsProps = {
  taxIds: TaxIdProps['taxIds']
  taxExempt: string
}

const TaxIdStatus = ({ taxIds }: TaxIdProps): JSX.Element => {
  return (
    <>
      {taxIds.map((taxId) => {
        const taxIdStatus = taxId.verification.status
        const shouldRenderStatus = isValidTaxIdStatus(taxIdStatus)
        return (
          <Text type="p" key={taxId.value}>
            Tax ID: {taxId.value}{' '}
            {shouldRenderStatus && (
              <VerifiedStatus status={taxIdStatus}>
                {taxIdStatus}
              </VerifiedStatus>
            )}
          </Text>
        )
      })}
    </>
  )
}

const DEFAULT_TAX_MESSAGING =
  'Your business status helps us determine if we need to charge you VAT (Value Added Tax) or the applicable sales tax in your region.'

const TaxStatusDetails = ({
  taxIds,
  taxExempt,
}: TaxStatusDetailsProps): JSX.Element => {
  const hasTaxIds = taxIds && taxIds.length > 0

  // has saved tax ids and is not exempt
  if (hasTaxIds && taxExempt !== 'exempt') {
    return (
      <DetailsWrapper>
        <Text type="h3">Business Status</Text>
        <TaxStatusText type="p">{DEFAULT_TAX_MESSAGING}</TaxStatusText>
        <Text type="label">I am using Buffer for Business</Text>
        {taxIds && <TaxIdStatus taxIds={taxIds} />}
        <Text type="p">
          No further action is required if you are a business without a VAT
          number (UK and EU) or a sales tax ID (US). If you have any questions,
          please contact us at{' '}
          <a href="mailto:support@buffer.com">support@buffer.com.</a>
        </Text>
      </DetailsWrapper>
    )
  }

  if (taxExempt === 'exempt') {
    // is Tax Exempt
    return (
      <DetailsWrapper>
        <Text type="h3">Business Status</Text>
        <TaxStatusText type="p">{DEFAULT_TAX_MESSAGING}</TaxStatusText>
        <Text type="label">I am tax-exempt</Text>
        {taxIds && <TaxIdStatus taxIds={taxIds} />}
        <Text type="p">
          To update your tax information, please contact us at{' '}
          <a href="mailto:support@buffer.com">support@buffer.com.</a>
        </Text>
      </DetailsWrapper>
    )
  }
  return (
    // default: no Tax ID
    <DetailsWrapper>
      <Text type="h3">Business Status</Text>
      <TaxStatusText type="p">{DEFAULT_TAX_MESSAGING}</TaxStatusText>
      <Text type="label">Not a business</Text>
      <Text type="p">
        If you are a business without a VAT number, no further action is
        required. If you have any questions please contact us at{' '}
        <a href="mailto:support@buffer.com">support@buffer.com.</a>
      </Text>
    </DetailsWrapper>
  )
}

TaxStatusDetails.propTypes = {
  taxIds: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string,
      value: PropTypes.string,
      verification: PropTypes.shape({
        status: PropTypes.oneOf([
          'pending',
          'verified',
          'unverified',
          'unavailable',
        ]),
        verifiedAddress: PropTypes.string,
        verifiedName: PropTypes.string,
      }),
    }),
  ),
  taxExempt: PropTypes.oneOf(['none', 'exempt', 'reverse']),
}

export default TaxStatusDetails
