import type { ChannelThirdPartyService } from '../../../../../channel-connections/interfaces'
import type { ConnectableChannelData } from '../../../hooks/channel-connections/types'
import type { OAuthFlowErrorType } from '../../../pages/Channels/OAuthFlow/types'

export interface IReducerState {
  selectedService: ChannelThirdPartyService | null
  isRefreshingConnection: boolean
  isNewConnection: boolean
  connectionCompleted: boolean
  connectedChannelIds: string[] | null
  availableChannelsForService: ConnectableChannelData[] | null
  connectionErrorType: OAuthFlowErrorType | null
  loading: boolean
}

export enum ChannelConnectionsActions {
  SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE',
  RESET_CHANNEL_CONNECTION_STATE = 'RESET_CHANNEL_CONNECTION_STATE',
  CONNECTION_SUCCESS = 'CONNENCTION_SUCCESS',
  CONNECTION_FAILED = 'CONNECTION_FAILED',
  SET_CONNECTION_LOADING = 'SET_CONNECTION_LOADING',
  SET_AVAILABLE_CHANNELS = 'SET_AVAILABLE_CHANNELS',
}

export type ReducerAction =
  | {
      type: ChannelConnectionsActions.SET_SELECTED_SERVICE
      selectedService: ChannelThirdPartyService
      isRefreshingConnection: boolean
    }
  | { type: ChannelConnectionsActions.RESET_CHANNEL_CONNECTION_STATE }
  | {
      type: ChannelConnectionsActions.CONNECTION_SUCCESS
      channelIds: string[]
      isNewConnection: boolean
    }
  | {
      type: ChannelConnectionsActions.CONNECTION_FAILED
      errorType: OAuthFlowErrorType
    }
  | {
      type: ChannelConnectionsActions.SET_CONNECTION_LOADING
    }
  | {
      type: ChannelConnectionsActions.SET_AVAILABLE_CHANNELS
      availbleChannels: ConnectableChannelData[]
    }

export type ChannelConnectionReducer = (
  state: IReducerState,
  action: ReducerAction,
) => IReducerState

export default function channelConnectionsReducer(
  state: IReducerState,
  action: ReducerAction,
): IReducerState {
  switch (action.type) {
    case ChannelConnectionsActions.SET_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: action.selectedService,
        isRefreshingConnection: action.isRefreshingConnection,
      }
    }

    case ChannelConnectionsActions.CONNECTION_SUCCESS: {
      return {
        ...state,
        connectedChannelIds: action.channelIds,
        connectionCompleted: true,
        loading: false,
        isNewConnection: action.isNewConnection,
      }
    }
    case ChannelConnectionsActions.SET_AVAILABLE_CHANNELS: {
      return {
        ...state,
        availableChannelsForService: action.availbleChannels,
        loading: false,
      }
    }
    case ChannelConnectionsActions.CONNECTION_FAILED: {
      console.error(`[Channel Authorization] ${action.errorType}`)
      return {
        ...state,
        connectionErrorType: action.errorType,
        loading: false,
      }
    }
    case ChannelConnectionsActions.SET_CONNECTION_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case ChannelConnectionsActions.RESET_CHANNEL_CONNECTION_STATE: {
      return {
        selectedService: null,
        isRefreshingConnection: false,
        isNewConnection: false,
        connectionCompleted: false,
        connectedChannelIds: null,
        connectionErrorType: null,
        availableChannelsForService: null,
        loading: false,
      }
    }
    default:
      throw new Error('Action.type does not exist')
  }
}
