import React from 'react'
import PropTypes from 'prop-types'
import { FormRadioCard, CardWrapper } from '../../../../account-components'

import { getPlansForProduct } from '../../../constants/plans'

// @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
const SelectPlan = ({ product, selectPlanForReview, cycle, currentPlan }) => {
  // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 3.
  const productPlans = getPlansForProduct(product, currentPlan, cycle)
  return (
    <CardWrapper itemCount={productPlans.length}>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type. */}
      {productPlans.map((plan, index) => (
        <FormRadioCard
          title={plan.name}
          key={index}
          selected={false}
          name="subscriptionPlan"
          callback={() =>
            selectPlanForReview({ ...plan, price: plan.price[cycle] })
          }
        >
          <div className="details">
            <img src={plan.image} />
            <ul>
              {/* @ts-expect-error TS(7006) FIXME: Parameter 'feature' implicitly has an 'any' type. */}
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
          </div>
          <div>
            <strong className="price">
              ${plan.price[cycle].toLocaleString()}
            </strong>{' '}
            /{cycle === 'month' ? 'mo' : 'yr'}
          </div>
        </FormRadioCard>
      ))}
    </CardWrapper>
  )
}

SelectPlan.propTypes = {
  selectPlanForReview: PropTypes.func,
  cycle: PropTypes.string,
}

export default SelectPlan
