import { actions as centralizedBillingActions } from '../centralized-billing'
import { connect } from 'react-redux'
import BillingDetails from './components/BillingDetails'
import { actions } from './reducer'

const hasAnalyzeAccessThroughPublishSubscription = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'account' implicitly has an 'any' ... Remove this comment to see the full error message
  account,
  // @ts-expect-error TS(7031) FIXME: Binding element 'centralizedBilling' implicitly ha... Remove this comment to see the full error message
  centralizedBilling,
}) => {
  return (
    centralizedBilling.selectedOrganizationDetails.products.publish &&
    centralizedBilling.selectedOrganizationDetails.products.publish.product &&
    centralizedBilling.selectedOrganizationDetails.products.publish.product.match(
      /premium|business|small|agency|enterprise/,
    ) &&
    account.featureFlips.includes('grantAnalyzeAccess')
  )
}

// default export = container
export default connect(
  (state) => ({
    ...state.billing,
    hasAnalyzeAccessThroughPublishSubscription:
      hasAnalyzeAccessThroughPublishSubscription({ ...state }),
    currentOrganizationId: state.account.currentOrganization.id,
  }),
  (dispatch) => ({
    getBillingDetails: () =>
      // @ts-expect-error TS(2339) FIXME: Property 'getBillingDetails' does not exist on typ... Remove this comment to see the full error message
      dispatch(centralizedBillingActions.getBillingDetails()),
    getBillingInformation: () => dispatch(actions.getBillingInformation()),
    changeCardDetails: () => dispatch(actions.openModal()),
    addBillingDetails: () => dispatch(actions.openModal()),
    closeModal: () => dispatch(actions.closeModal()),
    removeError: () => dispatch(actions.removeError()),
    reenableSubscription: () => dispatch(actions.reenableSubscription()),
    cancelSubscription: () => dispatch(actions.cancelSubscription()),
    openCancelSubscriptionModal: () =>
      dispatch(actions.openCancelSubscriptionModal()),
    closeCancelSubscriptionModal: () =>
      dispatch(actions.closeCancelSubscriptionModal()),
    closeChurnSurveyModal: () => dispatch(actions.closeChurnSurveyModal()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
    submitChurnSurvey: (data) => dispatch(actions.submitChurnSurvey(data)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'cycle' implicitly has an 'any' type.
    changeBillingCycle: (cycle) => dispatch(actions.changeBillingCycle(cycle)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'cycle' implicitly has an 'any' type.
    openChangeBillingCycleModal: (cycle) =>
      dispatch(actions.openChangeBillingCycleModal(cycle)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'cycle' implicitly has an 'any' type.
    closeChangeBillingCycleModal: (cycle) =>
      dispatch(actions.closeChangeBillingCycleModal(cycle)),
    openChangePlanModal: () => dispatch(actions.openChangePlanModal()),
    closeChangePlanModal: () => dispatch(actions.closeChangePlanModal()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
    changePlan: (plan, cycle) => dispatch(actions.changePlan(plan, cycle)),
  }),
)(BillingDetails)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
