import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { CreditCardForm } from './form'

const stripePromise = loadStripe(window.STRIPE_PUBLISHABLE_KEY)

export const StripeCreditCardForm = (props: any): JSX.Element => {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardForm {...props} />
    </Elements>
  )
}
