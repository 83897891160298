import React from 'react'
import PropTypes from 'prop-types'

import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ModalHeader = ({ children, padding }) => {
  return <Styles.Header padding={padding}>{children}</Styles.Header>
}

ModalHeader.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.bool,
}

ModalHeader.defaultProps = {
  padding: false,
  // This component can be used with both the Modal and SimpleModal components.
  // Modal provides top-padding styles, while SimpleModal provides no styles.
  // We're moving towards using the SimpleModal, but since this component is shared,
  // we need to make sure it doesn't affect how it's used within Modal components.
  // Once we've completely moved over to using SimpleModal, we can remove this prop.
}

export default ModalHeader
