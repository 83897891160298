import React from 'react'
import type { Account } from '../pages/Channels/auth-flows/shared/SelectChannelList/utils'

const contextData: Account = {
  id: '',
  currentOrganization: { id: '', limits: { channels: 3 } },
  enabledProducts: [],
  hasVerifiedEmail: false,
}
export const AccountContext = React.createContext(contextData)

export const useAccount = (): Account => {
  const context = React.useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountContext')
  }
  return context
}

export const useEntitlements = (): string[] => {
  const account = useAccount()
  return account?.currentOrganization?.entitlements || []
}
