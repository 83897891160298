import styled, { type FlattenSimpleInterpolation, css } from 'styled-components'

import {
  yellowDarker,
  yellowLightest,
  yellowLight,
} from '@bufferapp/ui/style/colors'

export const Container = styled.div<{
  shouldDisplayTaxMessage: boolean
  shouldDisplayBalanceAndDiscount: boolean
}>`
  margin-bottom: 0.5rem;

  display: flex;
  flex-direction: column;

  a,
  button {
    color: inherit;
    font-weight: bold;
  }

  > div > div {
    background-color: ${yellowLightest};
    border-color: ${yellowLight};
  }

  ul {
    line-height: 1.4rem;

    ${(props): FlattenSimpleInterpolation =>
      props.shouldDisplayTaxMessage && !props.shouldDisplayBalanceAndDiscount
        ? css`
            list-style: none;
          `
        : css`
            padding-left: 0.5rem;
          `}
  }

  p,
  li {
    color: ${yellowDarker};
  }
`

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;

  > div {
    margin-bottom: 0.5rem;
  }
`
