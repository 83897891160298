import React from 'react'
import PropTypes from 'prop-types'
import { SidebarStyled } from './style'
import SidebarSection from './SidebarSection/SidebarSection'

// @ts-expect-error TS(7031) FIXME: Binding element 'sections' implicitly has an 'any'... Remove this comment to see the full error message
const Sidebar = ({ sections }) => (
  <SidebarStyled>
    {/* @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message */}
    {sections.map(({ name, items }, idx) => (
      <SidebarSection key={idx} name={name} items={items} />
    ))}
  </SidebarStyled>
)

Sidebar.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
}

Sidebar.defaultProps = {
  sections: [],
}

export default Sidebar
