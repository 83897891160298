// Calculates the number of days between a target date and today.
// Expects targetDate to be a timestamp
// @ts-expect-error TS(7006) FIXME: Parameter 'targetDate' implicitly has an 'any' typ... Remove this comment to see the full error message
export const daysFromToday = (targetDate) => {
  if (!targetDate || typeof targetDate !== 'number') {
    return null
  }

  const today = new Date()
  const oneDay = 24 * 60 * 60 * 1000 // hours * minutes * seconds * milliseconds
  // @ts-expect-error TS(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
  return Math.round((targetDate - today) / oneDay)
}

const defaultDateOptions = { year: 'numeric', month: 'long', day: 'numeric' }

// @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
export const formatDate = (date, options = defaultDateOptions) =>
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  new Date(date).toLocaleDateString('en-US', options)
