import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import { grayDark } from '@bufferapp/ui/style/colors'
import { ArrowLeft } from '@bufferapp/ui/Icon'
import { Text } from '@bufferapp/ui'

import * as Styles from './styles'

export const ChannelConnectFooter = (): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Styles.Footer>
      <Styles.FooterLink
        data-testid={`back-to-channels-page-button`}
        href={'/channels'}
        onClick={(e: React.MouseEvent<HTMLElement>): void => {
          e.preventDefault()
          dispatch(push('/channels'))
        }}
      >
        <ArrowLeft color={grayDark} />
        <Text color={'grayDark'} type={'label'}>
          Back to Channels
        </Text>
      </Styles.FooterLink>
    </Styles.Footer>
  )
}
