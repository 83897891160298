import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 515px;
`

export const LoadingWrapper = styled.div`
  padding: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    margin-bottom: 30px;
  }
`
