/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Notification, Text, Link } from '@bufferapp/ui'
import { toTitleCase } from '../../../shared-utils'
import { ModalTitle } from '../../../account-components'
import DeprecatedChurnSurveyModal from '../DeprecatedChurnSurveyModal'

import * as Styles from './styles'

const CancelPlan = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'buttonLabel' implicitly has an 'a... Remove this comment to see the full error message
  buttonLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cancelPlan' implicitly has an 'an... Remove this comment to see the full error message
  cancelPlan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'closeCancelPlanModal' implicitly ... Remove this comment to see the full error message
  closeCancelPlanModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'copy' implicitly has an 'any' typ... Remove this comment to see the full error message
  copy,
  // @ts-expect-error TS(7031) FIXME: Binding element 'disabled' implicitly has an 'any'... Remove this comment to see the full error message
  disabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'error' implicitly has an 'any' ty... Remove this comment to see the full error message
  error,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSubmitting' implicitly has an '... Remove this comment to see the full error message
  isSubmitting,
  // @ts-expect-error TS(7031) FIXME: Binding element 'notificationMessage' implicitly h... Remove this comment to see the full error message
  notificationMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openCancelPlanModal' implicitly h... Remove this comment to see the full error message
  openCancelPlanModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planType' implicitly has an 'any'... Remove this comment to see the full error message
  planType,
  // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
  product,
  // @ts-expect-error TS(7031) FIXME: Binding element 'productModal' implicitly has an '... Remove this comment to see the full error message
  productModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'resetNotification' implicitly has... Remove this comment to see the full error message
  resetNotification,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showModal' implicitly has an 'any... Remove this comment to see the full error message
  showModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'churnSurveyModalOpen' implicitly ... Remove this comment to see the full error message
  churnSurveyModalOpen,
  // @ts-expect-error TS(7031) FIXME: Binding element 'closeChurnSurveyModal' implicitly... Remove this comment to see the full error message
  closeChurnSurveyModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'submitChurnSurvey' implicitly has... Remove this comment to see the full error message
  submitChurnSurvey,
  // @ts-expect-error TS(7031) FIXME: Binding element 'closeParentModal' implicitly has ... Remove this comment to see the full error message
  closeParentModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'noButton' implicitly has an 'any'... Remove this comment to see the full error message
  noButton,
}) => {
  return (
    <React.Fragment>
      {notificationMessage && (
        <Notification text={notificationMessage} onClose={resetNotification} />
      )}
      {showModal && productModal === product && (
        // @ts-expect-error TS(2322)
        <Modal
          dismissible={false}
          action={{
            label: isSubmitting
              ? 'Canceling plan...'
              : 'Yes, I would like to cancel',
            callback: (): void => {
              closeParentModal()
              cancelPlan({ product, planType })
            },
            disabled: isSubmitting,
          }}
          secondaryAction={
            isSubmitting
              ? null
              : {
                  label: 'Close',
                  callback: (): void => {
                    closeParentModal()
                    closeCancelPlanModal()
                  },
                }
          }
        >
          <Styles.ModalBody>
            <ModalTitle
              text={`Cancel your
                ${toTitleCase(product)} ${
                  planType === 'paid' ? 'plan' : 'trial'
                }`}
            />
            <Text type="p">{copy}</Text>
            {error && (
              <Styles.ErrorWrapper>
                <Text type="p" color="red">
                  We&apos;re sorry, but there appears to be a problem with
                  canceling your plan. Please visit the{' '}
                  <Link href="https://faq.buffer.com/" newTab>
                    Buffer FAQ
                  </Link>{' '}
                  to contact us for help.
                </Text>
              </Styles.ErrorWrapper>
            )}
          </Styles.ModalBody>
        </Modal>
      )}
      {churnSurveyModalOpen && (
        <DeprecatedChurnSurveyModal
          submit={submitChurnSurvey}
          dismiss={closeChurnSurveyModal}
          product={product}
        />
      )}

      {!noButton && (
        // @ts-expect-error TS(2740) FIXME: Type '{ type: string; disabled: any; label: any; o... Remove this comment to see the full error message
        <Button
          type="text"
          disabled={disabled}
          label={buttonLabel}
          onClick={(): void => openCancelPlanModal({ product })}
        />
      )}
    </React.Fragment>
  )
}

CancelPlan.propTypes = {
  buttonLabel: PropTypes.string,
  cancelPlan: PropTypes.func,
  closeCancelPlanModal: PropTypes.func,
  copy: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  handleCancelPlan: PropTypes.func,
  isSubmitting: PropTypes.bool,
  label: PropTypes.string,
  notificationMessage: PropTypes.string,
  openCancelPlanModal: PropTypes.func,
  planType: PropTypes.string,
  product: PropTypes.string,
  productModal: PropTypes.string,
  resetNotification: PropTypes.func,
  showModal: PropTypes.bool,
  churnSurveyModalOpen: PropTypes.bool,
  closeChurnSurveyModal: PropTypes.func,
  submitChurnSurvey: PropTypes.func,
  closeParentModal: PropTypes.func,
  noButton: PropTypes.bool,
}

CancelPlan.defaultProps = {
  closeParentModal: (): void => undefined,
  noButton: false,
}

export default CancelPlan
