import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as Styles from './style'
import { CardTitle } from '../../account-components'

const CardStyled = styled.div`
  ${Styles.CardBase};
`

// @ts-expect-error TS(7031) FIXME: Binding element 'addMargin' implicitly has an 'any... Remove this comment to see the full error message
const Card = ({ addMargin, children, title, titleBorder }) => (
  <CardStyled>
    {title ? <CardTitle border={titleBorder}>{title}</CardTitle> : null}
    {children}
  </CardStyled>
)

Card.propTypes = {
  addMargin: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  title: PropTypes.string,
  titleBorder: PropTypes.bool,
}

Card.defaultProps = {
  children: null,
  titleBorder: true,
}

export default Card
