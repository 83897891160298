// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
import { actionTypes as creditCardActionTypes } from '../credit-card'

export const actionTypes = keyWrapper('CENTRALIZED_BILLING', {
  SELECT_ORGANIZATION: 'SELECT_ORGANIZATION',
  CHANGE_PLAN_REQUEST: 'CHANGE_PLAN_REQUEST',
  CLOSE_ALL_CHANGE_PLAN_MODALS: 'CLOSE_ALL_CHANGE_PLAN_MODALS',
  OPEN_CHANGE_PLAN_MODAL: 'OPEN_CHANGE_PLAN_MODAL',
  CLOSE_CHANGE_PLAN_MODAL: 'CLOSE_CHANGE_PLAN_MODAL',
  SELECT_PLAN_FOR_REVIEW: 'SELECT_PLAN_FOR_REVIEW',
  RESET_PLAN_FOR_REVIEW: 'RESET_PLAN_FOR_REVIEW',
  CLOSE_CHANGE_PLAN_NOTIFICATION: 'CLOSE_CHANGE_PLAN_NOTIFICATION',
  UPDATE_CHANGE_PLAN_MODAL_VIEW: 'UPDATE_CHANGE_PLAN_MODAL_VIEW',
  FETCH_INVOICES_REQUEST: 'FETCH_INVOICES_REQUEST',
  TOGGLE_CHANGE_PLAN_CYCLE: 'TOGGLE_CHANGE_PLAN_CYCLE',
})

export const initialState = {
  allStripeAccounts: [],
  changePlanSuccess: false,
  fetchBillingDetailsSuccess: false,
  error: '',
  invoices: [],
  currentCycle: 'month',
  currentPlan: '',
  currentProduct: '',
  isFetchingInvoices: false,
  hasMoreInvoices: false,
  isChangingPlan: false,
  isLoading: false,
  isSubmittingCreditCard: false,
  planForReview: null,
  selectedOrganizationDetails: {
    products: {
      analyze: {},
      analyzeTrial: {},
      publish: {},
      publishTrial: {},
    },
    billing: {},
  },
  showChangePlanModal: false,
  showChangePlanNotification: false,
  changePlanModalView: 'plans',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PLAN_REQUEST:
      return {
        ...state,
        isChangingPlan: true,
      }
    case actionTypes.CHANGE_PLAN_SUCCESS:
      return {
        ...state,
        isChangingPlan: false,
        changePlanSuccess: true,
        showChangePlanModal: false,
        planForReview: null,
        showChangePlanNotification: true,
      }
    case actionTypes.SELECT_ORGANIZATION:
      return {
        ...state,
        selectedOrganizationDetails: action.selectedOrganization,
        invoices: action.selectedOrganization.invoices || [],
        hasMoreInvoices: !!action.selectedOrganization.hasMoreInvoices,
      }
    case `updatePlan_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        isChangingPlan: true,
      }
    case `updatePlan_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        isChangingPlan: false,
      }
    case `updatePlan_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        isChangingPlan: false,
        changePlanSuccess: true,
        showChangePlanModal: false,
        planForReview: null,
        showChangePlanNotification: true,
        changePlanModalView: 'plans',
      }
    case creditCardActionTypes.CC_CARD_SAVE_REQUEST:
      return {
        ...state,
        isSubmittingCreditCard: true,
      }
    case creditCardActionTypes.CC_CARD_SAVE_SUCCESS:
      return {
        ...state,
        isSubmittingCreditCard: false,
        changePlanModalView: 'review',
      }
    case creditCardActionTypes.CC_CARD_SAVE_FAILURE:
      return {
        ...state,
        isSubmittingCreditCard: false,
      }
    case actionTypes.FETCH_INVOICES_REQUEST:
      return {
        ...state,
        isFetchingInvoices: true,
      }
    case `getBillingInvoices_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        isFetchingInvoices: false,
        invoices: [...state.invoices, ...action.result.invoices.invoices],
        hasMoreInvoices: action.result.invoices.hasMoreInvoices,
      }
    case actionTypes.CLOSE_CHANGE_PLAN_MODAL:
      return {
        ...state,
        showChangePlanModal: false,
        planForReview: null,
      }
    case actionTypes.OPEN_CHANGE_PLAN_MODAL: {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const currentPlan = state.selectedOrganizationDetails.products[
        action.currentProduct
      ].price
        ? // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state.selectedOrganizationDetails.products[action.currentProduct]
        : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state.selectedOrganizationDetails.products[
            `${action.currentProduct}Trial`
          ]
      return {
        ...state,
        showChangePlanModal: true,
        currentProduct: action.currentProduct,
        currentPlan: action.currentPlan || currentPlan,
        currentCycle: action.currentCycle || 'month', // default to month
        changePlanModalView: 'plans',
      }
    }
    case actionTypes.SELECT_PLAN_FOR_REVIEW:
      return {
        ...state,
        planForReview: action.plan,
      }
    case actionTypes.RESET_PLAN_FOR_REVIEW:
      return {
        ...state,
        planForReview: null,
      }
    case actionTypes.CLOSE_CHANGE_PLAN_NOTIFICATION:
      return {
        ...state,
        showChangePlanNotification: false,
      }
    case actionTypes.UPDATE_CHANGE_PLAN_MODAL_VIEW:
      return {
        ...state,
        changePlanModalView: action.view,
      }
    case actionTypes.TOGGLE_CHANGE_PLAN_CYCLE:
      return {
        ...state,
        currentCycle: state.currentCycle === 'month' ? 'year' : 'month',
      }
    default:
      return state
  }
}

export const actions = {
  selectOrganization: (selectedOrganization = {}) => ({
    type: actionTypes.SELECT_ORGANIZATION,
    selectedOrganization,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'stripeCustomerId' implicitly has ... Remove this comment to see the full error message
  fetchInvoices: ({ stripeCustomerId, limit, status, invoiceId }) => ({
    type: actionTypes.FETCH_INVOICES_REQUEST,
    limit,
    status,
    invoiceId, // ID of the last invoice to start from
    stripeCustomerId,
  }),
  changePlanSuccess: () => ({
    type: actionTypes.CHANGE_PLAN_SUCCESS,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'plan' implicitly has an 'any' typ... Remove this comment to see the full error message
  changePlan: ({ plan, cycle, product }) => ({
    type: actionTypes.CHANGE_PLAN_REQUEST,
    plan,
    cycle,
    product,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentProduct' implicitly has an... Remove this comment to see the full error message
  openChangePlanModal: ({ currentProduct, currentPlan, currentCycle }) => ({
    type: actionTypes.OPEN_CHANGE_PLAN_MODAL,
    currentProduct,
    currentPlan,
    currentCycle,
  }),
  closeChangePlanModal: () => ({
    type: actionTypes.CLOSE_CHANGE_PLAN_MODAL,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
  selectPlanForReview: (plan) => ({
    type: actionTypes.SELECT_PLAN_FOR_REVIEW,
    plan,
  }),
  resetPlanForReview: () => ({
    type: actionTypes.RESET_PLAN_FOR_REVIEW,
  }),
  closeChangePlanNotification: () => ({
    type: actionTypes.CLOSE_CHANGE_PLAN_NOTIFICATION,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'view' implicitly has an 'any' type.
  updateChangePlanModalView: (view) => ({
    type: actionTypes.UPDATE_CHANGE_PLAN_MODAL_VIEW,
    view,
  }),
  toggleChangePlanCycle: () => ({
    type: actionTypes.TOGGLE_CHANGE_PLAN_CYCLE,
  }),
}
