import React from 'react'
import { Text, Modal, Button } from '@bufferapp/ui'

import { H3 } from '../Text'
import { Wrapper, ModalContent, ModalFooter, Title } from './Modal'

// @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
function getNewPlanAmount(plan, cycle) {
  const monthsInAYear = 12
  const discount = 0.2

  switch (cycle) {
    case 'year': {
      const annualCost = plan.amount * monthsInAYear
      return annualCost - annualCost * discount
    }
    default: {
      const monthlyCost = plan.amount / monthsInAYear
      return monthlyCost * (1 / (1 - discount))
    }
  }
}

const ChangeBillingCycleModal = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'plan' implicitly has an 'any' typ... Remove this comment to see the full error message
  plan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'changeBillingCycle' implicitly ha... Remove this comment to see the full error message
  changeBillingCycle,
  // @ts-expect-error TS(7031) FIXME: Binding element 'close' implicitly has an 'any' ty... Remove this comment to see the full error message
  close,
  // @ts-expect-error TS(7031) FIXME: Binding element 'changingBillingCycle' implicitly ... Remove this comment to see the full error message
  changingBillingCycle,
}) => {
  const newCycle = plan.interval === 'month' ? 'year' : 'month'
  const amoutString = `($${getNewPlanAmount(plan, newCycle)}/${newCycle})`
  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; action: {}; }' is not a... Remove this comment to see the full error message
    <Modal action={{}}>
      <Wrapper>
        <Title>
          {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
          <Text>
            <H3>
              Are you sure you want to switch your subscription billing cycle?
            </H3>
          </Text>
        </Title>
        <ModalContent>
          {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: (s... Remove this comment to see the full error message */}
          <Text>
            You’re about to switch to a{' '}
            <b>
              {`${newCycle}ly`} billing cycle {amoutString}
            </b>
            , with a new billing period starting today. Any payments you’ve made
            will be prorated and credited to your account.
          </Text>
        </ModalContent>
        <ModalFooter>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
          <Button
            type="text"
            label="No I don't want to switch"
            onClick={close}
          />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
          <Button
            type="primary"
            label={`Switch to ${newCycle}ly billing`}
            onClick={() => changeBillingCycle(newCycle)}
            disabled={changingBillingCycle}
          />
        </ModalFooter>
      </Wrapper>
    </Modal>
  )
}

export default ChangeBillingCycleModal
