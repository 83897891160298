import { AppStore, type AppLink } from '../../../../types'

// @ts-expect-error TS(7006) FIXME: Parameter 'account' implicitly has an 'any' type.
export function getCurrentPlanFromAccount(account) {
  return account?.currentOrganization?.billing?.subscription?.plan
}

// @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
export function isFreePlan(plan) {
  return plan && plan.id === 'free'
}

// @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
export function isTieredPlan(plan) {
  return plan && plan.id === 'agency'
}

// @ts-expect-error TS(7006) FIXME: Parameter 'organization' implicitly has an 'any' t... Remove this comment to see the full error message
export function isRevenueCatBillingGateway(organization) {
  const billingGateway = organization?.billing?.gateway?.gatewayType

  if (billingGateway && billingGateway === 'revenuecat') {
    return true
  }

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'organization' implicitly has an 'any' t... Remove this comment to see the full error message
export function isStripeBillingGateway(organization) {
  if (!organization?.isOneBufferOrganization) {
    return organization?.billingDetails?.allStripeAccounts?.length > 0
  }

  const billingGateway = organization?.billing?.gateway?.gatewayType

  if (billingGateway && billingGateway === 'stripe') {
    return true
  }

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'organization' implicitly has an 'any' t... Remove this comment to see the full error message
export const getGatewayStore = (organization): AppStore => {
  const billingGatewayPlatform = organization?.billing?.gateway?.gatewayPlatform

  if (billingGatewayPlatform === 'android') {
    return AppStore.GooglePlayStore
  }

  if (billingGatewayPlatform === 'apple') {
    return AppStore.AppleAppStore
  }

  return AppStore.MobileApp
}

// @ts-expect-error TS(7006) FIXME: Parameter 'organization' implicitly has an 'any' t... Remove this comment to see the full error message
export function getAppsStoreLinks(organization): AppLink {
  const billingGatewayPlatform = organization?.billing?.gateway?.gatewayPlatform

  if (billingGatewayPlatform === 'android') {
    return {
      app: 'Buffer for Android',
      link: 'https://play.google.com/store/apps/details?id=org.buffer.android',
    }
  }

  if (billingGatewayPlatform === 'apple') {
    return {
      app: 'Buffer for iOS',
      link: 'https://apps.apple.com/app/apple-store/id490474324',
    }
  }

  return {
    app: 'Buffer mobile',
    link: null,
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'organization' implicitly has an 'any' t... Remove this comment to see the full error message
export function hasActiveTrial(organization) {
  const trialIsActive = organization?.billing?.subscription?.trial?.isActive

  if (trialIsActive && trialIsActive === true) {
    return true
  }
  return false
}

// This function is used to decide if we should show upgrade paths to a user.
// We base this off whether a user is a Web(Stripe) paying user
// Or a Mobile(revenuecat) paying user
// The reason for this is if a user is already paying a subscription on Mobile
// and they make it through to the payment upgrade path on web
// they will end up with 2 subscriptions (they are paying twice)
// Exception is any free plan customer should be able to upgrade on any platform
// @ts-expect-error TS(7006) FIXME: Parameter 'organization' implicitly has an 'any' t... Remove this comment to see the full error message
export function shouldShowUpgradePaths(organization, plan) {
  const isFreeUser = isFreePlan(plan)
  const isMobileBillingGateway = isRevenueCatBillingGateway(organization)

  return (isFreeUser && isMobileBillingGateway) || !isMobileBillingGateway
}
// checks if RevenueCat (mobile) is a paying customer
// @ts-expect-error TS(7006) FIXME: Parameter 'organization' implicitly has an 'any' t... Remove this comment to see the full error message
export function isPaidRevenueCatBillingGateway(organization, plan) {
  const isFreeUser = isFreePlan(plan)
  const isMobileBillingGateway = isRevenueCatBillingGateway(organization)

  return !isFreeUser && isMobileBillingGateway
}

// checks if use hasPaymentDetails attached to account
// @ts-expect-error TS(7006) FIXME: Parameter 'account' implicitly has an 'any' type.
export function hasPaymentDetails(account) {
  return account?.currentOrganization?.billing?.paymentDetails.hasPaymentDetails
}
