import { LOCATION_CHANGE } from 'connected-react-router'

import {
  actionTypes as dataFetchActionTypes,
  actions as dataFetchActions,
  // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
} from '@bufferapp/async-data-fetch'
import { actions as analyticsActions } from '@bufferapp/analytics-middleware'

import { shouldTrackAnalytics, getPath } from './selectors'
import { actions, actionTypes } from './reducer'
import { redirectToLogin } from './utils/redirect'

const MS_BEFORE_REFRESH = 3 * 1000

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function trackPage(state) {
  const path = getPath(state)
  const name = `account${path.length > 1 ? ' ' : ' homepage'}${path
    .replace(/^\//, '')
    .replace('/', ' ')}`
  return analyticsActions.pageChange({ path, name })
}

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)

    switch (action.type) {
      case actionTypes.GET_ACCOUNT_SUCCESS: {
        const state = getState()
        if (shouldTrackAnalytics(state)) {
          // tracking a pageview on account load because the
          // LOCATION_CHANGE action happens before the account
          // has loaded and we skip tracking in that case
          dispatch(trackPage(state))
        }
        break
      }

      case actionTypes.GET_ACCOUNT_FAILURE: {
        redirectToLogin()
        break
      }

      /**
       * Update email
       */
      case actionTypes.UPDATE_EMAIL: {
        dispatch(
          dataFetchActions.fetch({
            name: 'updateEmail',
            args: {
              newEmail: action.newEmail,
              password: action.currPassword,
            },
          }),
        )
        break
      }

      case `updateEmail_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        // show confirmation
        console.info(
          `updateEmail_${dataFetchActionTypes.FETCH_SUCCESS}`,
          action,
        )
        // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
        dispatch(actions.emailUpdateRequested(action.result.email))
        break
      }

      case `updateEmail_${dataFetchActionTypes.FETCH_FAIL}`: {
        dispatch(actions.emailUpdateFailed(action.error))
        break
      }

      /**
       * Update password
       */
      case actionTypes.UPDATE_PASSWORD: {
        dispatch(
          dataFetchActions.fetch({
            name: 'updatePassword',
            args: {
              newPassword: action.newPassword,
              password: action.currPassword,
            },
          }),
        )
        break
      }

      case `updatePassword_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        // show confirmation
        dispatch(actions.passwordUpdated())
        // refresh the page after 3 seconds, the session won't be
        // valid and the user will be sent to the login page
        setTimeout(() => window.location.assign('/'), MS_BEFORE_REFRESH)
        break
      }

      case `updatePassword_${dataFetchActionTypes.FETCH_FAIL}`: {
        dispatch(actions.passwordUpdateFailed(action.error))
        break
      }

      case LOCATION_CHANGE: {
        const state = getState()
        if (shouldTrackAnalytics(state)) {
          dispatch(trackPage(state))
        }

        break
      }

      default:
        break
    }
  }
