import { connect } from 'react-redux'
import CancelPlan from './components/CancelPlan'
import reducer, { actions, actionTypes } from './reducer'
import middleware from './middleware'

// default export = container
export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'cancelPlan' does not exist on type 'Defa... Remove this comment to see the full error message
    ...state.cancelPlan,
    currentOrganizationId: state.account.currentOrganization.id,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
    cancelPlan: ({ product, planType }) =>
      dispatch(
        actions.cancelPlan({
          product,
          planType,
        }),
      ),
    // @ts-expect-error TS(7006) FIXME: Parameter 'product' implicitly has an 'any' type.
    openCancelPlanModal: (product) =>
      dispatch(actions.openCancelPlanModal(product)),
    closeCancelPlanModal: () => dispatch(actions.closeCancelPlanModal()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
    showNotification: (message) => dispatch(actions.showNotification(message)),
    resetNotification: () => dispatch(actions.resetNotification()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
    submitChurnSurvey: (data) => dispatch(actions.submitChurnSurvey(data)),
    closeChurnSurveyModal: () => dispatch(actions.closeChurnSurveyModal()),
  }),
)(CancelPlan)

// export reducer, actions and action types
export { reducer, actions, actionTypes, middleware }
