import React from 'react'
import PropTypes from 'prop-types'

// @ts-expect-error TS(7031) FIXME: Binding element 'width' implicitly has an 'any' ty... Remove this comment to see the full error message
export const AnalyzeLogo = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 181 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.597 4.17871C7.3333 4.17871 0.64624 10.8598 0.64624 19.116C0.64624 27.3723 7.3333 34.0534 15.597 34.0534C23.8607 34.0534 30.5477 27.3723 30.5477 19.116H15.597V4.17871Z"
      fill="#F3AFB9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6253 15.0423C34.6253 6.78604 27.9382 0.10498 19.6746 0.10498V15.0423H34.6253Z"
      fill="#132062"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.1115 20.4248L54.3874 9.34406L50.6362 20.4248H58.1115ZM59.7697 25.3949H48.9508L47.3198 30.2835H41.5298L51.0439 3.15186H57.6766L67.2179 30.2835H61.4007L59.7697 25.3949ZM87.6053 18.3876V30.2832H82.6036V19.0123C82.6036 16.3779 81.027 15.0199 78.7436 15.0199C76.2971 15.0199 74.4758 16.4322 74.4758 19.827V30.2832H69.4741V10.8918H74.4758V13.0645C75.6447 11.3263 77.6562 10.3486 80.2658 10.3486C84.3705 10.3758 87.6053 13.2546 87.6053 18.3876ZM100.273 15.319C103.372 15.319 105.682 17.5189 105.682 20.7779C105.682 23.9827 103.372 26.2368 100.273 26.2368C97.2282 26.2368 94.9176 24.037 94.9176 20.7779C94.9176 17.5189 97.201 15.319 100.273 15.319ZM110.684 30.4464V11.0822H105.682V13.3907C104.269 11.6254 102.203 10.5391 99.3485 10.5391C94.2108 10.5391 89.9431 14.9931 89.9431 20.7779C89.9431 26.5627 94.1837 31.0168 99.3485 31.0168C102.176 31.0168 104.296 29.9033 105.682 28.1651V30.4464H110.684ZM119.573 3.15186H114.571V30.2563H119.573V3.15186ZM134.904 30.283L141.945 10.9188V10.8916H136.589L132.295 23.9007L127.238 10.8916H121.775L129.739 29.6583C128.788 32.2384 127.402 33.4062 124.656 33.3791V38.0232C129.603 38.2948 132.893 35.8777 134.904 30.283ZM159.07 25.5852V30.2836H143.738V26.9431L151.92 15.6179H144.119V10.9194H158.662V14.26L150.453 25.5852H159.07ZM170.269 14.857C172.362 14.857 174.455 15.9977 175.08 18.7679H165.186C165.784 16.2692 167.578 14.857 170.269 14.857ZM175.026 24.3626C174.183 25.4761 172.634 26.2908 170.704 26.2908C168.094 26.2908 165.974 25.2045 165.24 22.6516H179.865C180.001 21.9726 180.055 21.3208 180.055 20.5875C180.055 14.857 175.977 10.3486 170.269 10.3486C164.153 10.3486 160.021 14.8027 160.021 20.5875C160.021 26.3723 164.126 30.8264 170.65 30.8264C174.347 30.8264 177.255 29.3326 179.076 26.6982L175.026 24.3626Z"
      fill="#231F20"
    />
  </svg>
)

AnalyzeLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}

AnalyzeLogo.defaultProps = {
  width: '181',
  height: '39',
}

export default AnalyzeLogo
