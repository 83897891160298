export const numberWithCurrency = (
  amount: number,
  currency: string,
): string => {
  const locale = navigator.language || 'en-EN'
  return amount.toLocaleString(locale, {
    style: 'currency',
    currency: currency.toUpperCase(),
  })
}
