import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'

import * as Styles from './styles'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

const Body = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'paidPlanMessage' implicitly has a... Remove this comment to see the full error message
  paidPlanMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planDetails' implicitly has an 'a... Remove this comment to see the full error message
  planDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasCardOnFile' implicitly has an ... Remove this comment to see the full error message
  hasCardOnFile,
  // @ts-expect-error TS(7031) FIXME: Binding element 'noTrialStarted' implicitly has an... Remove this comment to see the full error message
  noTrialStarted,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trialExpired' implicitly has an '... Remove this comment to see the full error message
  trialExpired,
}) => {
  const {
    discount,
    isActiveTrial,
    subscriptionCanceledDate,
    subscriptionEndDate,
    subscriptionIsCanceled,
    trialEndDate,
  } = planDetails

  return (
    <Styles.BodyWrapper>
      <div>
        {paidPlanMessage && (
          <Styles.MessageWrapper>{paidPlanMessage}</Styles.MessageWrapper>
        )}
      </div>
      <Styles.List>
        {discount && (
          <Styles.ListItem>
            Discounts Applied:{' '}
            <strong>
              {discount.name} (${discount.discount} off)
            </strong>
          </Styles.ListItem>
        )}
        {noTrialStarted ? (
          <React.Fragment>
            <Styles.ListItem>
              You&apos;re on a Legacy Buffer plan. Update plan to get access.
            </Styles.ListItem>
          </React.Fragment>
        ) : null}
        {trialExpired && !noTrialStarted ? (
          <React.Fragment>
            <Styles.ListItem>Your trial has ended.</Styles.ListItem>
          </React.Fragment>
        ) : null}
        {isActiveTrial && (
          <React.Fragment>
            <Styles.ListItem>
              Your trial expires {dayjs.unix(trialEndDate).from(Date.now())} on{' '}
              {dayjs.unix(trialEndDate).format('MMMM Do, YYYY')}.
            </Styles.ListItem>
            {hasCardOnFile && (
              <Styles.ListItem>
                At the end of your trial, you will automatically be upgraded to
                a paid plan.
              </Styles.ListItem>
            )}
          </React.Fragment>
        )}
      </Styles.List>

      <Text type="p">
        {subscriptionIsCanceled
          ? `This plan was canceled on ${dayjs
              .unix(subscriptionCanceledDate)
              .format(
                'MMMM Do, YYYY',
              )}. You can continue to use this plan's features until ${dayjs
              .unix(subscriptionEndDate)
              .format('MMMM Do, YYYY')}.`
          : ''}
      </Text>
    </Styles.BodyWrapper>
  )
}

Body.propTypes = {
  hasCardOnFile: PropTypes.bool,
  noTrialStarted: PropTypes.bool,
  paidPlanMessage: PropTypes.string,
  planDetails: PropTypes.shape({
    cycle: PropTypes.string,
    discount: PropTypes.shape({
      name: PropTypes.string,
      discount: PropTypes.number,
      type: PropTypes.string,
    }),
    isActiveTrial: PropTypes.bool,
    subscriptionCanceledDate: PropTypes.number,
    subscriptionEndDate: PropTypes.number,
    subscriptionIsCanceled: PropTypes.bool,
    trialEndDate: PropTypes.number,
  }),
  trialExpired: PropTypes.bool,
}

export default Body
