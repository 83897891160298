import type { TaxDetails } from './types'

export function hasValidTaxAddress(
  taxDetails: TaxDetails | undefined,
): boolean {
  if (!taxDetails) return false

  const customerTaxStatus = taxDetails.automaticTax

  switch (customerTaxStatus) {
    case 'unrecognized_location':
    case 'failed':
      return false
    case 'supported':
    case 'not_collecting':
      return true

    default:
      return false
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function isPayingStripeCustomer(user): boolean {
  const isOnStripeGateway =
    user?.currentOrganization?.billing?.gateway?.gatewayType === 'stripe'
  const isOnPaidPlan =
    user?.currentOrganization?.billing?.subscription?.plan?.id !== 'free'

  return Boolean(isOnStripeGateway && isOnPaidPlan)
}

export function isCustomerLocatedInTaxArea(
  customerTaxStats: string | undefined,
): boolean {
  if (!customerTaxStats) return false

  if (customerTaxStats === 'supported') return true

  return false
}
