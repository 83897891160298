import type { Account } from '../web/src/pages/Channels/auth-flows/shared/SelectChannelList/utils'

const PRODUCT_PUBLISH = 'publish'
const PRODUCT_ANALYZE = 'analyze'

export function getEnvironment(): string {
  const getEnvironment = (env: string): string =>
    ({
      development: 'local',
      staging: 'staging',
      production: 'production',
    })[env] as string
  return getEnvironment(window._ENV) || getEnvironment('production')
}

/**
 *
 */
export const getFilteredQueryParams = (queryParams = {}): string => {
  const filteredQueryParams = Object.keys(queryParams)
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .filter((key) => queryParams[key])
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join('&')
  return filteredQueryParams ? `?${filteredQueryParams}` : ''
}

export const getProductUrl = (product: string): string => {
  const environment = getEnvironment()

  if (environment === 'production') {
    return `https://${product}.buffer.com`
  } else if (environment === 'staging') {
    return `https://${product}.dev.buffer.com`
  } else {
    let port = 8080 // account and engage
    if (product === PRODUCT_PUBLISH) port = 8888
    if (product === PRODUCT_ANALYZE) port = 8081
    return `https://${product}.local.buffer.com:${port}`
  }
}

export const getMigrationHubUrl = (cta?: string): string => {
  const environment = getEnvironment()

  const ctaParam = cta ? `/?cta=${cta}` : ``
  if (environment === 'production') {
    return `https://account.buffer.com/new-plans${ctaParam}`
  } else if (environment === 'staging') {
    return `https://account.dev.buffer.com/new-plans${ctaParam}`
  } else {
    return `https://account.local.buffer.com/new-plans${ctaParam}`
  }
}

/**
 *
 */
type GetProductUrlParams = {
  product: string
  environment: string
  cta?: string
}
export const getStartTrialUrl = ({
  product,
  environment,
  cta,
}: GetProductUrlParams): string | null => {
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
  const loginUrl = getProductUrl('login', environment)
  const signupUrl = `${loginUrl}/signup`
  const [, incomingCta] = window.location.search.match(/cta=(.*)&*/) || [
    null,
    null,
  ]

  switch (product) {
    case PRODUCT_ANALYZE: {
      const params = {
        product,
        cta: cta || incomingCta || 'analyzeApp-appShell-crossSell-startTrial-1',
      }
      return `${signupUrl}${getFilteredQueryParams(params)}`
    }
    case PRODUCT_PUBLISH:
    default:
      return null
  }
}

/**
 *
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function getCurrentPlanFromAccount(user): string {
  return user?.currentOrganization?.billing?.subscription?.plan
}
/**
 *
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function getCurrentPlanNameFromAccount(user): string {
  return user?.currentOrganization?.billing?.subscription?.plan?.name
}

/**
 *
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function isFreeUser(user): boolean {
  return user?.currentOrganization?.billing?.subscription?.plan?.id === 'free'
}

/**
 *
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function isTrialUser(user): boolean {
  return (
    user?.currentOrganization?.billing?.subscription?.trial?.isActive || false
  )
}

/**
 *
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function isNewBufferUser(user): boolean {
  return user?.currentOrganization?.isOneBufferOrganization
}

/**
 *
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function getUserChannelLimit(user): number {
  return (
    user?.currentOrganization?.channelLimit ||
    user?.currentOrganization?.limits?.channels
  )
}

/**
 *
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function getUserChannelCount(user): number {
  const channels = user?.currentOrganization?.channels || []

  return channels.length
}

// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function getUserUnlockedChannelCount(user): number {
  const unlockedChannels =
    user?.currentOrganization?.channels?.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
      (channel) => !channel?.isLocked,
    ) || []

  return unlockedChannels.length
}

// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function isAtPlanLimit(user): boolean {
  const channelLimit = getUserChannelLimit(user)
  const channelCount = getUserUnlockedChannelCount(user)

  return channelCount >= channelLimit
}

export function canStartTrial(user: Account): boolean {
  return user?.currentOrganization?.billing?.canStartTrial
}

export const getSignUpDateInEpochsMs = (user: Account): number => {
  const currentOrganizationCreationDate = user?.currentOrganization?.createdAt

  return currentOrganizationCreationDate
    ? new Date(currentOrganizationCreationDate).getTime()
    : 0
}

export const getTrialStartDateInEpochsMs = (user: Account): number => {
  const trialStartDate =
    user?.currentOrganization?.billing?.subscription?.trial?.startDate

  return trialStartDate ? new Date(trialStartDate).getTime() : 0
}

export { getChannelLimitsByPlanName } from './getAnalyzeChannelLimits'
export { capitalizeFirstLetter, toTitleCase } from './strings'

export function getBrowserTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/London'
}

export function getUrlEnvModifier(): string {
  const [, envModifier] = window.location.hostname.match(
    /\w+\.(\w+\.)buffer\.com/,
  ) || [null, '']
  return envModifier
}
