import React, { type Dispatch } from 'react'
import type { ChannelConnections } from './types'
import type { ReducerAction as ChannelConnectionsReducerAction } from '../../components/ChannelConnections/ChannelConnectionsProvider/channelConnectionsReducer'

export const ChannelConnectionsContextDefaultValue = {} as ChannelConnections
export const ChannelConnectionsDispatchContextDefaultValue =
  {} as Dispatch<ChannelConnectionsReducerAction>

export const ChannelConnectionsContext = React.createContext<
  ChannelConnections | undefined
>(undefined)

export const ChannelConnectionsDispatchContext = React.createContext<
  Dispatch<ChannelConnectionsReducerAction> | undefined
>(undefined)

export function useChannelConnections(): ChannelConnections {
  const context = React.useContext(ChannelConnectionsContext)
  if (context === undefined) {
    throw new Error(
      'useChannelConnections must be used within a ChannelConnectionsContext.Provider',
    )
  }

  return context
}

export function useChannelConnectionsDispatch(): Dispatch<ChannelConnectionsReducerAction> {
  const context = React.useContext(ChannelConnectionsDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useChannelConnectionsDispatchActions must be used within a ChannelConnectionsDispatchContext.Provider',
    )
  }

  return context
}
