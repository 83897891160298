import {
  actions as asyncDataFetchActions,
  actionTypes as asyncDataFetchActionTypes,
  // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
} from '@bufferapp/async-data-fetch'
import { actionTypes, actions } from './reducer'
import { actions as analyticsActions } from '@bufferapp/analytics-middleware'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.CANCEL_PLAN_REQUEST:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'cancelPlan',
          args: {
            product: action.product,
            planType: action.planType,
          },
        }),
      )
      break
    case `cancelPlan_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      store.dispatch(actions.openChurnSurveyModal())
      store.dispatch(
        actions.showNotification('Your plan was successfully canceled.'),
      )
      setTimeout(() => {
        store.dispatch(actions.resetNotification())
      }, 7000)

      break
    case actionTypes.SUBMIT_CHURN_SURVEY:
      store.dispatch(
        analyticsActions.trackEvent('churnSurveyCompleted', {
          product: action.data.product,
          reason: action.data.reason,
          details: action.data.details,
          organizationId: store.getState().currentOrganizationId,
        }),
      )

      store.dispatch(actions.closeChurnSurveyModal())

      break
    default:
      break
  }
  return next(action)
}
