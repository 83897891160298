// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

// We currently have billing-details package to handle Analyze payments.
// We'll eventually move to centralized-billing and deprecate billing-details.
// Until we do, these are sharing similar functionality, so I've pre-pended
// action types with 'CC' to avoid any possible collisions.
export const actionTypes = keyWrapper('CREDIT_CARD', {
  CC_CARD_SAVE_REQUEST: 'CC_CARD_SAVE_REQUEST',
  CC_CARD_SAVE_FAILURE: 'CC_CARD_SAVE_FAILURE',
  CC_CARD_SAVE_SUCCESS: 'CC_CARD_SAVE_SUCCESS',
  CC_OPEN_CREDIT_CARD_FORM_MODAL: 'CC_OPEN_CREDIT_CARD_FORM_MODAL',
  CC_CLOSE_CREDIT_CARD_FORM_MODAL: 'CC_CLOSE_CREDIT_CARD_FORM_MODAL',
  CC_SHOW_CREDIT_CARD_NOTIFICATION: 'CC_SHOW_CREDIT_CARD_NOTIFICATION',
  CC_RESET_CREDIT_CARD_NOTIFICATION: 'CC_RESET_CREDIT_CARD_NOTIFICATION',
})

const initialState = {
  handleCardSetupError: '',
  isSubmitting: false,
  showCreditCardFormModal: false,
  isLoading: false,
  creditCardSuccessMessage: '',
  creditCardFailureMessage: '',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CC_CARD_SAVE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      }
    case actionTypes.CC_CARD_SAVE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        creditCardFailureMessage: '',
      }
    case actionTypes.CC_CARD_SAVE_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        creditCardFailureMessage: action.message,
      }
    case actionTypes.CC_OPEN_CREDIT_CARD_FORM_MODAL:
      return {
        ...state,
        showCreditCardFormModal: true,
      }
    case actionTypes.CC_CLOSE_CREDIT_CARD_FORM_MODAL:
      return {
        ...state,
        showCreditCardFormModal: false,
        creditCardFailureMessage: '',
      }
    case actionTypes.CC_SHOW_CREDIT_CARD_NOTIFICATION:
      return {
        ...state,
        creditCardSuccessMessage: action.message,
      }
    case actionTypes.CC_RESET_CREDIT_CARD_NOTIFICATION:
      return {
        ...state,
        creditCardSuccessMessage: '',
      }
    default:
      return state
  }
}

export const actions = {
  saveCardDetails: () => ({
    type: actionTypes.CC_CARD_SAVE_REQUEST,
  }),
  openCreditCardFormModal: () => ({
    type: actionTypes.CC_OPEN_CREDIT_CARD_FORM_MODAL,
  }),
  closeCreditCardFormModal: () => ({
    type: actionTypes.CC_CLOSE_CREDIT_CARD_FORM_MODAL,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  showCreditCardNotification: (message) => ({
    type: actionTypes.CC_SHOW_CREDIT_CARD_NOTIFICATION,
    message,
  }),
  resetCreditCardNotification: () => ({
    type: actionTypes.CC_RESET_CREDIT_CARD_NOTIFICATION,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  creditCardSaveSuccess: (message) => ({
    type: actionTypes.CC_CARD_SAVE_SUCCESS,
    message,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  creditCardFailure: (message) => ({
    type: actionTypes.CC_CARD_SAVE_FAILURE,
    message,
  }),
}
