import React from 'react'
import PropTypes from 'prop-types'

import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const RadioRow = ({ children, id, name, onClick, selected }) => {
  return (
    <Styles.Label selected={selected === id}>
      <Styles.ContentWrapper>
        <Styles.RadioWrapper>
          <Styles.RadioBox
            type="radio"
            name={name}
            id={id}
            onChangeCapture={(e: any) => {
              onClick(id)
            }}
            checked={selected === id}
          />
        </Styles.RadioWrapper>
        {children}
      </Styles.ContentWrapper>
    </Styles.Label>
  )
}

RadioRow.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.string,
}

export default RadioRow
