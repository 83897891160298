import styled from 'styled-components'

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(29, 29, 29, 0.7);
  transition: all 0.3s;

  & > div {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 80px);
  }
`
