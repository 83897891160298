import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import { fontWeightBold } from '@bufferapp/ui/style/fonts'
import { grayLighter } from '@bufferapp/ui/style/colors'
import * as Styles from '../../../../../../account-components/Card/style'

export const ChannelRow = styled.div<{
  isLocked: boolean
}>`
  ${Styles.CardBase};
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-content: space-between;
  ${(props) => {
    if (props.isLocked) return `background-color: ${grayLighter};`
  }}
  box-shadow: none;
`

export const TextStyled = styled(Text)`
  font-weight: ${fontWeightBold} !important;
  width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1.5rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-basis: 10rem;
  justify-content: flex-end;
`
