export const actionTypes = {
  ACTIVE_PRODUCTS_LOAD_SUCCESS: 'ACTIVE_PRODUCTS_LOAD_SUCCESS',
  ACTIVE_PRODUCTS_LOAD_FAILED: 'ACTIVE_PRODUCTS_LOAD_FAILED',
}

const initialState = {
  updatesDisabled: false,
  activeProducts: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIVE_PRODUCTS_LOAD_SUCCESS: {
      return {
        ...state,
        activeProducts: [...action.activeProducts],
      }
    }
    default: {
      return state
    }
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'activeProducts' implicitly has an 'any'... Remove this comment to see the full error message
  activeProductsLoadSuccess: (activeProducts) => ({
    type: actionTypes.ACTIVE_PRODUCTS_LOAD_SUCCESS,
    activeProducts,
  }),
  activeProductsLoadFailed: () => ({
    type: actionTypes.ACTIVE_PRODUCTS_LOAD_FAILED,
    activeProducts: [],
  }),
}
