/**
 * Formats number to currency with 2 decimals and no decimal for whole numbers
 * @param {number} value number to format.
 * @return {string} formatted string
 */
export function formatCurrency(value: number): string {
  if (value) {
    if (Number.isInteger(value)) {
      return value.toString()
    } else {
      return value.toFixed(2)
    }
  } else {
    return '0'
  }
}
