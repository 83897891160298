import gql from 'graphql-tag'

export const GET_ACCOUNT = gql`
  query AccountForCreditCard {
    account {
      id
      email
      createdAt
      featureFlips
      isImpersonation
      shouldShowEmailVerificationCommunication
      currentOrganization {
        id
        isOneBufferOrganization
        name
        canEdit
        privileges {
          canManageBilling
        }
        canAddPaymentDetails
        role
        createdAt
        channelLimit
        featureFlips
        isOneBufferOrganization
        entitlements
        limits {
          channels
        }
        billingNotifications {
          shouldUpgradeMobileSubscription
          shouldDowngradeMobileSubscription
        }
        members {
          totalCount
        }
        channels {
          id
          isLocked
        }
        billingDetails {
          allStripeAccounts {
            id
            name
            created
            billing {
              card {
                brand
                last4
                expMonth
                expYear
              }
            }
            customerIsPausedWithCoupon
            delinquent
            hasMoreInvoices
            products {
              analyze {
                cycle
                cycleEndDate
                discount {
                  type
                  discount
                  name
                }
                isActiveTrial
                planName
                price
                product
                subscriptionIsCanceled
                subscriptionCanceledDate
                subscriptionEndDate
                subscriptionId
                subscriptionIsPaused
                trialEndDate
                type
              }
              analyzeTrial {
                cycle
                cycleEndDate
                discount {
                  type
                  discount
                  name
                }
                isActiveTrial
                planName
                price
                product
                subscriptionIsCanceled
                subscriptionCanceledDate
                subscriptionEndDate
                subscriptionId
                subscriptionIsPaused
                trialEndDate
                type
              }
              publish {
                cycle
                cycleEndDate
                discount {
                  type
                  discount
                  name
                }
                isActiveTrial
                planName
                price
                product
                subscriptionIsCanceled
                subscriptionCanceledDate
                subscriptionEndDate
                subscriptionId
                subscriptionIsPaused
                trialEndDate
                type
              }
              publishTrial {
                cycle
                cycleEndDate
                discount {
                  type
                  discount
                  name
                }
                isActiveTrial
                planName
                price
                product
                subscriptionIsCanceled
                subscriptionCanceledDate
                subscriptionEndDate
                subscriptionId
                subscriptionIsPaused
                trialEndDate
                type
              }
            }
            invoices {
              id
              amount
              date
              downloadUrl
              status
            }
          }
        }
        billing {
          ... on OBBilling {
            canStartTrial
            balance
            discount {
              gatewayId
              name
              type
              duration
              value
              durationInMonths
            }
            gateway {
              gatewayType
              gatewayPlatform
            }
            paymentDetails {
              creditCard {
                brand
                last4
                expMonth
                expYear
              }
            }
            upcomingInvoiceAmount
            subscription {
              periodEnd
              isCanceledAtPeriodEnd
              canceledAt
              trial {
                isActive
                remainingDays
                endDate
              }
              plan {
                id
                name
                interval
                limits {
                  channels
                  members
                }
                prices {
                  baseMonthlyPrice
                  basePlanPrice
                  monthlyFlatFee
                  monthlyChannelPrice
                  channelTierUnit
                }
              }
            }
          }
          ... on MPBilling {
            canAccessPublishing
            billingRedirectUrl
            balance
            discount {
              gatewayId
              name
              type
              duration
              value
              durationInMonths
            }
            subscriptions {
              plan
              product
            }
          }
        }
      }
      channels {
        id
        name
        service
        avatar
        isDisconnected
        organizationId
      }
      organizations {
        id
        name
      }
      products {
        name
        userId
      }
    }
  }
`
