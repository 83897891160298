import styled from 'styled-components'
import { grayLighter, grayLight } from '@bufferapp/ui/style/colors'

export const Text = styled.div<{
  width?: string
  margin?: string
}>`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0'};
  height: 18px;
  border-radius: 4px;
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;

  @keyframes pulse {
    from {
      background-color: ${grayLighter};
    }
    50% {
      background-color: ${grayLight};
    }
    to {
      background-color: ${grayLighter};
    }
  }
`
