import React from 'react'
import PropTypes from 'prop-types'
import { grayLight } from '@bufferapp/ui/style/colors'

import {
  Circle,
  SmallCircle,
  CircleWrapper,
  Container,
  Line,
  LineWrapper,
  MoreIconStyled,
} from './style'

// @ts-expect-error TS(7031) FIXME: Binding element 'lastChannelOnList' implicitly has... Remove this comment to see the full error message
const ExampleChannel = ({ lastChannelOnList }) => {
  return (
    <Container lastChannelOnList={lastChannelOnList}>
      <CircleWrapper>
        <Circle />
        <SmallCircle />
      </CircleWrapper>
      <LineWrapper>
        <Line width="146px" height="16px" marginBottom="8px" />
        <Line width="110px" height="16px" />
      </LineWrapper>
      <MoreIconStyled color={grayLight} />
    </Container>
  )
}

ExampleChannel.propTypes = {
  displaySkeleton: PropTypes.bool,
  lastChannelOnList: PropTypes.bool,
}

ExampleChannel.defaultProps = {
  displaySkeleton: false,
  lastChannelOnList: false,
}

export default ExampleChannel
