import React from 'react'
import PropTypes from 'prop-types'

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const CircleCheckmarkIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={props['data-testid']}
  >
    <ellipse cx="8" cy="8.5" rx="8" ry="8.44444" fill={props.color} />
    <path
      d="M3.06065 8.65457C2.67013 9.06679 2.67013 9.73513 3.06065 10.1474L5.88908 13.1329L12.9601 5.66901C13.3507 5.25679 13.3507 4.58845 12.9601 4.17623C12.5696 3.76401 11.9365 3.76401 11.5459 4.17623L5.88908 10.1474L4.47487 8.65457C4.08434 8.24235 3.45118 8.24235 3.06065 8.65457Z"
      fill="white"
    />
  </svg>
)

CircleCheckmarkIcon.propTypes = {
  color: PropTypes.string,
  'data-testid': PropTypes.string,
}

export default CircleCheckmarkIcon
