import React from 'react'
import PropTypes from 'prop-types'
import SidebarItem from '../SidebarItem/SidebarItem'
import { SidebarSectionStyled, Header } from './style'

// @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
const SidebarSection = ({ name, items }) => (
  <SidebarSectionStyled>
    <Header>{name}</Header>
    <div>
      {/* @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message */}
      {items.map(({ icon = null, label, url }, idx) => (
        <SidebarItem key={idx} icon={icon} label={label} url={url} />
      ))}
    </div>
  </SidebarSectionStyled>
)

SidebarSection.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SidebarSection
