import React from 'react'
import PropTypes from 'prop-types'

import ChannelHeader from './ChannelHeader'

import { ChannelItem, ConnectOptionsWrapper } from './styles'
import { CONNECTION_AVAILABILITY } from '../../types'

const ChannelRadio = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'avatar' implicitly has an 'any' t... Remove this comment to see the full error message
  avatar,
  // @ts-expect-error TS(7031) FIXME: Binding element 'connectionAvailability' implicitl... Remove this comment to see the full error message
  connectionAvailability,
  // @ts-expect-error TS(7031) FIXME: Binding element 'credentials' implicitly has an 'a... Remove this comment to see the full error message
  credentials,
  // @ts-expect-error TS(7031) FIXME: Binding element 'disabled' implicitly has an 'any'... Remove this comment to see the full error message
  disabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSelected' implicitly has an 'an... Remove this comment to see the full error message
  isSelected,
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  name,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectChannel' implicitly has an ... Remove this comment to see the full error message
  selectChannel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'serviceType' implicitly has an 'a... Remove this comment to see the full error message
  serviceType,
}) => {
  return (
    <ChannelItem disabled={disabled}>
      <ChannelHeader
        avatar={avatar}
        credentials={credentials}
        name={name}
        serviceType={serviceType}
      />
      <ConnectOptionsWrapper
        connected={connectionAvailability !== CONNECTION_AVAILABILITY.available}
      >
        {connectionAvailability !== CONNECTION_AVAILABILITY.available && (
          <React.Fragment>
            <span>
              {connectionAvailability ===
              CONNECTION_AVAILABILITY.alreadyConnected
                ? 'Connected'
                : 'Unavailable'}
            </span>
          </React.Fragment>
        )}
        {connectionAvailability === CONNECTION_AVAILABILITY.alreadyConnected ||
        connectionAvailability === CONNECTION_AVAILABILITY.available ||
        !connectionAvailability ? (
          <input
            type="radio"
            data-testid={`${name}-radio`}
            onClick={() => {
              if (!isSelected) {
                selectChannel()
              }
            }}
            name={serviceType}
            checked={isSelected}
          />
        ) : null}
      </ConnectOptionsWrapper>
    </ChannelItem>
  )
}

ChannelRadio.propTypes = {
  avatar: PropTypes.string,
  connectionAvailability: PropTypes.oneOf(Object.keys(CONNECTION_AVAILABILITY)),
  credentials: PropTypes.object,
  disabled: PropTypes.bool,
  hasAdPermissions: PropTypes.bool,
  id: PropTypes.string,
  // @ts-expect-error TS(2339) FIXME: Property 'boolean' does not exist on type 'typeof ... Remove this comment to see the full error message
  isSelected: PropTypes.boolean,
  name: PropTypes.string,
  reconnect: PropTypes.func,
  removeChannel: PropTypes.func,
  selectChannel: PropTypes.func,
  serviceId: PropTypes.string,
  serviceType: PropTypes.string,
}

ChannelRadio.defaultProps = {
  connected: false,
  reconnect: () => {},
}

export default ChannelRadio
