export enum PlanInterval {
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export enum AppStore {
  AppleAppStore = 'Apple App Store',
  GooglePlayStore = 'Google Play Store',
  MobileApp = 'mobile app',
}

export interface AppLink {
  app: string
  link: string | null
}

export interface Plan {
  name: string
  interval: string
  prices: {
    monthlyChannelPrice: number
    baseMonthlyPrice: number
    monthlyFlatFee: number
    channelTierUnit: number
  }
}
