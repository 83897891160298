import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Modal, Button, Text } from '@bufferapp/ui'
import { H3 } from './Text'
import { HC_UTM_PARAMS } from '../../../shared-utils/constants'

const ModalContent = styled.main`
  background: #f5f5f5;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
`

const ModalFooter = styled.footer`
  width: 100%;
  box-sizing: border-box;
  background: white;
  padding: 0 16px;
  margin-top: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    font-weight: 500;
  }

  & > span,
  & > p {
    margin: 0 auto 0 0;
  }
`

const Title = styled.header`
  width: 100%;
  padding: 0 1rem 1rem;
`

const Wrapper = styled.section`
  width: 100%;

  & + * {
    display: none;
  }
`

const Link = styled.a`
  text-decoration: none;
  color: #2c4bff;
`

const InstagramBusinessExplanation = () => (
  <Text type="p">
    Analyze is designed to work with Instagram Business or Creator accounts.
    Learn how to convert your personal profile to a business account{' '}
    <Link
      href={`https://support.buffer.com/article/565-troubleshooting-facebook-and-instagram-connections?${HC_UTM_PARAMS}`}
    >
      here
    </Link>
    .
  </Text>
)

const FacebookExplanation = () => (
  <Text type="p">
    Analyze is designed to work with Facebook Pages. You can find out more about
    these over at Facebook{' '}
    <Link href="https://www.facebook.com/business/pages">here</Link>.
  </Text>
)

const TwitterExplanation = () => (
  <Text type="p">Analyze is designed to work with Twitter User accounts.</Text>
)

const LinkedInExplanation = () => (
  <Text type="p">Analyze is designed to work with LinkedIn Pages.</Text>
)

// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
const getChannelType = (service) => {
  switch (service) {
    case 'instagram':
      return 'Instagram Business or Creator accounts'
    case 'facebook':
      return 'Facebook Pages'
    case 'twitter':
      return 'Twitter User account'
    case 'linkedin':
      return 'LinkedIn Pages'
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'service' implicitly has an 'any' ... Remove this comment to see the full error message
const EmptyChannelListModal = ({ service, logout, close }) => {
  const channelType = getChannelType(service)

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; action: {}; }' is not a... Remove this comment to see the full error message
    <Modal action={{}}>
      <Wrapper>
        <Title>
          {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
          <Text>
            <H3>No {channelType} found</H3>
          </Text>
        </Title>
        <ModalContent>
          {service === 'instagram' && <InstagramBusinessExplanation />}
          {service === 'facebook' && <FacebookExplanation />}
          {service === 'twitter' && <TwitterExplanation />}
          {service === 'linkedin' && <LinkedInExplanation />}
        </ModalContent>
        <ModalFooter>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
          <Button type="text" label="Close" onClick={close} />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
          <Button
            type="primary"
            label={'Connect another account'}
            onClick={logout}
          />
        </ModalFooter>
      </Wrapper>
    </Modal>
  )
}

EmptyChannelListModal.propTypes = {
  close: PropTypes.func,
  logout: PropTypes.func,
  service: PropTypes.string,
}

export default EmptyChannelListModal
