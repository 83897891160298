import React, { Component, type Requireable } from 'react'
import PropTypes from 'prop-types'

import {
  AccordionItemWrapper,
  AccordionItemHeader,
  AccordionItemBody,
} from './styles'

interface AccordionItemProps {
  header: any
  body: any
}

class AccordionItem extends Component<AccordionItemProps> {
  static propTypes: {
    header: any
    body: Requireable<any>
  }

  state = { open: true }

  handleToggle = () => this.setState({ open: !this.state.open })

  render() {
    const { header, body } = this.props

    return (
      <AccordionItemWrapper>
        <AccordionItemHeader onClick={this.handleToggle} open={this.state.open}>
          {header}
        </AccordionItemHeader>
        {body && (
          <AccordionItemBody open={this.state.open}>{body}</AccordionItemBody>
        )}
      </AccordionItemWrapper>
    )
  }
}

AccordionItem.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  body: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default AccordionItem
