import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'
import { red } from '@bufferapp/ui/style/colors'

import * as Styles from './styles'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { Tag } from '../../../../account-components'

dayjs.extend(advancedFormat)

const Header = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'planDetails' implicitly has an 'a... Remove this comment to see the full error message
  planDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'customerIsPausedWithCoupon' impli... Remove this comment to see the full error message
  customerIsPausedWithCoupon,
  // @ts-expect-error TS(7031) FIXME: Binding element 'noTrialStarted' implicitly has an... Remove this comment to see the full error message
  noTrialStarted,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trialExpired' implicitly has an '... Remove this comment to see the full error message
  trialExpired,
}) => {
  const {
    cycle,
    cycleEndDate,
    isActiveTrial,
    planName,
    price,
    subscriptionIsCanceled,
    subscriptionIsPaused,
  } = planDetails

  const isPaidPlan = !isActiveTrial

  let planTitle = ''

  if (trialExpired) {
    planTitle = 'Update Plan'
  } else if (noTrialStarted) {
    planTitle = 'Update Plan'
  } else if (planName && isPaidPlan) {
    planTitle = `${planName} Plan`
  } else if (planName && isActiveTrial) {
    planTitle = `${planName} Trial`
  } else if (isPaidPlan) {
    planTitle = 'Paid Plan'
  } else {
    planTitle = 'Trial'
  }

  // Display renewal date criteria
  const isNotFreePublishPlan = price !== 0
  const isPaused = customerIsPausedWithCoupon || subscriptionIsPaused
  const displayRenewalDate =
    cycleEndDate &&
    !isActiveTrial &&
    !subscriptionIsCanceled &&
    isNotFreePublishPlan &&
    !isPaused

  const renewalDateCopy = `Your ${
    cycle === 'month' ? 'monthly' : 'yearly'
  } subscription will renew on
    ${dayjs.unix(cycleEndDate).format('MMMM Do, YYYY')}.`

  return (
    <Styles.PlanHeader>
      <Text type="h3">{planTitle}</Text>
      {displayRenewalDate ? <Text type="p">{renewalDateCopy}</Text> : ''}
      {subscriptionIsCanceled && (
        <Tag text="Canceled" backgroundColor={red} uppercase />
      )}
    </Styles.PlanHeader>
  )
}

Header.propTypes = {
  noTrialStarted: PropTypes.bool,
  planDetails: PropTypes.shape({
    cycle: PropTypes.string,
    cycleEndDate: PropTypes.number,
    isActiveTrial: PropTypes.bool,
    planName: PropTypes.string,
    price: PropTypes.number,
    subscriptionIsCanceled: PropTypes.bool,
    subscriptionIsPaused: PropTypes.bool,
  }),
  trialExpired: PropTypes.bool,
  customerIsPausedWithCoupon: PropTypes.bool,
}

export default Header
