export const CONNECTION_AVAILABILITY = {
  available: 'available',
  unavailable: 'unavailable',
  alreadyConnected: 'alreadyConnected',
}

export interface ChannelCredentials {
  invalid: boolean
}

export interface ChannelHeaderProps {
  avatar: string
  credentials: ChannelCredentials
  name: string
  serviceType: string
  location?: string
}
