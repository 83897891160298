import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Notification = ({ children, hideInMs }) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (hideInMs) {
      setTimeout(() => setShow(false), hideInMs)
    }
  }, [])

  return show ? <Styles.Notification>{children}</Styles.Notification> : null
}

Notification.propTypes = {
  children: PropTypes.node,
}

export default Notification
