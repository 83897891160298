import React from 'react'

import { Text } from '@bufferapp/ui'

import * as Styles from './styles'

export const ConnectRecommendation = (): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Styles.QuoteWrapper>
        <Text type="p">
          We recommend connecting 3 channels for free to get the most out of
          Buffer!{' '}
        </Text>
        <div className="tail" />
      </Styles.QuoteWrapper>
      <img
        src="https://buffer.com/resources/content/images/size/w300/2020/06/Joel-Gascoigne.png"
        alt="Joel from Buffer avatar"
      />
    </Styles.Wrapper>
  )
}
