import React from 'react'
import PropTypes from 'prop-types'

import AppMenu from '../../web/src/components/AppMenu'

import { Wrapper, ContentWrapper } from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const PageLayout = ({ children, withAppShell, withSidebar }) => {
  if (withAppShell) {
    return (
      <Wrapper>
        {withSidebar && <AppMenu />}
        <ContentWrapper>{children}</ContentWrapper>
      </Wrapper>
    )
  }

  return <div>{children}</div>
}

PageLayout.propTypes = {
  bannerOptions: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  withAppShell: PropTypes.bool,
  withOrgSwitcher: PropTypes.bool,
  withSidebar: PropTypes.bool,
}

export default PageLayout
