import styled from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'

import { Small } from './Text'

export const ServiceType = styled(Small)`
  color: #636363;
  font-weight: 500;
  line-height: 14px;
`

export const AvatarAndName = styled.section`
  display: flex;
  align-items: center;
`

export const Name = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`

export const ChannelItem = styled.li<{
  disabled: boolean
}>`
  width: 100%;
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

export const ConnectOptionsWrapper = styled.div<{
  connected: boolean
}>`
  display: flex;
  justify-content: ${(props) =>
    props.connected ? 'space-between' : 'flex-end'};
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 500;
    color: ${grayDark};
  }

  input {
    cursor: pointer;
  }
`
