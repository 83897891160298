import styled from 'styled-components'
import {
  grayDark,
  grayDarker,
  grayLight,
  gray,
  blue,
  fontFamily,
  fontSize,
  fontWeightMedium,
} from '../../../../styles'

export const QuestionWrapper = styled.div`
  margin-bottom: 28px;

  &:last-of-type {
    margin-bottom: 0;
  }
`
export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  border: none;
  margin: 8px 0 28px 0;
`

export const AnswerLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${grayDarker};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeightMedium};
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${grayLight};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${gray};
  }

  &:has(input:checked) {
    color: ${blue};
    border: 1px solid ${blue};
    background: rgba(213, 219, 255, 0.5);

    svg {
      fill: ${blue};
    }
  }
`

export const AnswerInput = styled.input`
  display: none;
`

export const OtherAnswerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
`
export const OtherTextBox = styled.input`
  margin: 0 !important;
  border-radius: 4px;
  border: 1px solid ${grayLight};
  padding: 12px;
  width: 175px;
  font-size: ${fontSize};

  &::placeholder {
    color: ${grayDark};
    opacity: 1;
  }
`
