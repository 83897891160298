import React from 'react'
import { Text } from '@bufferapp/ui'
import styled from 'styled-components'

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`

const Toggle = styled.button<{
  enabled: boolean
}>`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  background-color: #b8b8b8;
  border-radius: 100px;
  border-style: none;
  margin: 0 0.5rem;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    top: 1px;
    left: ${(props) => (props.enabled ? '50%' : '1px')};
    transition: all 0.3s;
  }
`

export default class ToggleSwitch extends React.Component {
  // @ts-expect-error TS(2339) FIXME: Property 'enabled' does not exist on type 'Readonl... Remove this comment to see the full error message
  state = { enabled: this.props.enabled }
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  toggle(event) {
    event.preventDefault()
    this.setState({
      enabled: !this.state.enabled,
    })
    // @ts-expect-error TS(2339) FIXME: Property 'onClick' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.onClick) {
      // @ts-expect-error TS(2339) FIXME: Property 'onClick' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.props.onClick()
    }
  }

  render() {
    return (
      <Wrapper>
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
        <Text>{this.props.leftLabel}</Text>
        <Toggle enabled={this.state.enabled} onClick={this.toggle.bind(this)} />
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
        <Text>{this.props.rightLabel}</Text>
      </Wrapper>
    )
  }
}
