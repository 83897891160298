import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'

import { useDispatch, useSelector } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button, Text } from '@bufferapp/ui'
import PropTypes from 'prop-types'
import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'

import { actions } from '../../reducer'
import {
  CREATE_SETUP_INTENT,
  DISMISS_OBJECT,
  UPDATE_PAYMENT_METHOD,
} from '../../apollo/mutations'

import { addDays } from 'date-fns'

import { GET_ACCOUNT } from '../../apollo/queries'

import { ButtonWrapper, InputWrapper, ErrorWrapper } from './styles'

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const CreditCardForm = (props) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  // @ts-expect-error TS(2339) FIXME: Property 'creditCard' does not exist on type 'Defa... Remove this comment to see the full error message
  const isSubmitting = useSelector((state) => state.creditCard.isSubmitting)
  const creditCardFailureMessage = useSelector(
    // @ts-expect-error TS(2339) FIXME: Property 'creditCard' does not exist on type 'Defa... Remove this comment to see the full error message
    (state) => state.creditCard.creditCardFailureMessage,
  )

  const currentOrganization = useSelector(
    (state) => state.account.currentOrganization,
  )
  const [setupIntentSecret, setSetupIntentSecret] = useState('')

  // Track credit card modal as an upgrade path when arriving via the payment past due banner
  const cta = new URLSearchParams(window.location.search).get('cta') || ''
  const upgradePathName =
    cta === 'update-payment-method' ? 'pastDue-upgrade' : null
  useEffect(() => {
    if (upgradePathName) {
      BufferTracker.upgradePathViewed({
        organizationId: currentOrganization?.id,
        product: 'account',
        upgradePathName,
        clientName: Client.PublishWeb,
      })
    }
  }, [upgradePathName, currentOrganization?.id])

  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  function handleError(message, userMessage) {
    // we have already used the current setupIntentSecret
    setSetupIntentSecret('')
    console.error(message)
    dispatch(actions.creditCardFailure(userMessage || message))
  }

  const [dismissObject] = useMutation(DISMISS_OBJECT)

  const [createSetupIntent] = useMutation(CREATE_SETUP_INTENT, {
    onCompleted: ({ billingCreateSetupIntent }) => {
      if (billingCreateSetupIntent.success) {
        setSetupIntentSecret(billingCreateSetupIntent.clientSecret)
      } else {
        // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
        handleError(`${billingCreateSetupIntent.userFriendlyMessage}`)
      }
    },
    onError: (error) => {
      handleError(
        `${error.message}`,
        'Oops! There seems to be a problem with your billing information. Please contact us to look into it.',
      )
    },
  })

  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD, {
    onCompleted: ({ billingUpdateCustomerPaymentMethod }) => {
      if (billingUpdateCustomerPaymentMethod.success) {
        dispatch(actions.closeCreditCardFormModal())
        dispatch(
          actions.showCreditCardNotification(
            'Your card was successfully saved.',
          ),
        )
        // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
        dispatch(actions.creditCardSaveSuccess())

        // If updating due to payment past due banner, dismiss the past due banner on success of saving card
        if (upgradePathName) {
          dismissObject({
            variables: {
              organizationId: currentOrganization.id,
              dismissedObject: {
                id: 'payment-past-due-banner',
                expiresAt: addDays(new Date(), 7).toISOString(),
              },
            },
          })
        }

        // Have the appshell refetch account after payment method is updated
        // This will ensure the payment method is available in the PlanSelector if opened
        const { BILLING_EVENT_KEY } = window?.appshell?.eventKeys || {}
        window.dispatchEvent(new Event(BILLING_EVENT_KEY))
      } else {
        handleError(
          `updatePaymentMethod: ${billingUpdateCustomerPaymentMethod.userFriendlyMessage}`,
          `${billingUpdateCustomerPaymentMethod.userFriendlyMessage}`,
        )
      }
    },
    onError: (error) => {
      handleError(
        `updatePaymentMethod: ${error.message}`,
        "We're sorry, but there seems to be an issue with saving your card. Please make sure the details are correct and try again.",
      )
    },
    refetchQueries: [{ query: GET_ACCOUNT }],
  })

  useEffect(() => {
    if (
      currentOrganization &&
      currentOrganization.id &&
      setupIntentSecret === ''
    ) {
      createSetupIntent({
        variables: {
          organizationId: currentOrganization.id,
        },
      })
    }
  }, [currentOrganization, setupIntentSecret])

  const { buttonLabel, closeAction, closeButtonLabel, isLoading } = props

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleSubmit = async (event) => {
    event.preventDefault()

    dispatch(actions.saveCardDetails())

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    stripe
      .confirmCardSetup(setupIntentSecret, {
        payment_method: {
          // @ts-expect-error TS(2322) FIXME: Type 'StripeCardElement | null' is not assignable ... Remove this comment to see the full error message
          card: elements.getElement(CardElement),
        },
      })
      .then((result) => {
        if (result.error) {
          // TODO: Pass in a more user-friendly, error specific message
          dispatch(
            actions.creditCardFailure(
              "We're sorry, but there seems to be an issue with saving your card. Please make sure the details are correct and try again.",
            ),
          )
        } else {
          const attribution = upgradePathName ? { upgradePathName } : null
          updatePaymentMethod({
            variables: {
              organizationId: currentOrganization.id,
              paymentMethodId: result.setupIntent.payment_method,
              attribution,
            },
          })
        }
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <InputWrapper>
        <CardElement />
      </InputWrapper>
      <ErrorWrapper>
        <Text hasError type="help">
          {creditCardFailureMessage}
        </Text>
      </ErrorWrapper>
      <ButtonWrapper>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: any; onClick: any; }'... Remove this comment to see the full error message */}
        <Button type="text" label={closeButtonLabel} onClick={closeAction} />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: (event: any) => Pro... Remove this comment to see the full error message */}
        <Button
          type="primary"
          onClick={handleSubmit}
          label={buttonLabel}
          disabled={isSubmitting || isLoading || !setupIntentSecret}
        />
      </ButtonWrapper>
    </form>
  )
}

CreditCardForm.propTypes = {
  buttonLabel: PropTypes.string,
  closeAction: PropTypes.func,
  closeButtonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
}
