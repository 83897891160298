import React from 'react'
import { SimpleModal } from '@bufferapp/ui'

import { FacebookGroupsForm } from './components/FacebookGroupsForm'

type EditChannelModalProps = {
  onClose: () => void
  id: string
  service: string
  serviceId: string
  type: string
  name: string
}

export function EditChannelModal({
  onClose,
  id,
  service,
  serviceId,
  type,
  name,
}: EditChannelModalProps): JSX.Element {
  const isFacebookGroup = service === 'facebook' && type === 'group'

  return (
    <SimpleModal closeAction={onClose}>
      {isFacebookGroup && (
        <FacebookGroupsForm
          id={id}
          name={name}
          serviceId={serviceId}
          onClose={onClose}
        />
      )}
    </SimpleModal>
  )
}
