import gql from 'graphql-tag'

export const GET_CHANNELS_FOR_ORGANIZATION = gql`
  query ChannelsForOrganization {
    account {
      id
      currentOrganization {
        id
        name
        canEdit
        isOneBufferOrganization
        billingNotifications {
          shouldUpgradeMobileSubscription
          shouldDowngradeMobileSubscription
        }
        limits {
          channels
        }
        privileges {
          canManageChannels
        }
        channels {
          id
          name
          displayName
          service
          serviceId
          type
          avatar
          isDisconnected
          descriptor
          isLocked
          createdAt
          shouldShowPinterestUpgradeBanner
          shouldShowLinkedInUpgradeBanner
          shouldShowTiktokUpgradeBanner
          serverUrl
          locationData {
            location
          }
        }
        billing {
          ... on OBBilling {
            subscription {
              trial {
                isActive
              }
              plan {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const CAN_CONNECT_CHANNELS = gql`
  query CanConnectChannels($channels: [canConnectChannelsInput!]) {
    canConnectChannels(channels: $channels) {
      serviceId
      service
      connectionAvailability
    }
  }
`

export const CHANNEL_AUTHORIZATION_URL = gql`
  query ChannelAuthorizationUrl(
    $channelAuthorizationUrl: ChannelAuthorizationUrlInput!
  ) {
    channelAuthorizationUrl(channelAuthorizationUrl: $channelAuthorizationUrl) {
      ... on ChannelAuthorizationUrlResponse {
        url
      }
      ... on ChannelAuthorizationUrlError {
        userFriendlyMessage
        cause
      }
    }
  }
`

/** ---------------------------------------
 * Refactored Channel Connection queries 2024
 * - TODO: remove the above queries after refactor can be fully roleld out
 *    - CHANNEL_AUTHORIZATION_URL
 *    - CAN_CONNECT_CHANNELS
 *
 */

// This gets all the available network services available for the user to connect to their account.
export const GET_CONNECTABLE_SERVICES = gql`
  query Schedules($organization: ID!, $client: ClientPlatform) {
    connectableServices(
      input: { organization: $organization, client: $client }
    ) {
      ... on ConnectableServicesResponse {
        services {
          service
          types
          title
          subtitle
          contentTypes
          audience
          socialProof
          description
          serviceIconUrl
          similarNetworks
          tag
          serviceStatus {
            label
            details {
              message
              upvoteId
            }
          }
          links {
            text
            url
          }
        }
      }
      ... on ConnectableServicesError {
        userFriendlyMessage
        cause
      }
    }
  }
`
