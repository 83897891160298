import React from 'react'
import PropTypes from 'prop-types'

import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const CardWrapper = ({ children, itemCount }) => (
  <Styles.CardWrapper itemCount={itemCount}>{children}</Styles.CardWrapper>
)

CardWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  itemCount: PropTypes.number,
}

export default CardWrapper
