import styled from 'styled-components'
import { white, grayLight, grayLighter } from '@bufferapp/ui/style/colors'

export const Header = styled.div`
  padding: 0 16px 16px;
  border-bottom: 1px solid ${grayLight};
`

export const Body = styled.div`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background-color: ${grayLighter};
  border-bottom: 1px solid ${grayLight};
`

export const ChannelWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid ${grayLight};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${white};
`
