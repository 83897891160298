import React, { Component } from 'react'
import { Button, Notification } from '@bufferapp/ui'

import DeleteAccountForm from '../DeleteAccountForm'
import UpdateConfirmationForm from '../../../web/src/components/UpdateConfirmationForm'

import { BlockWrapper } from './style'

class DeleteAccount extends Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onConfirmationSubmit = (e) => {
    e.preventDefault()
    const password = e.target[0].value
    // @ts-expect-error TS(2339) FIXME: Property 'verifyAccount' does not exist on type 'R... Remove this comment to see the full error message
    this.props.verifyAccount(password)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'reason' implicitly has an 'any' type.
  handleDeleteSubmit = (reason) => {
    // @ts-expect-error TS(2339) FIXME: Property 'deleteAccount' does not exist on type 'R... Remove this comment to see the full error message
    const { deleteAccount, deleteMyAccount } = this.props
    deleteMyAccount(deleteAccount.password, reason)
  }

  handleShowConfirmModal = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'showConfirmModal' does not exist on type... Remove this comment to see the full error message
    this.props.showConfirmModal()
  }

  handleCloseModals = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'hideAllModals' does not exist on type 'R... Remove this comment to see the full error message
    this.props.hideAllModals()
  }

  render() {
    const {
      showConfirmModal,
      verifyAccountSuccess,
      error,
      // @ts-expect-error TS(2339) FIXME: Property 'deleteAccount' does not exist on type 'R... Remove this comment to see the full error message
    } = this.props.deleteAccount

    return (
      <BlockWrapper>
        {error && (
          <Notification
            text={error}
            // @ts-expect-error TS(2339) FIXME: Property 'clearError' does not exist on type 'Read... Remove this comment to see the full error message
            onClose={this.props.clearError}
          />
        )}
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
        <Button
          type="secondary"
          label="Delete My Buffer Account"
          onClick={this.handleShowConfirmModal}
        />
        {showConfirmModal && (
          <UpdateConfirmationForm
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            modalCopy={
              'To continue with deleting your account, please confirm your password'
            }
            onSubmit={this.onConfirmationSubmit}
            onCancel={this.handleCloseModals}
          />
        )}
        {verifyAccountSuccess && (
          <DeleteAccountForm
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            onSubmit={this.handleDeleteSubmit}
            onCancel={this.handleCloseModals}
          />
        )}
      </BlockWrapper>
    )
  }
}

export default DeleteAccount
