import React from 'react'

import { Modal, Text } from '@bufferapp/ui'
import { Locked } from '@bufferapp/ui/Icon'

import { StripeCreditCardForm } from '../../../credit-card/components/CreditCardForm'

import { H3 } from '../Text'
import { Wrapper, Title } from './Modal'

export default class PaymentDetailsModal extends React.Component {
  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'closeModal' does not exist on type 'Read... Remove this comment to see the full error message
    const { closeModal, validating } = this.props

    return (
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; action: {}; }' is not a... Remove this comment to see the full error message
      <Modal action={{}}>
        <Wrapper>
          <Title>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
            <Text>
              <H3>Enter your payment method</H3>
            </Text>
            <Locked />
          </Title>
          <StripeCreditCardForm
            closeAction={closeModal}
            closeButtonLabel={'Cancel'}
            buttonLabel={validating ? 'One moment...' : 'Save payment details'}
            isSubmitting={validating}
          />
        </Wrapper>
      </Modal>
    )
  }
}
