import qs from 'querystring'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'url-... Remove this comment to see the full error message
import Url from 'url-parse'

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getPath = (state) => state.router.location.pathname

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getRouterSearch = (state) => state.router.location.search.slice(1)

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getParsedQuery = (state) => qs.parse(getRouterSearch(state))

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getRedirect = (state) => {
  const unsafeRedirect = getParsedQuery(state).redirect
  return isAllowedTarget(unsafeRedirect)
    ? `https://${new Url(unsafeRedirect).hostname}`
    : ''
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getUsername = (state) => {
  const unsafeUsername = getParsedQuery(state).username
  // NOTE: The unsafeUsername !== '...' check if a temporal hack/fix
  // until we figure out why that value is being passed on some scenarios.
  const isValid =
    unsafeUsername && unsafeUsername !== '...' && unsafeUsername !== 'undefined'
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string | string[]' is not assign... Remove this comment to see the full error message
  return isValid ? decodeURI(unsafeUsername) : ''
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getAccountId = (state) => state.account.id

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getAccountEmail = (state) => state.account.email

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getAccountCreatedAt = (state) => state.account.createdAt

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const shouldTrackAnalytics = (state) =>
  state.account?.currentOrganization?.id?.length &&
  !state.account.isImpersonation

// Taken from buffer-login it may be useful to extract
// @ts-expect-error TS(7006) FIXME: Parameter 'redirect' implicitly has an 'any' type.
function isAllowedTarget(redirect) {
  try {
    const { hostname } = new Url(redirect)
    return (
      redirect !== undefined &&
      (hostname === null ||
        hostname === 'buffer.com' ||
        hostname.endsWith('.buffer.com'))
    )
  } catch (error) {
    return false
  }
}
