import styled from 'styled-components'
import { grayLight } from '@bufferapp/ui/style/colors'

export const AccordionWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
  padding: 0 10rem;
  flex-direction: column;
  align-items: center;
`

export const AccordianContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`

export const AccordionSectionWrapper = styled.div`
  position: relative;
  margin: 1rem 0;
  border-bottom: thin solid ${grayLight};
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 2rem 0 1rem;
  font-weight: bold;
  cursor: pointer;
`

export const SectionContentWrapper = styled.div`
  overflow: hidden;
`

export const SectionContent = styled.div<{
  isOpen: boolean
}>`
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  margin-top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  padding: 1rem 0;
  line-height: 24px;

  a {
    font-size: 16px;
  }
`
