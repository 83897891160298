export function getEnvironmentFromUrl(baseUrl: string): string {
  if (baseUrl.includes('.local.')) {
    return '.local'
  }
  if (baseUrl.includes('.dev.')) {
    return '.dev'
  }
  return ''
}

export const HC_UTM_PARAMS =
  'utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more'

export const HELLONEXT_URL = 'https://suggestions.buffer.com'
export const HELLONEXT_LOGIN_SERVICE_URL = `https://login${getEnvironmentFromUrl(
  window.location.href,
)}.buffer.com/hellonext?domain=buffer`
