import styled, { css } from 'styled-components'

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  span {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 15px;
  }
`

export const Row = styled.div`
  padding: 1rem;
`

const TableElementSharedStyles = css<{
  size?: number
  align?: string
  border?: boolean
}>`
  width: ${({ size }) => `${size}%`};
  text-align: ${({ align }) => `${align || 'initial'}`};
  padding: 0.5rem 1rem;
  border-bottom: ${({ border }) => `${border ? '1px solid #e8e8e8;' : ''}`};
`

export const TableHeader = styled.th`
  ${TableElementSharedStyles};
`

export const TableColumn = styled.td`
  ${TableElementSharedStyles};
`

export const TableRow = styled.tr<{
  border: boolean
}>`
  border-bottom: ${({ border }) => `${border ? '1px solid #e8e8e8;' : ''}`};
`

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
`
