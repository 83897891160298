/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'

import { Modal, Text } from '@bufferapp/ui'
import { ModalTitle } from '../../../../../../../account-components'
import { Cross } from '@bufferapp/ui/Icon'
import Input from '@bufferapp/ui/Input'

import { actions } from '../../../../../../../channel-list/reducer'
import { useAccount } from '../../../../../context/Account'

import { Body, ModalHeader, IconWrapper, Warning } from './styles'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error TS(7031) FIXME: Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SelectShopModal = ({ onClose, channelToReconnect }): JSX.Element => {
  const dispatch = useDispatch()
  const account = useAccount()
  const [store, setStore] = useState(
    channelToReconnect ? channelToReconnect.serviceId : '',
  )
  const [, setConnecting] = useState(!!channelToReconnect)
  // customers can only connect 1 shopify account
  // @ts-expect-error TS(2339) FIXME: Property 'channels' does not exist on type '{ id: ... Remove this comment to see the full error message
  const hasReachedChannelLimit = account?.currentOrganization?.channels.some(
    // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
    (channel) => channel.service === 'shopify',
  )

  function close(): void {
    BufferTracker.channelConnectionAborted({
      channel: 'shopify',
      clientName: Client.PublishWeb,
      organizationId: `${account.currentOrganization?.id}`,
      product: 'account',
    })
    dispatch(actions.close())
    onClose()
  }

  function connect(): void {
    window.open(`/shopify/connect?store=${store}&source=channels`, '_self')
  }

  function handleConnectStore(): void {
    setConnecting(true)
    connect()
  }

  if (channelToReconnect) {
    connect()
    return <React.Fragment></React.Fragment>
  }

  return (
    // @ts-expect-error TS(2322)
    <Modal
      action={{
        label: 'Connect Store',
        callback: handleConnectStore,
        disabled: hasReachedChannelLimit,
      }}
      secondaryAction={{
        label: 'Cancel',
        callback: close,
      }}
    >
      <ModalHeader>
        <ModalTitle text={'Enter your Shopify store URL'} />
        <IconWrapper>
          <Cross onClick={close} />
        </IconWrapper>
      </ModalHeader>
      <Body>
        <Input
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (ev: any) => void; name: string;... Remove this comment to see the full error message
          onChange={(ev): void => {
            setStore(ev.currentTarget.value)
          }}
          name="Shopify Store URL"
          label="Shopify Store URL"
          placeholder="https://my-store-url.myshopify.com"
          disabled={hasReachedChannelLimit}
        />
        {hasReachedChannelLimit && (
          <Warning>
            <Text type="p">
              Sorry! It is only possible to connect one Shopify store per Buffer
              account. Please remove your existing store before connecting a new
              one.
            </Text>
          </Warning>
        )}
      </Body>
    </Modal>
  )
}

SelectShopModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  channelToReconnect: PropTypes.object,
}

SelectShopModal.defaultProps = {
  onClose: (): void => {
    // do nothing
  },
  channelToReconnect: null,
}

export default SelectShopModal
