const ACCOUNTS_PER_PLAN_NAME = {
  'early-access-10': 10,
  'early-access-25': 25,
  'early-access-50': 50,
  'early-access-100': 100,
  'early-access-200': 200,
  '8-profiles-pro': 8,
  '10-profiles': 10,
  '25-profiles': 25,
  '50-profiles': 50,
  '100-profiles': 100,
  '150-profiles': 150,
  '200-profiles': 200,
}
// @ts-expect-error TS(7006) FIXME: Parameter 'planName' implicitly has an 'any' type.
const getChannelLimitsByPlanName = (planName) => {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const limit = ACCOUNTS_PER_PLAN_NAME[planName]
  if (!limit) {
    const parsedLimit = planName
      .split('-')
      // @ts-expect-error TS(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
      .filter((key) => !!Number.parseInt(key))[0]
    return parsedLimit ? Number.parseInt(parsedLimit) : 0
  }
  return limit
}
export { getChannelLimitsByPlanName }
export default {
  getChannelLimitsByPlanName,
}
