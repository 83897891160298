import styled from 'styled-components'

import { grayLight, grayLighter } from '@bufferapp/ui/style/colors'

export const Body = styled.div`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background-color: ${grayLighter};
  border-top: 1px solid ${grayLight};
  border-bottom: 1px solid ${grayLight};

  p {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  a {
    text-decoration: none;
    font-weight: 700;
  }
`
