import styled from 'styled-components'

export const TableWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
`
