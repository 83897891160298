import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import { fontWeightBold } from '@bufferapp/ui/style/fonts'

export const ChannelRow = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
`

export const TextStyled = styled(Text)`
  font-weight: ${fontWeightBold} !important;
  width: 8.125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1.5rem;
  user-select: none;
`

export const NameStyle = styled.div`
  margin-left: 1.25rem;
  user-select: none;
`
