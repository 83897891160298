import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { AccordionItem } from '../../../../account-components'
import { ArrowRight, ChevronUp } from '@bufferapp/ui/Icon'

import PlanDetails from '../PlanDetails'

const BillingDetailsItem = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasCardOnFile' implicitly has an ... Remove this comment to see the full error message
  hasCardOnFile,
  // @ts-expect-error TS(7031) FIXME: Binding element 'logo' implicitly has an 'any' typ... Remove this comment to see the full error message
  logo,
  // @ts-expect-error TS(7031) FIXME: Binding element 'paidPlanMessage' implicitly has a... Remove this comment to see the full error message
  paidPlanMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planDetails' implicitly has an 'a... Remove this comment to see the full error message
  planDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
  product,
  // @ts-expect-error TS(7031) FIXME: Binding element 'productLink' implicitly has an 'a... Remove this comment to see the full error message
  productLink,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trialDetails' implicitly has an '... Remove this comment to see the full error message
  trialDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'customerIsPausedWithCoupon' impli... Remove this comment to see the full error message
  customerIsPausedWithCoupon,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openCancelPlanModal' implicitly h... Remove this comment to see the full error message
  openCancelPlanModal,
}) => {
  const hasActivePlanOrTrial =
    (trialDetails && trialDetails.subscriptionId) ||
    (planDetails && planDetails.subscriptionId)

  const enabledProducts = useSelector((state) => state.account.enabledProducts)

  const analyzeTrialExpired =
    !hasActivePlanOrTrial && enabledProducts.includes('analyze')
  const noAnalyzeTrialStarted =
    !hasActivePlanOrTrial && !enabledProducts.includes('analyze')

  const hasDetailsToRender = hasActivePlanOrTrial || product === 'analyze'

  const sendToMigrationHub = () => {
    window.location.assign(
      'https://account.buffer.com/new-plans/?cta=billingDetails-migration-cta',
    )
  }

  return (
    <AccordionItem
      header={
        hasDetailsToRender ? (
          <React.Fragment>
            {logo}
            <ChevronUp className="chevron" />
          </React.Fragment>
        ) : (
          <a
            data-testid={`${product}-billng-details-item`}
            href={productLink}
            onClick={(e) => {
              e.preventDefault()
              sendToMigrationHub()
            }}
          >
            {logo}
            <ArrowRight className="icon" />
          </a>
        )
      }
      body={
        hasDetailsToRender ? (
          <React.Fragment>
            {trialDetails && trialDetails.subscriptionId ? (
              <PlanDetails
                hasCardOnFile={hasCardOnFile}
                planDetails={trialDetails}
                product={product}
                updatePlanCallback={sendToMigrationHub}
                openCancelPlanModal={openCancelPlanModal}
              />
            ) : null}

            {planDetails && planDetails.subscriptionId ? (
              <PlanDetails
                hasCardOnFile={hasCardOnFile}
                paidPlanMessage={paidPlanMessage}
                planDetails={planDetails}
                product={product}
                updatePlanCallback={sendToMigrationHub}
                customerIsPausedWithCoupon={customerIsPausedWithCoupon}
                openCancelPlanModal={openCancelPlanModal}
              />
            ) : null}

            {!hasActivePlanOrTrial && product === 'analyze' ? (
              <PlanDetails
                noTrialStarted={noAnalyzeTrialStarted}
                trialExpired={analyzeTrialExpired}
                hasCardOnFile={hasCardOnFile}
                planDetails={trialDetails}
                product={product}
                updatePlanCallback={sendToMigrationHub}
              />
            ) : null}
          </React.Fragment>
        ) : null
      }
    />
  )
}

BillingDetailsItem.propTypes = {
  hasCardOnFile: PropTypes.bool,
  logo: PropTypes.node,
  paidPlanMessage: PropTypes.string,
  planDetails: PropTypes.object,
  product: PropTypes.string,
  productLink: PropTypes.string,
  trialDetails: PropTypes.object,
  customerIsPausedWithCoupon: PropTypes.bool,
  openCancelPlanModal: PropTypes.func,
}

export default BillingDetailsItem
