import styled from 'styled-components'

export const CardTitle = styled.header<{
  border?: boolean
  padding?: string
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: ${(props) => (props.border ? '1px solid #e8e8e8' : 'none')};
  padding: ${(props) => (props.padding ? props.padding : 0)};
`
