import styled from 'styled-components'

export const Wrapper = styled.label<{
  disabled: boolean
}>`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #f5f5f5;
  width: 100%;
  opacity: ${(props) => (props.disabled ? '50%' : '100%')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  input {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

export const Avatar = styled.div<{
  url: string
}>`
  height: 32px;
  width: 37px;
  margin-left: 10px;
  border-radius: 100%;
  background-image: ${(props) => `url(${props.url})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  width: 100%;
`

export const NameAndLocation = styled.div`
  display: flex;
  flex-direction: column;
`

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
