import styled from 'styled-components'

const BlockWrapper = styled.div<{
  margin?: string
}>`
  width: 512px;
  margin-bottom: ${(props): string => props.margin || '24px'};

  div[type='note'] {
    svg {
      &:first-of-type {
        margin-right: 0.5rem;
      }
    }
  }
`

export default BlockWrapper
