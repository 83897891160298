import styled, { css } from 'styled-components'

import {
  fontFamily,
  fontSize,
  lineHeight,
  fontWeightMedium,
} from '../../style/fonts'
import { blue, boxShadow, grayDefault, white } from '../../style/colors'
import { borderRadius } from '../../style/borders'

/* styles common to all buttons */
export const ButtonBase = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${fontSize};
  line-height: ${lineHeight};
  font-weight: ${fontWeightMedium};
  border-radius: ${borderRadius};
  cursor: pointer;
  user-select: none;
  border: 0;
  -webkit-appearance: none;
  position: relative;
  transition-property: background-color, border-color, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 0 0 auto;
  font-family: ${fontFamily};
  background-color: ${white};
  color: ${grayDefault};
  padding-top: 0;
  padding-bottom: 0;
  :hover {
    transition-property: background-color, border-color, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }
  :active {
    top: 1px;
  }
  :focus {
    box-shadow: 0 0 0 3px ${boxShadow};
    outline: 0;
  }
`

/* size variants */
export const small = css`
  padding-left: 8px;
  padding-right: 8px;
  height: 32px;
`

export const SidebarItemStyled = styled.a`
  ${ButtonBase};
  ${small};
  text-decoration: none;
  margin-bottom: 8px;
  background-color: ${blue};
  color: ${white};
  cursor: default;
  :active {
    top: 0;
  }
  :focus {
    box-shadow: none;
    outline: 0;
  }
`

export const Label = styled.span<{ hasIcon: boolean }>`
  margin-left: ${(props) => (props.hasIcon ? '5px' : '0px')};
`
