import React from 'react'

import { Link, Text } from '@bufferapp/ui'
import { HC_UTM_PARAMS } from '../../../../../../shared-utils/constants'
import { useAccount } from '../../../../context/Account'
import { ConnectRecommendation } from './ConnectRecommendation'

import { isFreeUser } from '../../../../../../shared-utils'

import * as Styles from './styles'

export const ChannelConnectTitle = (): JSX.Element => {
  const account = useAccount()
  const isFree = isFreeUser(account)
  const connectedChannelCount = account?.currentOrganization?.channels?.length

  const showChannelStorefrontView = isFree

  return (
    <Styles.TitleContainerStyled wide={true}>
      <div>
        <Text type="h1">Connect your channels</Text>
        {showChannelStorefrontView ? (
          <Text type={'p'} color={'grayDark'}>
            {`${connectedChannelCount}`}/3 channels connected
          </Text>
        ) : (
          <Text type={'p'} color={'grayDark'}>
            Looking for step-by-step instructions? Visit our Help Center to read
            our{' '}
            <Link
              newTab
              fontWeight={700}
              href={`https://support.buffer.com/article/564-connecting-your-channels-to-buffer?${HC_UTM_PARAMS}`}
            >
              Getting Started
            </Link>{' '}
            guides and learn about supported channel types.
          </Text>
        )}
      </div>
      {showChannelStorefrontView && <ConnectRecommendation />}
    </Styles.TitleContainerStyled>
  )
}
