import styled from 'styled-components'

export const LogoWrapper = styled.span<{
  color: string
  resizeSvg?: boolean
}>`
  margin-top: 0.25rem;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  ${(props) =>
    props.resizeSvg &&
    `
    & > svg {
      height: 40px;
      width: 40px;
    }
  `}
`

export const LogoWrapperMedium = styled.span<{
  color: string
  resizeSvg?: boolean
}>`
  margin-top: 0.25rem;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  ${(props) =>
    props.resizeSvg &&
    `
  & > svg {
    height: 32px;
    width: 32px;
  }
`}
`
