import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Avatar, Text, Button } from '@bufferapp/ui'
import { grayDark } from '@bufferapp/ui/style/colors'

import { Refresh, Cross } from '@bufferapp/ui/Icon'
import { Small, Bold, Error } from './Text'
import { CONNECTION_AVAILABILITY } from '../../types'

const ServiceType = styled(Small)`
  color: #636363;
  font-weight: 500;
  line-height: 14px;
  > span {
    display: block;
  }
`

const AvatarAndName = styled.section`
  display: flex;
  align-items: center;
  width: 245px;
`

const Name = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`

const AdPermisions = styled.div`
  display: flex;
  width: 95px;
`

const AllowButton = styled(Button)`
  margin-top: 0;
  margin-left: 10px;
`

const ButtonWrapper = styled.div`
  width: 70px;
  margin-left: auto;
`

const RemoveButtonWrapper = styled.div`
  width: auto;
  margin-left: 10px;
`

const ChannelItem = styled.li<{
  disabled: boolean
  toggleChannel: boolean
}>`
  width: 100%;
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.toggleChannel ? 'space-between' : '')};

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`

const ConnectOptionsWrapper = styled.div<{ connected: boolean }>`
  display: flex;
  width: 150px;
  justify-content: ${(props) =>
    props.connected ? 'space-between' : 'flex-end'};
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 500;
    color: ${grayDark};
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
const serviceToServiceLabel = (service) => {
  let serviceLabel
  switch (service) {
    case 'instagram':
      serviceLabel = 'Instagram Business or Creator'
      break
    case 'linkedin':
      serviceLabel = 'LinkedIn'
      break
    default:
      serviceLabel = service.charAt(0).toUpperCase() + service.slice(1)
      break
  }

  return serviceLabel
}

export class Channel extends React.Component {
  checkbox: any
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.checkbox = React.createRef()
    // @ts-expect-error TS(2322) FIXME: Type '((e: any) => void) | null' is not assignable... Remove this comment to see the full error message
    this.selectChannel = props.toggleChannel
      ? this.selectChannel.bind(this)
      : null
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  selectChannel(e) {
    // @ts-expect-error TS(2339) FIXME: Property 'toggleChannel' does not exist on type 'R... Remove this comment to see the full error message
    this.props.toggleChannel(this.props.id)
    if (e.target !== this.checkbox.current) {
      this.checkbox.current.checked = !this.checkbox.current.checked
    }
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'avatar' does not exist on type 'Readonly... Remove this comment to see the full error message
      avatar,
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      name,
      // @ts-expect-error TS(2339) FIXME: Property 'serviceType' does not exist on type 'Rea... Remove this comment to see the full error message
      serviceType,
      // @ts-expect-error TS(2339) FIXME: Property 'serviceId' does not exist on type 'Reado... Remove this comment to see the full error message
      serviceId,
      // @ts-expect-error TS(2339) FIXME: Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error TS(2339) FIXME: Property 'toggleChannel' does not exist on type 'R... Remove this comment to see the full error message
      toggleChannel,
      // @ts-expect-error TS(2339) FIXME: Property 'credentials' does not exist on type 'Rea... Remove this comment to see the full error message
      credentials,
      // @ts-expect-error TS(2339) FIXME: Property 'hasAdPermissions' does not exist on type... Remove this comment to see the full error message
      hasAdPermissions,
      // @ts-expect-error TS(2339) FIXME: Property 'connectionAvailability' does not exist o... Remove this comment to see the full error message
      connectionAvailability,
      // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist o... Remove this comment to see the full error message
      location,
    } = this.props
    const serviceLabel = serviceToServiceLabel(serviceType)
    const hasPaidMetrics = hasAdPermissions !== undefined
    return (
      <ChannelItem
        disabled={disabled}
        onClick={this.selectChannel}
        toggleChannel={toggleChannel}
      >
        <AvatarAndName>
          <Avatar src={avatar} alt={name} network={serviceType} />
          <Name>
            <Bold>
              {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
              <Text>
                {name}
                {credentials && credentials.invalid && (
                  <Error> (Needs refreshing)</Error>
                )}
              </Text>
            </Bold>
            <ServiceType>
              <Text type="span">{serviceLabel}</Text>
              {location && <Text type="span">{location}</Text>}
            </ServiceType>
          </Name>
        </AvatarAndName>
        {toggleChannel && (
          <ConnectOptionsWrapper
            connected={
              connectionAvailability !== CONNECTION_AVAILABILITY.available
            }
          >
            {connectionAvailability !== CONNECTION_AVAILABILITY.available && (
              <React.Fragment>
                <span>
                  {connectionAvailability ===
                  CONNECTION_AVAILABILITY.alreadyConnected
                    ? 'Connected'
                    : 'Unavailable'}
                </span>
              </React.Fragment>
            )}

            {connectionAvailability === CONNECTION_AVAILABILITY.available ||
            !connectionAvailability ? (
              <input ref={this.checkbox} type="checkbox" name={name} />
            ) : null}
          </ConnectOptionsWrapper>
        )}
        {!toggleChannel && (
          <React.Fragment>
            <AdPermisions>
              <Text type="label" color="grayDark">
                {hasPaidMetrics ? (hasAdPermissions ? 'Yes' : 'No') : '-'}
              </Text>
              {hasPaidMetrics && !hasAdPermissions && (
                <AllowButton
                  type="text"
                  size="mini"
                  onClick={() =>
                    // @ts-expect-error TS(2339) FIXME: Property 'reconnect' does not exist on type 'Reado... Remove this comment to see the full error message
                    this.props.reconnect(serviceId, serviceType, true)
                  }
                  label={
                    <Text type="label" color="blue">
                      Allow
                    </Text>
                  }
                />
              )}
            </AdPermisions>
            <ButtonWrapper>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => any; icon: El... Remove this comment to see the full error message */}
              <Button
                type="text"
                onClick={() =>
                  // @ts-expect-error TS(2339) FIXME: Property 'reconnect' does not exist on type 'Reado... Remove this comment to see the full error message
                  this.props.reconnect(serviceId, serviceType, hasAdPermissions)
                }
                icon={<Refresh />}
              />
            </ButtonWrapper>
            <RemoveButtonWrapper>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => any; icon: El... Remove this comment to see the full error message */}
              <Button
                type="text"
                // @ts-expect-error TS(2339) FIXME: Property 'removeChannel' does not exist on type 'R... Remove this comment to see the full error message
                onClick={() => this.props.removeChannel(this.props._id)}
                icon={<Cross />}
              />
            </RemoveButtonWrapper>
          </React.Fragment>
        )}
      </ChannelItem>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Channel.propTypes = {
  avatar: PropTypes.string,
  connectionAvailability: PropTypes.oneOf(Object.keys(CONNECTION_AVAILABILITY)),
  credentials: PropTypes.object,
  disabled: PropTypes.bool,
  hasAdPermissions: PropTypes.bool,
  name: PropTypes.string,
  reconnect: PropTypes.func,
  removeChannel: PropTypes.func,
  serviceId: PropTypes.string,
  serviceType: PropTypes.string,
  toggleChannel: PropTypes.func,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Channel.defaultProps = {
  connected: false,
  reconnect: () => {},
}
