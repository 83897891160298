/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'

import PropTypes from 'prop-types'

import SelectShop from './SelectShop'

// @ts-expect-error TS(7031) FIXME: Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
const ShopifyFlow = ({ onClose, channelToReconnect }) => {
  // Redirect users to Shopify to connnect (complete install) Buffer app with
  // their Shopify shop.
  if (!channelToReconnect) {
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
    window.location = 'https://apps.shopify.com/buffer'
    return null
  }

  return (
    <SelectShop onClose={onClose} channelToReconnect={channelToReconnect} />
  )
}

ShopifyFlow.propTypes = {
  onClose: PropTypes.func.isRequired,
  channelToReconnect: PropTypes.object,
}

ShopifyFlow.defaultProps = {
  onClose: () => {},
  channelToReconnect: null,
}

export default ShopifyFlow
