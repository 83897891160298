import styled from 'styled-components'
import { fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { grayLight, grayLighter } from '@bufferapp/ui/style/colors'

export const Title = styled.h3`
  font-size: 18px;
  font-weight: ${fontWeightMedium};
  padding-bottom: 16px;
`

export const Body = styled.div`
  padding: 16px;
  width: 100%;
  background-color: ${grayLighter};
  border-top: ${`1px solid ${grayLight}`};
  border-bottom: ${`1px solid ${grayLight}`};
`
