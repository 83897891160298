import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'numberItems' implicitly has an 'a... Remove this comment to see the full error message
const animation = ({ numberItems }) =>
  css`
    ${pulse} ${numberItems}s ease infinite forwards
  `

// @ts-expect-error TS(7031) FIXME: Binding element 'numberItems' implicitly has an 'a... Remove this comment to see the full error message
const createCSS = ({ numberItems }) => {
  let styles = ''
  for (let i = 0; i < numberItems + 1; i += 1) {
    styles += `
     > :nth-child(${i}){
        animation-delay: ${0.5 * i}s  ; 
     }
   `
  }

  return css`
    ${styles}
  `
}

const StyledList = styled.span`
  > * {
    animation: ${animation};
    opacity: 0;
  }
  ${createCSS}
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const AnimatedList = ({ children }) => {
  const numberItems = React.Children.count(children)
  return <StyledList numberItems={numberItems}>{children}</StyledList>
}

AnimatedList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AnimatedList
