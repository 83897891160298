import React, { type ChangeEvent, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Input, Text, Tooltip } from '@bufferapp/ui'
import { UPDATE_CUSTOM_CHANNEL } from '../../../../../../apollo/mutations/channels'
import { GET_ACCOUNT } from '../../../../../../apollo/queries/account'

import * as Styles from '../../styles'

export function FacebookGroupsForm({
  onClose,
  id,
  name: channelName,
  serviceId,
}: {
  onClose: () => void
  id: string
  name: string
  serviceId: string
}): JSX.Element {
  const [name, setName] = useState(channelName)
  const [nameError, setNameError] = useState('')

  const [updateCustomChannel, { loading }] = useMutation(
    UPDATE_CUSTOM_CHANNEL,
    {
      refetchQueries: [{ query: GET_ACCOUNT }],
      onCompleted: () => {
        onClose()
      },
    },
  )

  return (
    <Styles.Wrapper>
      <Styles.Header>
        <Text type="h3">Edit Facebook Group</Text>
      </Styles.Header>
      <Styles.Body>
        <Styles.FormGroup>
          <Text htmlFor="name" type="label">
            Name
            <Input
              // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (ev: any) => void; disabled: boo... Remove this comment to see the full error message
              type="input"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setNameError('')
                setName(e.target.value)
              }}
              id="name"
              name="name"
              placeholder="Enter your Facebook Group Name"
              hasError={nameError}
              help={nameError}
            />
          </Text>
        </Styles.FormGroup>
        <Styles.FormGroup>
          <Text htmlFor="serviceId" type="label">
            Group URL
            {/* @ts-expect-error TS(2322) */}
            <Tooltip label="Group URLs can't be changed. To add a new Group URL, connect a new Facebook Group.">
              <Input
                // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (ev: any) => void; disabled: boo... Remove this comment to see the full error message
                type="input"
                value={`https://facebook.com/goups/${serviceId}`}
                disabled
                id="serviceId"
                name="serviceId"
              />
            </Tooltip>
          </Text>
        </Styles.FormGroup>
      </Styles.Body>
      <Styles.Footer>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; size: string... Remove this comment to see the full error message */}
        <Button type="text" onClick={onClose} label="Cancel" />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; size: string... Remove this comment to see the full error message */}
        <Button
          type="primary"
          onClick={(): void => {
            // Validation that name is required
            if (name.trim() === '') {
              return setNameError('Facebook Group name is required.')
            }

            // Max length validation for group name
            if (name.length > 75) {
              setNameError('Facebook Group name must be under 75 characters')
              return
            }

            updateCustomChannel({
              variables: {
                input: {
                  customChannelMetadata: {
                    facebookGroupMetadata: {
                      avatar: '',
                      name,
                    },
                  },
                  channelId: id,
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
              },
            })
          }}
          label={loading ? 'Saving' : 'Save'}
          disabled={loading}
        />
      </Styles.Footer>
    </Styles.Wrapper>
  )
}
