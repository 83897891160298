import React from 'react'
import PropTypes from 'prop-types'

import * as Styles from './styles'
import { Text } from '@bufferapp/ui'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const CardTitle = ({ children, border, padding }) => (
  <Styles.CardTitle border={border} padding={padding}>
    <Text type="h3">{children}</Text>
  </Styles.CardTitle>
)

CardTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  border: PropTypes.bool,
  padding: PropTypes.string,
}

CardTitle.defaultProps = {
  border: false,
  padding: '16px',
}

export default CardTitle
