import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Button, Text } from '@bufferapp/ui'
import { Form } from '../../../account-components'
import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'
import { isFreeUser, isNewBufferUser } from '../../../shared-utils'
import { HC_UTM_PARAMS } from '../../../shared-utils/constants'

import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import { red } from '@bufferapp/ui/style/colors'

import { Channel } from './Channel'
import ChannelRadio from './ChannelRadio'

import { setChannelCookies } from '../../../web/src/pages/Channels/utils/cookies'

import { H3 } from './Text'
import { CONNECTION_AVAILABILITY } from '../../types'

const ModalContent = styled.main`
  background: #f5f5f5;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 255px;

  form {
    max-height: 398px;
  }
`

const FooterNotice = styled.div`
  padding: 8px 16px 0 16px;
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    margin: -2px 8px 0 0;
    fill: ${red};
  }
`

const ModalFooter = styled.footer`
  width: 100%;
  box-sizing: border-box;
  background: white;
  padding: 0 16px;
  margin-top: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    font-weight: 500;
  }

  & > span,
  & > p {
    margin: 0 auto 0 0;
  }
`

const Title = styled.header`
  width: 100%;
  padding: 0 1rem 1rem;
`

const Wrapper = styled.section`
  width: 100%;

  & + * {
    display: none;
  }
`

const Link = styled.a`
  text-decoration: none;
`

const ModalWrapper = styled.section`
  padding-top: 1rem;
  margin-top: -1rem;
  width: 100%;
`

function ChannelListModal({
  // @ts-expect-error TS(7031) FIXME: Binding element 'account' implicitly has an 'any' ... Remove this comment to see the full error message
  account,
  // @ts-expect-error TS(7031) FIXME: Binding element 'addChannels' implicitly has an 'a... Remove this comment to see the full error message
  addChannels,
  // @ts-expect-error TS(7031) FIXME: Binding element 'allowedAmount' implicitly has an ... Remove this comment to see the full error message
  allowedAmount,
  // @ts-expect-error TS(7031) FIXME: Binding element 'close' implicitly has an 'any' ty... Remove this comment to see the full error message
  close,
  // @ts-expect-error TS(7031) FIXME: Binding element 'limit' implicitly has an 'any' ty... Remove this comment to see the full error message
  limit,
  // @ts-expect-error TS(7031) FIXME: Binding element 'pages' implicitly has an 'any' ty... Remove this comment to see the full error message
  pages,
  // @ts-expect-error TS(7031) FIXME: Binding element 'service' implicitly has an 'any' ... Remove this comment to see the full error message
  service,
  // @ts-expect-error TS(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
  type,
}) {
  const [channels, setChannels] = useState(new Set())

  const shouldShowNewChannelConnectionModal =
    isNewBufferUser(account) && !isFreeUser(account)

  function closeConnection() {
    BufferTracker.channelConnectionAborted({
      channel: service.toLowerCase(),
      clientName: Client.PublishWeb,
      organizationId: account?.currentOrganization?.id,
      product: 'account',
    })

    close()
  }

  function handleAddToBufferOnClick() {
    addChannels(channels, service)

    const isConnectingFacebookGroup = service === 'facebook' && type === 'group'

    if (isConnectingFacebookGroup) {
      const facebookGroupId = channels.values().next().value
      setChannelCookies(type, facebookGroupId)
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function store(id) {
    const allowOneChannelOnly = service === 'facebook' && type === 'group'

    const newChannelsList = channels

    if (allowOneChannelOnly) {
      if (newChannelsList.size > 0) {
        newChannelsList.clear()
      }
      newChannelsList.add(id)
    } else {
      channels.has(id) ? newChannelsList.delete(id) : newChannelsList.add(id)
    }

    setChannels(new Set(newChannelsList))
  }

  function reachedConnectionLimit() {
    return channels.size > allowedAmount
  }

  function checkShouldShowLimit() {
    if (shouldShowNewChannelConnectionModal) {
      return false
    }

    reachedConnectionLimit()
  }

  const FACEBOOK_PAGES_URL = `https://support.buffer.com/article/569-connecting-your-facebook-page-to-buffer?${HC_UTM_PARAMS}`
  const faqUrl = FACEBOOK_PAGES_URL
  const BUFFER_HELP_URL = `https://support.buffer.com/article/571-unavailable-channels-are-likely-connected-to-another-buffer-organization?${HC_UTM_PARAMS}`
  const unavailableUrl = BUFFER_HELP_URL
  const isFacebookPagesConnectFlow = service === 'facebook' && type === 'page'

  const multiProductChannelLimit = 25
  const showRemainingChannelsLimit = allowedAmount <= multiProductChannelLimit

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; action: {}; }' is not a... Remove this comment to see the full error message
    <Modal action={{}}>
      <Wrapper>
        <ModalWrapper>
          <Title>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
            <Text>
              <H3>Which channels would you like to add?</H3>
            </Text>
            {!shouldShowNewChannelConnectionModal &&
              (reachedConnectionLimit() ? (
                // @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message
                <Text>
                  To add more than {limit} channels, please{' '}
                  <Link href="/billing">Upgrade Plan</Link>.
                </Text>
              ) : limit && showRemainingChannelsLimit ? (
                // @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message
                <Text>
                  You can add up to {allowedAmount} more social accounts.
                </Text>
              ) : null)}
          </Title>
          <ModalContent>
            {/* @ts-expect-error TS(2741) FIXME: Property 'onSubmit' is missing in type '{ children... Remove this comment to see the full error message */}
            <Form>
              <ol>
                {/* @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type. */}
                {pages.map((channel, i) => {
                  const connectingFacebookGroup =
                    service === 'facebook' && type === 'group'

                  if (connectingFacebookGroup) {
                    return (
                      <ChannelRadio
                        key={i}
                        isSelected={channels.has(channel.id)}
                        id={channel.id}
                        avatar={channel.avatar}
                        serviceType={service}
                        name={channel.username}
                        connectionAvailability={channel.connectionAvailability}
                        selectChannel={() => store(channel.id)}
                        disabled={
                          channel.connectionAvailability !==
                          CONNECTION_AVAILABILITY.available
                        }
                      />
                    )
                  } else {
                    return (
                      <Channel
                        key={i}
                        // @ts-expect-error TS(2322) FIXME: Type '{ key: any; id: any; avatar: any; serviceTyp... Remove this comment to see the full error message
                        id={channel.id}
                        avatar={channel.avatar}
                        serviceType={service}
                        name={channel.username}
                        location={channel.locationData?.location}
                        connectionAvailability={channel.connectionAvailability}
                        toggleChannel={store}
                        disabled={
                          channel.connectionAvailability !==
                          CONNECTION_AVAILABILITY.available
                        }
                      />
                    )
                  }
                })}
              </ol>
            </Form>
          </ModalContent>
        </ModalWrapper>
        {checkShouldShowLimit() && (
          <FooterNotice>
            <WarningIcon size="medium" />
            <Text color="red" type="p">
              A maximum of {allowedAmount} more channels can be added with your
              current plan.
            </Text>
          </FooterNotice>
        )}
        <ModalFooter>
          {!checkShouldShowLimit() && isFacebookPagesConnectFlow && (
            <Text type="p">
              Don&apos;t see one of your channels? <br />
              <Link
                href={faqUrl}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Learn why
              </Link>
            </Text>
          )}
          {!checkShouldShowLimit() && !isFacebookPagesConnectFlow && (
            <Text type="p">
              Seeing unavailable channels? <br />
              <Link
                href={unavailableUrl}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Learn why
              </Link>
            </Text>
          )}
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
          <Button type="text" label="Cancel" onClick={closeConnection} />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; disabled: boolean | undefine... Remove this comment to see the full error message */}
          <Button
            type="primary"
            disabled={channels.size === 0 || checkShouldShowLimit()}
            label={'Add to Buffer'}
            onClick={handleAddToBufferOnClick}
          />
        </ModalFooter>
      </Wrapper>
    </Modal>
  )
}

ChannelListModal.propTypes = {
  account: PropTypes.object,
  addChannels: PropTypes.func,
  allowedAmount: PropTypes.number,
  close: PropTypes.func,
  limit: PropTypes.number,
  pages: PropTypes.array,
  service: PropTypes.string,
  type: PropTypes.string,
}

export default ChannelListModal
