import styled from 'styled-components'
import {
  white,
  gray,
  grayLight,
  grayLighter,
  grayDark,
  redDark,
} from '@bufferapp/ui/style/colors'

export const Header = styled.div`
  padding: 0 16px 16px;
  border-bottom: 1px solid ${grayLight};
  width: 100%;
`

export const Body = styled.div`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background-color: ${grayLighter};
  border-bottom: 1px solid ${grayLight};

  p {
    margin-bottom: 16px;
  }
`

export const ChannelWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid ${grayLight};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${white};
`

export const ChannelListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid ${gray};
  border-right: 1px solid ${gray};
  border-left: 1px solid ${gray};
  border-bottom: 1px solid ${grayLight};
  border-radius: 4px 4px 0 0;
  background: ${white};
  color: ${grayDark};

  p {
    margin: 0;
  }
`

export const ChannelListWrapper = styled.div`
  border-right: 1px solid ${gray};
  border-bottom: 1px solid ${gray};
  border-left: 1px solid ${gray};
  border-radius: 0 0 4px 4px;
  background: ${white};
  max-height: 250px;
  overflow: auto;

  p {
    margin: 0;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 16px 16px;
`

export const LabelRow = styled.label`
  display: flex;
  width: 100%;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${grayLight};
  }
`

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  font-weight: 500;

  svg {
    margin-right: 8px;
  }

  span {
    color: ${redDark};
  }
`
