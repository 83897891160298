// @ts-expect-error TS(7006) FIXME: Parameter 'planPrice' implicitly has an 'any' type... Remove this comment to see the full error message
export const calculateDiscountedPlanPrice = (planPrice, discount) => {
  if (!discount) {
    return planPrice
  }

  if (discount?.type === 'percent') {
    return planPrice - planPrice * percentToDecimal(discount?.value)
  } else {
    return planPrice - discount?.value
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'percentValue' implicitly has an 'any' t... Remove this comment to see the full error message
const percentToDecimal = (percentValue) => {
  return percentValue / 100
}

// @ts-expect-error TS(7006) FIXME: Parameter 'currentOrganization' implicitly has an ... Remove this comment to see the full error message
export const getCustomerBillingBreakdown = (currentOrganization, discount) => {
  const stripeAccounts = currentOrganization?.billingDetails?.allStripeAccounts

  // @ts-expect-error TS(7034) FIXME: Variable 'billingBreakdown' implicitly has type 'a... Remove this comment to see the full error message
  const billingBreakdown = []
  let products

  if (stripeAccounts?.length > 0) {
    products = stripeAccounts[0]?.products
  } else {
    // @ts-expect-error TS(7005) FIXME: Variable 'billingBreakdown' implicitly has an 'any... Remove this comment to see the full error message
    return billingBreakdown
  }

  if (products) {
    for (const key of Object.keys(products)) {
      const product = products[key]

      if (product?.isActiveTrial === false) {
        billingBreakdown.push({
          planName: product?.planName,
          price: calculateDiscountedPlanPrice(product?.price, discount),
          cycle: product?.cycle,
        })
      }
    }
  }

  return billingBreakdown
}

export const getTotalLegacyPriceByInterval = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'currentOrganization' implicitly has an ... Remove this comment to see the full error message
  currentOrganization,
  // @ts-expect-error TS(7006) FIXME: Parameter 'interval' implicitly has an 'any' type.
  interval,
) => {
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  const billingBreakdown = getCustomerBillingBreakdown(currentOrganization)

  if (billingBreakdown?.length < 1) {
    return null
  }

  let priceSum = 0
  for (const plan of billingBreakdown) {
    if (plan.cycle === interval) {
      priceSum += plan.price
    } else {
      if (interval === 'month') {
        priceSum += plan.price / 12
      } else if (interval === 'year') {
        priceSum += plan.price * 12
      }
    }
  }

  return priceSum
}
