import React from 'react'
import styled from 'styled-components'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import { Text } from '@bufferapp/ui'

const WarningWrapper = styled.span`
  color: #9d2637;
  font-weight: 500;
  font-size: 0.875rem;
  display: flex;
  margin-bottom: 1rem;

  > :nth-child(1) {
    margin-right: 0.5rem;
  }
`
export const OverQuota = () => (
  // @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message
  <Text>
    <WarningWrapper>
      <WarningIcon />
      Due to downgrading your plan, you are now over quota
    </WarningWrapper>
  </Text>
)
