import styled from 'styled-components'
import {
  white,
  grayLight,
  grayLighter,
  grayDarker,
} from '@bufferapp/ui/style/colors'

export const AccordionItemWrapper = styled.div<{
  open?: boolean
}>`
  width: 100%;
  box-sizing: border-box;

  &:not(:last-of-type) {
    border-bottom: ${(props) => !props.open && `1px solid ${grayLight}`};
  }
`

export const AccordionItemHeader = styled.div<{
  open: boolean
}>`
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  border-radius: 4px;

  .chevron {
    transform: ${(props) => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: transform 0.4s ease;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;

    .icon {
      fill: ${grayDarker};
    }
  }
`

export const AccordionItemBody = styled.div<{
  open: boolean
}>`
  padding: ${(props) => (props.open ? '1rem' : 0)};
  display: ${(props) => (props.open ? 'block' : 'none')};
  width: 100%;
  background-color: ${grayLighter};
  box-sizing: border-box;
  border-radius: 4px;

  img {
    max-width: 100%;
  }
`
