import gql from 'graphql-tag'

export const INVOICES_BY_ORGANIZATION = gql`
  query InvoicesByOrganization(
    $organizationId: String!
    $first: Int
    $after: String
  ) {
    invoicesByOrganization(
      organizationId: $organizationId
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          amount
          status
          downloadUrl
          date
          currency
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`

export const MIGRATION_SUMMARY = gql`
  query MigrationSummary(
    $organizationId: String!
    $plan: OBPlanId
    $interval: BillingInterval
    $quantity: Int
  ) {
    planMigrationSummaryViaHub(
      organizationId: $organizationId
      plan: $plan
      interval: $interval
      quantity: $quantity
    ) {
      chargeAmount
      creditAmount
      intervalAmount
    }
  }
`
