import React from 'react'
import PropTypes from 'prop-types'

import { Button, Text } from '@bufferapp/ui'

import { Container } from './styles'
import type { TaxMessagingProps } from './types'

// We plan to remove this messaging to customer on the 18th of July 2023
const ExistingCustomerWithAddress = (): JSX.Element | null => {
  return (
    <li>
      <Text type="p">
        Buffer will be collecting taxes in your region. Applicable tax will be
        added to the price of your subscription.{' '}
        <a
          href="https://support.buffer.com/article/544-why-was-i-charged-that-your-bill-taxes-and-vat-explained?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
          aria-label="buffer-will-collect-tax-learn-more"
        >
          Learn more
        </a>
      </Text>
    </li>
  )
}

const ExistingCustomerWithoutAddress = ({
  onUpdateAddressClick,
}: {
  onUpdateAddressClick: () => void
}): JSX.Element => {
  return (
    <li>
      <Text type="p">
        We noticed that your billing address is missing. To make sure we comply
        with tax regulations in your region,{' '}
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
        <Button
          type="text"
          label="please add your billing address"
          onClick={(): void => {
            onUpdateAddressClick()
          }}
        />
      </Text>
    </li>
  )
}

const TaxMessaging = ({
  hasValidTaxAddress,
  isInTaxableLocation,
  onUpdateAddressClick,
}: TaxMessagingProps): JSX.Element | null => {
  if (hasValidTaxAddress && isInTaxableLocation)
    return (
      <Container data-testid="taxMessaging">
        <ul>
          <ExistingCustomerWithAddress />
        </ul>
      </Container>
    )

  if (!hasValidTaxAddress)
    return (
      <Container data-testid="taxMessaging">
        <ul>
          <ExistingCustomerWithoutAddress
            onUpdateAddressClick={onUpdateAddressClick}
          />
        </ul>
      </Container>
    )

  return null
}

TaxMessaging.propTypes = {
  hasAddress: PropTypes.bool,
  isInTaxableLocation: PropTypes.bool,
  onUpdateAddressClick: PropTypes.func,
}

export default TaxMessaging
