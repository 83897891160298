import { ChannelThirdPartyService } from '../../../../../channel-connections/interfaces'

function writeCookie(
  key: string,
  value: string,
  expires: string,
  domain?: string,
): void {
  let cookieValue = `oauthChannels${key}=${value};path=/;expires=${expires}`
  if (domain) cookieValue += `;domain=${domain}`
  document.cookie = cookieValue
}

export function setCookie(key: string, value: string): void {
  const now = new Date()
  // In case something goes wrong we don't want the cookie to persist and potentially create side effects
  const FiveMinutes = 5 * 60 * 1000
  now.setTime(now.getTime() + FiveMinutes)
  writeCookie(key, value, now.toUTCString())
}

export function expireCookie(
  key: string,
  value: string | null,
  domain?: string,
): void {
  if (value) {
    writeCookie(key, value, 'Thu, 01 Jan 1970 00:00:00 UTC', domain)
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
export function setChannelCookies(type, serviceId = null): void {
  setCookie('Type', type)
  if (serviceId) {
    setCookie('ChannelId', serviceId)
  }
}

export function saveChannelObjectIdInCookie(channelObjectId: string): void {
  setCookie('ChannelObjectId', channelObjectId)
}

export function getChannelsCookies() {
  // Type of channel (page, profile, group)
  const [, oauthChannelsType] = document.cookie.match(
    /oauthChannelsType=(\w+)/,
  ) || [null, 'page'] // eslint-disable-line no-unused-vars

  // Service ID of channel
  const [, oauthChannelsChannelId] = document.cookie.match(
    /oauthChannelsChannelId=([a-zA-Z0-9:_-]*)/,
  ) || [null, null] // eslint-disable-line no-unused-vars

  // Channel object ID in our MongoDB database (channels collection)
  const [, oauthChannelObjectId] = document.cookie.match(
    /oauthChannelsChannelObjectId=([a-zA-Z0-9:_-]*)/,
  ) || [null, null] // eslint-disable-line no-unused-vars

  return {
    oauthChannelsType,
    oauthChannelsChannelId,
    oauthChannelObjectId,
  }
}

export function cleanupOauthCookie(): void {
  const { oauthChannelsType, oauthChannelsChannelId } = getChannelsCookies()
  expireCookie('Type', oauthChannelsType)
  expireCookie('ChannelId', oauthChannelsChannelId)
  expireCookie('ChannelObjectId', oauthChannelsChannelId)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
export function setChannelStateCookies(service, state): void {
  const now = new Date()
  const FifteenMinutes = 15 * 60 * 1000
  now.setTime(now.getTime() + FifteenMinutes)

  writeCookie(service, state, now.toUTCString())
}

// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
export function getChannelStateCookies(service) {
  if (service === ChannelThirdPartyService.tiktok) {
    // @ts-expect-error TS(2488) FIXME: Type 'RegExpMatchArray | null' must have a '[Symbo... Remove this comment to see the full error message
    const [, oauthChannelState] = document.cookie.match(
      /oauthChannelstiktok=([a-zA-Z0-9:_-]*)/,
    )
    return oauthChannelState
  }
}

export function getChannelStateCookiesV2(
  service: ChannelThirdPartyService,
): string | null {
  const regex = new RegExp(`oauthChannels${service}=([a-zA-Z0-9:_-]*)`)
  const [, oauthChannelState] = document?.cookie?.match(regex) || []

  return oauthChannelState
}

export function getReconnectingChannelCookie(): string | null {
  const [, reconnectingChannel] =
    document?.cookie?.match(/ReconnectingChannel=([a-zA-Z0-9:_-]*)/) || []

  return reconnectingChannel
}

export function getRedirectURLCookie(): string | null {
  const [, redirectURL] =
    document?.cookie?.match(/RedirectURL=([/a-zA-Z0-9.:_-]*)/) || []

  return redirectURL
}

export function getMastodonServerCookie(): string | null {
  const [, server] =
    document?.cookie?.match(/MastodonServer=([/a-zA-Z0-9.:_-]*)/) || []

  return server
}

export function getFollowBufferCookie(): string | null {
  const [, followBuffer] =
    document?.cookie?.match(/FollowBuffer=([/a-zA-Z0-9.:_-]*)/) || []

  return followBuffer
}

export function cleanupChannelConnectionCookies(
  service: ChannelThirdPartyService,
): void {
  expireCookie('ReconnectingChannel', 'false', 'buffer.com')
  expireCookie('RedirectURL', 'false', 'buffer.com')
  expireCookie('FollowBuffer', 'false', 'buffer.com')
  expireCookie(service, 'false', 'buffer.com')
}
