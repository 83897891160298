// NOTE: This is a copy from the core-modules
import type { StatusLabel } from '../gql/graphql'

export enum Product {
  buffer = 'buffer',
  analyze = 'analyze',
  engage = 'engage',
  publish = 'publish',
  startPage = 'startPage',
}

export enum ChannelThirdPartyService {
  facebook = 'facebook',
  instagram = 'instagram',
  linkedin = 'linkedin',
  pinterest = 'pinterest',
  shopify = 'shopify',
  twitter = 'twitter',
  tiktok = 'tiktok',
  googlebusiness = 'googlebusiness',
  mastodon = 'mastodon',
  youtube = 'youtube',
  threads = 'threads',
  bluesky = 'bluesky',
}

export enum ChannelNativeService {
  startPage = 'startPage',
}

// That's a trick to merge two enums into one
export const Service = { ...ChannelThirdPartyService, ...ChannelNativeService }
export type Service = ChannelThirdPartyService | ChannelNativeService

export enum ChannelType {
  business = 'business',
  page = 'page',
  profile = 'profile',
  group = 'group',
  account = 'account',
  channel = 'channel',
}

export interface HelpCenterLink {
  text: string
  url: string
}

export enum ContentType {
  image = 'image',
  video = 'video',
  text = 'text',
  analytics = 'analytics',
  engagement = 'engagement',
  stories = 'stories',
  reels = 'reels',
  shorts = 'shorts',
  post_analytics = 'post_analytics',
  audience_insights = 'audience_insights',
  threads = 'threads',
  pdf = 'pdf',
  link_tracking = 'link_tracking',
  page_analytics = 'page_analytics',
}

export enum ChannelCategory {
  small_business = 'small_business',
  creator = 'creator',
  ecommerce = 'ecommerce',
}

export interface ConnectableService {
  service: Service
  types: ChannelType[]
  products: Product[]
  serviceStatus: {
    label: StatusLabel
    details?: {
      upvoteId: number
      message?: string
    }
  }
  canConnect: boolean
  title: string
  subtitle: string
  serviceIconUrl: string
  description: string[]
  links?: HelpCenterLink[]
  contentTypes: ContentType[]
  audience?: string
  socialProof?: string
  similarNetworks?: Service[]
  tag?: string
  categories: ChannelCategory[]
}
