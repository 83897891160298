import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'

import { Wrapper, InfoIconStyled } from './style'

// @ts-expect-error TS(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
const WarningMessage = ({ message }) => (
  <Wrapper>
    <InfoIconStyled />
    <Text type="p" color="grayDarker">
      {message}
    </Text>
  </Wrapper>
)

WarningMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default WarningMessage
