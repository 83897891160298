import styled from 'styled-components'
import { white, grayLight } from '@bufferapp/ui/style/colors'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'

export const Circle = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: ${grayLight};
  margin-right: 0.5rem;
`

export const SmallCircle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  border: 2px solid ${white};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${grayLight};
`

export const LineWrapper = styled.div`
  margin-left: 0.625rem;
`

export const Line = styled.div<{
  marginBottom?: string
  width: string
  height: string
}>`
  border-radius: 4px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '0.625rem')};
  background-color: ${grayLight};
`

export const CircleWrapper = styled.div`
  position: relative;
`

export const Container = styled.div<{
  lastChannelOnList: boolean
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.625rem;
  border-bottom: ${(props) =>
    !props.lastChannelOnList ? '1px solid #e8e8e8' : 'none'};
`

export const MoreIconStyled = styled(MoreIcon)`
  margin-left: auto;
`
