import React from 'react'
import { Text } from '@bufferapp/ui'
import PropTypes from 'prop-types'

import { LabelGroupWrapper } from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const LabelGroup = ({ text, children }) => {
  return (
    <LabelGroupWrapper>
      <Text type="label">{text}</Text>
      {children}
    </LabelGroupWrapper>
  )
}

LabelGroup.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
}

export default LabelGroup
