import type { ChannelThirdPartyService } from '../../../../channel-connections/interfaces'
import type { Account } from '../../pages/Channels/auth-flows/shared/SelectChannelList/utils'

export type ServiceOauthData = {
  codeVerifier?: string
  code: string
}

export enum ChannelType {
  business = 'business',
  page = 'page',
  profile = 'profile',
  group = 'group',
  account = 'account',
  channel = 'channel',
}

export enum ConnectionAvailability {
  alreadyConnected = 'alreadyConnected',
  available = 'available',
  unavailable = 'unavailable',
}

export type LocationData = {
  location?: string
  mapsLink?: string
  googleAccountId?: string
}

export type ConnectableChannelData = {
  name: string
  serviceId: string
  isLocked: boolean
  avatar?: string
  displayName?: string
  serverUrl?: string
  locationData?: LocationData
  connectionAvailability?: ConnectionAvailability
  type: ChannelType
}

export type MapDataToChannelWithCredentialsArgs = {
  channel: ConnectableChannelData
  account: Account
  service: ChannelThirdPartyService
}

export interface ChannelWithCredentialsInput {
  avatar: string
  name: string
  organizationId: string
  products?: Array<string>
  service: ChannelThirdPartyService
  type: ChannelType
  serviceId: string
  locationData?: LocationData
  displayName?: string
  serverUrl?: string
  timezone?: string
  channelId?: string
  verified?: boolean
}

export type ConnnectedChannelData = {
  id: string
  isLocked: string
  isNew: boolean
}
