import React from 'react'
import PropTypes from 'prop-types'
import { Link, Modal, Text } from '@bufferapp/ui'
import { HC_UTM_PARAMS } from '../../../../../../../shared-utils/constants'
import { Title, Body } from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
const PermissionsErrorModal = ({ onClose }) => {
  return (
    // @ts-expect-error TS(2322)
    <Modal
      closeButton={{
        callback: (): void => {
          onClose()
        },
      }}
    >
      <Title>Whoops, we couldn&apos;t refresh the channel connection.</Title>
      <Body>
        <Text type="p">
          It looks like you may be missing some of the needed permissions for
          the connection.{' '}
          <Link
            newTab
            href={`https://support.buffer.com/article/565-troubleshooting-facebook-and-instagram-connections?${HC_UTM_PARAMS}`}
            fontWeight={700}
          >
            This article
          </Link>{' '}
          can walk you through fixing the permissions or let us know if you need
          more assistance.
        </Text>
      </Body>
    </Modal>
  )
}

PermissionsErrorModal.propTypes = {
  onClose: PropTypes.func,
}

export default PermissionsErrorModal
