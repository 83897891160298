import React from 'react'
import PropTypes from 'prop-types'

import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'width' implicitly has an 'any' ty... Remove this comment to see the full error message
const SkeletonText = ({ width, margin }) => (
  <Styles.Text width={width} margin={margin} />
)

SkeletonText.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.string,
}

export default SkeletonText
