import styled from 'styled-components'

export const Wrapper = styled.section`
  & + * {
    display: none;
  }
`
export const ModalFooter = styled.footer`
  width: 100%;
  box-sizing: border-box;
  background: white;
  padding: 0 16px;
  margin-top: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > span,
  & > p {
    margin: 0 auto 0 0;
  }

  & + * {
    display: none;
  }
`

export const Title = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem 1rem;
  box-sizing: border-box;
`

export const ModalContent = styled.main`
  background: #f5f5f5;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
`

export const Form = styled.form`
  fieldset {
    border: transparent;
    padding: 0;
    margin: 8px 0 16px 0;
  }
`

export const StyledReason = styled.div`
  position: relative;
  padding: 4px 0;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`

export const StyledRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
`

export const CheckMark = styled.div`
  transition: all 0.2s ease-out;
  content: '';
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid #b8b8b8;
  border-radius: 100%;
  background: transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    top: calc(50% - 2px);
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: transparent;
  }

  ${StyledRadio}:hover ~ & {
    box-shadow: 0px 0px 0px 3px #abb7ff;
    border-color: #2c4bff;

    &::before {
      background: #2c4bff;
    }
  }

  ${StyledRadio}:checked ~ & {
    transition: all 0.3s ease-out;
    background-color: #2c4bff;
    border-color: #2c4bff;

    &::before {
      background: #ffffff;
    }
  }
`
