import React from 'react'
import styled from 'styled-components'
import { Button, Text } from '@bufferapp/ui'
import { Card, FormBlock } from '../../../account-components'

import { Strong } from '../Text'
import CardTitle from '../CardTitle'

const Content = styled.main`
  font-weight: 500;
  padding-top: 1rem;
`

const ExpiryDate = styled.section`
  margin-right: 1.5rem;
`

const ExpiryDateLabel = styled.span`
  color: #636363;
  margin-right: 0.5rem;
`

const CardPreview = styled.section`
  margin: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Details = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
`

const CardType = styled.span`
  background: linear-gradient(
      307.87deg,
      #ffffff -80.4%,
      rgba(255, 255, 255, 0) 104.26%
    ),
    #2c4bff;
  border-radius: 3px;
  padding: 6px 9px;
  color: white;
  font-size: 11px;
`

const CardDot = styled.i`
  display: inline-block;
  height: 4px;
  width: 4px;
  background: #3d3d3d;
  border-radius: 50%;

  &:not(:last-child) {
    margin-right: 2px;
  }
`

const Spacer = styled.span`
  margin-left: 1rem;
`

const DotsWrapper = styled.span`
  margin-right: 0.5rem;
  line-height: 0;
`

const Dots = () => (
  <DotsWrapper>
    <CardDot />
    <CardDot />
    <CardDot />
    <CardDot />
  </DotsWrapper>
)

const CardDetails = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'trialing' implicitly has an 'any'... Remove this comment to see the full error message
  trialing,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cardDetails' implicitly has an 'a... Remove this comment to see the full error message
  cardDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cancelAt' implicitly has an 'any'... Remove this comment to see the full error message
  cancelAt,
  // @ts-expect-error TS(7031) FIXME: Binding element 'canceledAt' implicitly has an 'an... Remove this comment to see the full error message
  canceledAt,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openCancelSubscriptionModal' impl... Remove this comment to see the full error message
  openCancelSubscriptionModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'changeCardDetails' implicitly has... Remove this comment to see the full error message
  changeCardDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'reenabling' implicitly has an 'an... Remove this comment to see the full error message
  reenabling,
  // @ts-expect-error TS(7031) FIXME: Binding element 'reenableSubscription' implicitly ... Remove this comment to see the full error message
  reenableSubscription,
}) => {
  if (!cardDetails) {
    return null
  }

  const willCancel = canceledAt || cancelAt

  return (
    <Card>
      <Content>
        <CardTitle>Payment Method</CardTitle>
        <FormBlock inline align="left" condensed withBorder>
          <Details>
            <CardType>
              {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
              <Text>{cardDetails.type}</Text>
            </CardType>
            <CardPreview>
              <Dots /> <Dots /> <Dots />{' '}
              <Text type="label">
                <Strong>{cardDetails.last4}</Strong>
              </Text>
            </CardPreview>
            <ExpiryDate>
              <Text type="label">
                <ExpiryDateLabel>Expires</ExpiryDateLabel>
                <Strong>
                  {cardDetails.expMonth}/{cardDetails.expYear}
                </Strong>
              </Text>
            </ExpiryDate>
          </Details>
        </FormBlock>
      </Content>
      <FormBlock condensed inline align="right">
        {cancelAt && (
          // @ts-expect-error TS(2740) FIXME: Type '{ type: string; disabled: any; label: string... Remove this comment to see the full error message
          <Button
            type="primary"
            disabled={reenabling}
            label={
              reenabling
                ? 'Re-enabling subscription...'
                : 'Re-enable Analyze subscription'
            }
            onClick={reenableSubscription}
          />
        )}
        {canceledAt && (
          // @ts-expect-error TS(2740) FIXME: Type '{ type: string; disabled: any; label: string... Remove this comment to see the full error message
          <Button
            type="primary"
            disabled={reenabling}
            label={
              reenabling
                ? 'Starting subscription...'
                : 'Start a new Analyze subscription'
            }
            onClick={reenableSubscription}
          />
        )}
        {!willCancel && (
          // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message
          <Button
            type="secondary"
            label={trialing ? 'Cancel Trial' : 'Cancel Subscription'}
            onClick={openCancelSubscriptionModal}
          />
        )}
        <Spacer />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
        <Button
          type="secondary"
          label="Change Card"
          onClick={changeCardDetails}
        />
      </FormBlock>
    </Card>
  )
}

export default CardDetails
