export const getServiceFromUrl = (): string => {
  const [, service] = window.location.search.match(/service=(\w+)/) || []
  return service
}

export const getErrorCodeFromUrl = (): string => {
  const [, code] = window.location.search.match(/code=(\w+)/) || []
  return code
}

export const getAuthCodeFromUrl = (): string => {
  const [, code] = window.location.search.match(/code=(.+?)(?=$|&)/) || []
  return code
}

export const getMastodonServerFromUrl = (): string => {
  const [, mastodonServer] =
    window.location.search.match(/server=(.+?)(?=$|&)/) || []
  return mastodonServer
}

export const getShouldFollowBufferOnMastodon = (): boolean => {
  const [, followBuffer] =
    window.location.search.match(/followBuffer=(.+?)(?=$|&)/) || []

  return followBuffer === 'true'
}

export const getStateTokenFromUrl = (): string => {
  const [, state] = window.location.search.match(/state=(.+?)(?=$|&)/) || []
  return state || ''
}

export const getErrorMessageFromUrl = (): string => {
  const [, errorMessage] =
    window.location.search.match(/error=(.+?)(?=$|&)/) || []
  return errorMessage
}

export const getNetworkChannelIdFromUrl = (network: string): string => {
  const regex = new RegExp(`${network}=(.+?)(?=$|&)`)
  const [, channelId] = window.location.search.match(regex) || []
  return channelId
}

export const removeOAuthParamsFromUrl = (path = '/channels/connect'): void => {
  window.history.replaceState({}, document.title, path)
}

export const getStartConnectionServiceFromUrl = (): string | null => {
  const url = new URL(window.location.href)
  return url.searchParams.get('startConnectionService') || null
}
