import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_CUSTOMER_PORTAL_SESSION } from '../../../../../apollo/mutations/billing'
import { useAccount } from '../../../../../context/Account'
import { getProductUrl } from '../../../../../../../shared-utils'

export default function () {
  const account = useAccount()
  const productURL = getProductUrl('account')
  const openBillingPortal = (ctaClicked: boolean): void => {
    if (!ctaClicked) {
      return
    }
    getCustomerPortalSession({
      variables: {
        organizationId: account.currentOrganization.id,
        returnURL: `${productURL}/billing`,
      },
    })
  }
  const [errorMessage, setErrorMessage] = useState(null)
  const [getCustomerPortalSession, { error: mutationError, loading }] =
    useMutation(CREATE_CUSTOMER_PORTAL_SESSION, {
      onCompleted(data) {
        if (data?.billingCreateCustomerPortalSession.userFriendlyMessage) {
          setErrorMessage(
            data?.billingCreateCustomerPortalSession.userFriendlyMessage,
          )
        } else {
          window.open(
            `${data.billingCreateCustomerPortalSession.customerPortalSessionUrl}/customer/update`,
            '_self',
          )
          setErrorMessage(null)
        }
      },
      onError(e) {
        setErrorMessage(
          // @ts-expect-error TS(2345) FIXME: Argument of type '"Whoops! Looks like there was a ... Remove this comment to see the full error message
          `Whoops! Looks like there was a problem processing your request. Please try again. If you continue experiencing this problem, please contact us.`,
        )
        throw new Error(
          `There was a problem fetching the customer portal session: ${e.message}.`,
        )
      },
    })

  return {
    openBillingPortal,
    mutationError,
    errorMessage,
    loading,
  }
}
