import styled from 'styled-components'
import { grayLight } from '@bufferapp/ui/style/colors'

export const InputWrapper = styled.div`
  border: 1px solid ${grayLight};
  border-radius: 4px;
  padding: 8px;
`

export const ErrorWrapper = styled.div`
  margin-top: 1rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`
