import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, Text } from '@bufferapp/ui'
import {
  BlockWrapper,
  Card,
  CardBody,
} from '../../../../../../account-components'
import { useAccount } from '../../../../context/Account'

import Table from './components/Table'

import { INVOICES_BY_ORGANIZATION } from '../../../../apollo/queries/billing'

import * as Styles from './styles'

const InvoicesAndReceipts = () => {
  const [invoices, setInvoices] = useState([])

  const [fetchInvoices, { loading, error, data, fetchMore }] = useLazyQuery(
    INVOICES_BY_ORGANIZATION,
    {
      notifyOnNetworkStatusChange: true,
    },
  )

  const user = useAccount()
  const organizationId = user.currentOrganization.id
  const INVOICES_PER_REQUEST = 12

  useEffect(() => {
    if (!user || !user.currentOrganization.canEdit) return
    fetchInvoices({
      variables: {
        organizationId,
        first: INVOICES_PER_REQUEST,
      },
    })
  }, [organizationId])

  useEffect(() => {
    if (data) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setInvoices([...invoices, ...data.invoicesByOrganization.edges])
    }
  }, [data])

  // @ts-expect-error TS(7006) FIXME: Parameter 'pageInfo' implicitly has an 'any' type.
  const handleFetchMore = (pageInfo) => {
    fetchMore({
      variables: {
        organizationId,
        first: INVOICES_PER_REQUEST,
        after: pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!previousResult?.invoicesByOrganization) return

        const newEdges = fetchMoreResult?.invoicesByOrganization.edges || []
        const pageInfo = fetchMoreResult?.invoicesByOrganization.pageInfo

        return {
          invoicesByOrganization: {
            __typename: previousResult.invoicesByOrganization.__typename,
            edges: [...newEdges],
            pageInfo,
          },
        }
      },
    })
  }

  if (loading) {
    return (
      <BlockWrapper id="invoices">
        <Card title="Invoices & Receipts">
          <Table isLoading />
        </Card>
      </BlockWrapper>
    )
  }

  if (!user.currentOrganization.canEdit) {
    return (
      <BlockWrapper id="invoices">
        <Card title="Invoices & Receipts">
          <CardBody>
            <Text type="p">
              Contact an Admin in your organization to view invoices and
              receipts.
            </Text>
          </CardBody>
        </Card>
      </BlockWrapper>
    )
  }

  if (error || !data) {
    return (
      <BlockWrapper id="invoices">
        <Card title="Invoices & Receipts">
          <CardBody>
            <Text type="p">
              Sorry, but there was a problem loading your invoices. Please
              refresh the page to try again.
            </Text>
          </CardBody>
        </Card>
      </BlockWrapper>
    )
  }

  const { pageInfo } = data.invoicesByOrganization

  return (
    <BlockWrapper id="invoices">
      <Card title="Invoices & Receipts">
        {invoices.length > 0 ? (
          <Table invoices={invoices}></Table>
        ) : (
          <Styles.Row>
            <Text type="p">
              You don&apos;t have any invoices or receipts yet
            </Text>
          </Styles.Row>
        )}

        {pageInfo?.hasNextPage ? (
          <Styles.LoadMoreWrapper>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ onClick: () => void; disabled: false; labe... Remove this comment to see the full error message */}
            <Button
              onClick={() => handleFetchMore(pageInfo)}
              disabled={loading}
              label={loading ? 'Loading...' : 'Load more'}
            />
          </Styles.LoadMoreWrapper>
        ) : null}
      </Card>
    </BlockWrapper>
  )
}

export default InvoicesAndReceipts
