import styled from 'styled-components'

export const TableColumn = styled.div`
  flex: 1;

  &:first-of-type {
    flex: 2;
  }

  &:last-of-type {
    text-align: right;
  }
`
