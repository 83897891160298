import { connect } from 'react-redux'
import CentralizedBilling from './components/CentralizedBilling'
import { actions } from './reducer'
import { actions as cancelPlanActions } from '../cancel-plan'

export default connect(
  (state) => ({
    ...state.centralizedBilling,
    // @ts-expect-error TS(2339) FIXME: Property 'creditCard' does not exist on type 'Defa... Remove this comment to see the full error message
    creditCard: state.creditCard,
    currentOrganization: state.account.currentOrganization,
    isOneBufferOrganization:
      state.account.currentOrganization.isOneBufferOrganization,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'organizationStripeId' implicitly has an... Remove this comment to see the full error message
    selectOrganization: (organizationStripeId) =>
      dispatch(actions.selectOrganization(organizationStripeId)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'values' implicitly has an 'any' type.
    changePlan: (values) => dispatch(actions.changePlan(values)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'options' implicitly has an 'any' type.
    fetchInvoices: (options) => dispatch(actions.fetchInvoices(options)),
    // @ts-expect-error TS(7031) FIXME: Binding element 'currentPlan' implicitly has an 'a... Remove this comment to see the full error message
    openChangePlanModal: ({ currentPlan, currentProduct, currentCycle }) =>
      dispatch(
        actions.openChangePlanModal({
          currentPlan,
          currentProduct,
          currentCycle,
        }),
      ),
    // @ts-expect-error TS(7006) FIXME: Parameter 'product' implicitly has an 'any' type.
    openCancelPlanModal: (product) => {
      dispatch(cancelPlanActions.openCancelPlanModal(product))
    },
    closeChangePlanModal: () => dispatch(actions.closeChangePlanModal()),
    toggleChangePlanCycle: () => dispatch(actions.toggleChangePlanCycle()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
    selectPlanForReview: (plan) => dispatch(actions.selectPlanForReview(plan)),
    resetPlanForReview: () => dispatch(actions.resetPlanForReview()),
    closeChangePlanNotification: () =>
      dispatch(actions.closeChangePlanNotification()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'view' implicitly has an 'any' type.
    updateChangePlanModalView: (view) =>
      dispatch(actions.updateChangePlanModalView(view)),
  }),
)(CentralizedBilling)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
