/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Text, Link } from '@bufferapp/ui'
import { ModalTitle } from '../../../../../../../account-components'
import { Cross, Warning } from '@bufferapp/ui/Icon'
import { useDispatch } from 'react-redux'

import { actions, errorTypes } from '../../../../../../../channel-list/reducer'

import { Body } from '../Modal/styles'
import { ModalHeader, IconWrapper, LeftIconWrapper } from './styles'

import { HC_UTM_PARAMS } from '../../../../../../../shared-utils/constants'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error TS(7006) FIXME: Parameter 'errorType' implicitly has an 'any' type... Remove this comment to see the full error message
function getTitle(errorType): string {
  switch (errorType) {
    case errorTypes.CHANNEL_STEALING:
      return 'Sorry, the channel you are trying to connect is unavailable'
    default:
      return 'Looks like we’ve hit a snag. Would you mind trying again?'
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'errorType' implicitly has an 'any... Remove this comment to see the full error message
function getBody({ errorType, link }): JSX.Element {
  switch (errorType) {
    case errorTypes.CHANNEL_STEALING:
      return (
        <Text type="p">
          It looks like that channel is already connected to another Buffer
          organization. For security reasons, each unique channel may only be
          connected to one organization at a time.
          <br />
          <br />
          Removing the channel from the other organization will make it
          available to connect here.
          <a
            href={`https://support.buffer.com/article/571-unavailable-channels-are-likely-connected-to-another-buffer-organization?${HC_UTM_PARAMS}`}
          >
            Learn more
          </a>{' '}
          about resolving this issue in our Help Center.
        </Text>
      )
    default:
      return (
        <Text type="p">
          Something went wrong while trying to connect your channel. Check out
          our{' '}
          {link ? (
            <Link href={link} newTab>
              troubleshooting guide
            </Link>
          ) : (
            'troubleshooting guide'
          )}
          , and if the problem persists, please get in touch.
        </Text>
      )
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, react/prop-types
const GenericErrorModal = ({ onClose, errorType, link }): JSX.Element => {
  const dispatch = useDispatch()

  return (
    // @ts-expect-error TS(2322)
    <Modal
      secondaryAction={{
        label: 'Close',
        callback: (): void => {
          dispatch(actions.close())
          onClose()
        },
      }}
      dismissible={false}
    >
      <ModalHeader>
        <LeftIconWrapper>
          <IconWrapper>
            <Warning />
          </IconWrapper>
        </LeftIconWrapper>
        <ModalTitle text={getTitle(errorType)} />
        <IconWrapper>
          <Cross
            onClick={(): void => {
              dispatch(actions.close())
              onClose()
            }}
          />
        </IconWrapper>
      </ModalHeader>
      <Body>{getBody({ errorType, link })}</Body>
    </Modal>
  )
}

GenericErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  link: PropTypes.string,
}

GenericErrorModal.defaultProps = {
  onClose: (): void => undefined,
  link: undefined,
}

export default GenericErrorModal
