import styled, { css } from 'styled-components'
import {
  blue,
  grayLighter,
  grayDark,
  grayDarker,
  redDark,
} from '@bufferapp/ui/style/colors'
import { fontSize } from '@bufferapp/ui/style/fonts'

export const Content = styled.main`
  font-weight: 500;
`

export const ExpiryDate = styled.section`
  margin-right: 1.5rem;

  span {
    font-size: ${fontSize};
  }
`

export const ExpiryDateLabel = styled.span`
  color: ${grayDark};
  margin-right: 0.5rem;
`

export const CardPreview = styled.section`
  margin: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: ${fontSize};
  }
`

export const CardDetails = styled.section<{
  withColor: boolean
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.withColor &&
    css`
      padding: 10px;
      background: ${grayLighter};
    `}
`

export const CardType = styled.span`
  background: linear-gradient(
      307.87deg,
      #ffffff -80.4%,
      rgba(255, 255, 255, 0) 104.26%
    ),
    ${blue};
  border-radius: 3px;
  padding: 6px 9px;
  color: white;
  font-size: 11px;
`

export const CardDot = styled.i`
  display: inline-block;
  height: 4px;
  width: 4px;
  background: ${grayDarker};
  border-radius: 50%;

  &:not(:last-child) {
    margin-right: 2px;
  }
`

export const DotsWrapper = styled.span`
  margin-right: 0.5rem;
  line-height: 0;
`

export const Wrapper = styled.section`
  width: 330px;

  & + * {
    display: none;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    margin-left: 0.5rem;
  }
`

export const Notice = styled.div`
  border: 1px solid #a59638;
  color: #625920;
  background: #fdf8d8;
  border-radius: 4px;
  font-size: 14px;
  padding: 16px;
`

export const NotificationWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
`

export const TrialMessagingWrapper = styled.span`
  margin-top: 1rem;
  display: inline-block;
`

export const NoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 0 16px;
  p {
    margin-left: 0.5rem;
    color: ${redDark};
    font-weight: 500;
  }
  svg {
    color: ${redDark};
  }
`
