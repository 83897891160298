import React from 'react'
import styled from 'styled-components'
import { Button } from '@bufferapp/ui'
import { Card, FormBlock } from '../../../account-components'
import CardTitle from '../CardTitle'
import PlanInformation from './PlanInformation'

const Subscription = styled.section`
  padding-bottom: 0.5rem;
`

const Content = styled.main`
  font-weight: 500;
  padding-top: 1rem;
`

const ButtonWrapper = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Plan = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'addBillingDetails' implicitly has... Remove this comment to see the full error message
  addBillingDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cardDetails' implicitly has an 'a... Remove this comment to see the full error message
  cardDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'plan' implicitly has an 'any' typ... Remove this comment to see the full error message
  plan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trial' implicitly has an 'any' ty... Remove this comment to see the full error message
  trial,
  // @ts-expect-error TS(7031) FIXME: Binding element 'canceledAt' implicitly has an 'an... Remove this comment to see the full error message
  canceledAt,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cancelAt' implicitly has an 'any'... Remove this comment to see the full error message
  cancelAt,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openChangeBillingCycleModal' impl... Remove this comment to see the full error message
  openChangeBillingCycleModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'changePlan' implicitly has an 'an... Remove this comment to see the full error message
  changePlan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'canSwitchPlans' implicitly has an... Remove this comment to see the full error message
  canSwitchPlans,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasAnalyzeAccessThroughPublishSub... Remove this comment to see the full error message
  hasAnalyzeAccessThroughPublishSubscription,
}) => {
  return !plan ? null : (
    <Card>
      <Content>
        <CardTitle>Plan</CardTitle>
        <FormBlock inline align="left" condensed withBorder>
          <Subscription>
            <PlanInformation
              {...trial}
              {...plan}
              cardDetails={cardDetails}
              canceledAt={canceledAt}
              cancelAt={cancelAt}
              hasAnalyzeAccessThroughPublishSubscription={
                hasAnalyzeAccessThroughPublishSubscription
              }
            />
          </Subscription>
        </FormBlock>
      </Content>
      {!hasAnalyzeAccessThroughPublishSubscription && (
        <FormBlock condensed inline align="center">
          {cardDetails && (
            <ButtonWrapper>
              {canSwitchPlans && (
                // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message
                <Button
                  type="secondary"
                  label="Change Plan"
                  onClick={changePlan}
                />
              )}
              {!canSwitchPlans && (
                // @ts-expect-error TS(2740) FIXME: Type '{ onClick: () => any; type: string; label: s... Remove this comment to see the full error message
                <Button
                  onClick={() => openChangeBillingCycleModal()}
                  type="secondary"
                  label={`Switch to ${
                    plan.interval === 'month' ? 'yearly' : 'monthly'
                  } billing`}
                />
              )}
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
              <Button
                type="secondary"
                label="Need more than 10 channels?"
                onClick={() =>
                  (window.location.href =
                    'mailto:hello+analyze@buffer.com?subject=I need more than 10 Analyze channels')
                }
              />
            </ButtonWrapper>
          )}
          {!cardDetails && (
            <ButtonWrapper>
              {canSwitchPlans && (
                // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message
                <Button
                  type="secondary"
                  label="Change Plan"
                  onClick={changePlan}
                />
              )}
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
              <Button
                type="primary"
                label="Add Billing Details"
                onClick={addBillingDetails}
              />
            </ButtonWrapper>
          )}
        </FormBlock>
      )}
    </Card>
  )
}

export default Plan
