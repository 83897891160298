import gql from 'graphql-tag'

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation updatePlan(
    $organizationId: String
    $plan: OBPlanId
    $interval: BillingInterval
  ) {
    billingUpdateSubscriptionPlan(
      organizationId: $organizationId
      plan: $plan
      interval: $interval
    ) {
      ... on BillingUpdateSubscriptionPlanResponse {
        success
        scheduledUpdate {
          newPlan
          newInterval
          newQuantity
          effectiveDate
        }
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const MIGRATE_TO_NEW_BUFFER = gql`
  mutation migrateToBufferViaHub(
    $organizationId: String!
    $plan: OBPlanId!
    $interval: BillingInterval!
    $quantity: Int!
  ) {
    billingMigrateToBufferViaHub(
      organizationId: $organizationId
      plan: $plan
      interval: $interval
      quantity: $quantity
    ) {
      ... on BillingMigrateToOneBufferResponse {
        billing {
          id
          canAccessPublishing
          canAccessAnalytics
          canAccessEngagement
          balance
        }
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const CREATE_CUSTOMER_PORTAL_SESSION = gql`
  mutation createCustomerPortalSession(
    $organizationId: String!
    $returnURL: String!
  ) {
    billingCreateCustomerPortalSession(
      organizationId: $organizationId
      returnURL: $returnURL
    ) {
      ... on BillingCreateCustomerPortalSessionResponse {
        customerPortalSessionUrl
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const REMOVE_SUBSCRIPTION_SCHEDULE = gql`
  mutation removeSubscriptionSchedule($organizationId: OrganizationId!) {
    removeSubscriptionSchedule(organizationId: $organizationId) {
      ... on RemoveSubscriptionScheduleResponse {
        success
      }
      ... on RemoveSubscriptionScheduleError {
        message
      }
    }
  }
`
