import { actions } from './reducer'
import {
  actionTypes as dataFetchActionTypes,
  actions as dataFetchActions,
  // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
} from '@bufferapp/async-data-fetch'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)

    switch (action.type) {
      case 'APP_INIT': {
        dispatch(
          dataFetchActions.fetch({
            name: 'getActiveProducts',
          }),
        )
        break
      }
      case `getActiveProducts_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        dispatch(actions.activeProductsLoadSuccess(action.result))
        break
      }
      case `getActiveProducts_${dataFetchActionTypes.FETCH_FAIL}`: {
        dispatch(actions.activeProductsLoadFailed())
        break
      }
      default:
        break
    }
  }
