import React from 'react'
import PropTypes from 'prop-types'
import { ModalStyled } from './style'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Modal = ({ children }) => <ModalStyled>{children}</ModalStyled>

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

Modal.defaultProps = {
  children: null,
}

export default Modal
