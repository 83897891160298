import { yellowDarker } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const Container = styled.div`
  margin: 0.5rem 0 1rem 0;
  display: flex;

  button {
    height: 0;
    padding: 0 0 3px 0;
    color: ${yellowDarker};
    font-weight: 700;
    text-decoration: underline;
  }

  a {
    color: inherit;
  }
`
