import styled from 'styled-components'

export const ModalWrapper = styled.div`
  width: 513px;
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;

  label {
    color: #3d3d3d;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 8px;
  }

  select {
    width: 100%;
    background-color: #ffffff;
    padding: 0px 9px;
    border: 1px solid #b8b8b8;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    &:focus {
      border: 1px solid #2c4bff;
      box-shadow: 0px 0px 0px 3px #abb7ff;
      outline: none;
      transition-property: border-width, border-color, box-shadow;
      transition-duration: 0.1s;
      transition-timing-function: ease-in;
    }
  }
`
