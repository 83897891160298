import React from 'react'
import { Services } from '../../channel-list/constants'
import BufferIcon from '@bufferapp/ui/Icon/Icons/Buffer'
import { getProductUrl } from '../../shared-utils'

const config = [
  {
    key: 'buffer',
    title: (
      <>
        Get started with Buffer <BufferIcon size="large" />
      </>
    ),
    subtitle: `Connect a channel to plan, create and schedule content.`,
    nextUrl: getProductUrl('publish'),
    disabledServices: {},
    hiddenServices: [],
  },
  {
    key: 'publishing',
    title: 'Welcome to Buffer! Let’s set up your account',
    alternativeTitle: 'Now, let’s set up your account',
    trialTitle: 'Trial started 🎉 let’s set up your account',
    subtitle: `Connect a channel where you wish to grow your audience.`,
    nextUrl: `${getProductUrl('publish')}/calendar/week`,
    disabledServices: {
      [Services.TWITTER]:
        'Due to ongoing changes at Twitter, connecting a Twitter channel in Buffer is not possible right now.',
    },
    hiddenServices: [Services.SHOPIFY],
  },
  {
    key: 'analytics',
    title: 'Get started with Analytics 📈',
    subtitle: `Connect a channel to get insights on your posts and optimize your posting strategy.`,
    nextUrl: getProductUrl('analyze'),
    disabledServices: {
      [Services.TWITTER]:
        'Due to ongoing changes at Twitter, connecting a Twitter channel in Buffer is not possible right now.',
    },
    hiddenServices: [
      Services.TIKTOK,
      Services.GOOGLE_BUSINESS_PROFILE,
      Services.MASTODON,
      Services.PINTEREST,
      Services.STARTPAGE,
      Services.YOUTUBE,
      Services.THREADS,
      Services.BLUESKY,
    ],
  },
  {
    key: 'engagement',
    title: 'Get started with Engagement 💬',
    subtitle: `Connect a channel to answer your audience's comments.`,
    nextUrl: getProductUrl('engage'),
    disabledServices: {},
    hiddenServices: [
      Services.TIKTOK,
      Services.GOOGLE_BUSINESS_PROFILE,
      Services.MASTODON,
      Services.PINTEREST,
      Services.STARTPAGE,
      Services.TWITTER,
      Services.LINKEDIN,
      Services.SHOPIFY,
      Services.YOUTUBE,
      Services.THREADS,
      Services.BLUESKY,
    ],
  },
]

export type OnboardingConfig = {
  key: string
  title: React.ReactNode
  subtitle: string
  nextUrl: string | null
  disabledServices: { [key: string]: string }
  hiddenServices: string[]
}

export function getOnboardingConfig(
  productKey: string,
): OnboardingConfig | null {
  return config.find((conf) => conf.key === productKey) || null
}
