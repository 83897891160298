import styled from 'styled-components'
import { grayLight } from '@bufferapp/ui/style/colors'

export const PlanDetailsWrapper = styled.div`
  font-size: 14px;

  &:not(:last-of-type) {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid ${grayLight};
  }

  h3 {
    margin-bottom: 1rem;
    font-size: 14px;
  }
`

export const PlanHeader = styled.div`
  margin-bottom: 16px;

  h3 {
    margin: 0 8px 0 0;
  }
`

export const BodyWrapper = styled.div`
  margin-bottom: 16px;
`

export const List = styled.ul`
  flex: 1;
  padding: 0;
  margin: 0 0 1rem 0;
  list-style: none;
`

export const ListItem = styled.li<{
  dim?: boolean
}>`
  padding: 0;
  opacity: ${(props) => props.dim && 0.25};

  &:not(:last-of-type) {
    margin: 0 0 1rem 0;
  }
`

export const MessageWrapper = styled.div`
  width: 100%;
  padding: 0 0 1rem 0;
`

export const NoteWrapper = styled.div`
  width: 100%;
  padding: 0.5rem;
  background-color: #fcf2aa;
  margin: 0.5rem 0 1rem;
`

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e8e8e8;
  /* AccordionBody has a padding that we break out of below */
  margin: 0 -16px -16px -16px;
  padding: 8px 16px;

  /*
    Ideally we would be adding styles on the button, but because they
    are wrapped with a div from the UI library, we need to target the div
    based on type.
  */
  div[type='text'],
  div[type='primary'] {
    margin-left: 0.5rem;
  }
`
