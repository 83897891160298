import gql from 'graphql-tag'

export const ADD_ORGANIZATION_TO_BETA_PROGRAM = gql`
  mutation addOrganizationToBetaProgram($organizationId: String!) {
    addOrganizationToBetaProgram(organizationId: $organizationId) {
      ... on BasicError {
        userFriendlyMessage
        cause
      }
      ... on MutationBasicResponse {
        success
      }
    }
  }
`

export const REMOVE_ORGANIZATION_FROM_BETA_PROGRAM = gql`
  mutation removeOrganizationFromBetaProgram($organizationId: String!) {
    removeOrganizationFromBetaProgram(organizationId: $organizationId) {
      ... on BasicError {
        userFriendlyMessage
        cause
      }
      ... on MutationBasicResponse {
        success
      }
    }
  }
`
