import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Modal from './Modal/Wrapper'

import * as plans from '../../constants/plans'

class ChangePlan extends Component {
  state = {
    planOptions: {
      analyze: plans.analyze,
      publish: plans.publish,
    },
  }

  showPlanOptions = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'updateChangePlanModalView' does not exis... Remove this comment to see the full error message
    this.props.updateChangePlanModalView('plans')
    // @ts-expect-error TS(2339) FIXME: Property 'handleResetPlanForReview' does not exist... Remove this comment to see the full error message
    this.props.handleResetPlanForReview()
  }

  uncheckPlanInputs = () => {
    // If a user selects a plan and then toggles monthly/yearly cycles, we don't
    // want to keep the plan selected as we don't want to assume their
    // intention. Instead, we clear all selections and let
    // them explicitly re-click the plan they want.
    const allRadioPlanOptions = document.getElementsByName('subscriptionPlan')
    if (allRadioPlanOptions) {
      // @ts-expect-error TS(2339) FIXME: Property 'checked' does not exist on type 'HTMLEle... Remove this comment to see the full error message
      allRadioPlanOptions.forEach((e) => (e.checked = false))
    }
  }

  toggleCycle = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'toggleCycle' does not exist on type 'Rea... Remove this comment to see the full error message
    this.props.toggleCycle()
    this.uncheckPlanInputs()
    // @ts-expect-error TS(2339) FIXME: Property 'handleResetPlanForReview' does not exist... Remove this comment to see the full error message
    this.props.handleResetPlanForReview()
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'closeChangePlanModal' does not exist on ... Remove this comment to see the full error message
      closeChangePlanModal,
      // @ts-expect-error TS(2339) FIXME: Property 'confirmChangePlan' does not exist on typ... Remove this comment to see the full error message
      confirmChangePlan,
      // @ts-expect-error TS(2339) FIXME: Property 'creditCard' does not exist on type 'Read... Remove this comment to see the full error message
      creditCard,
      // @ts-expect-error TS(2339) FIXME: Property 'currentView' does not exist on type 'Rea... Remove this comment to see the full error message
      currentView,
      // @ts-expect-error TS(2339) FIXME: Property 'cycle' does not exist on type 'Readonly<... Remove this comment to see the full error message
      cycle,
      // @ts-expect-error TS(2339) FIXME: Property 'hasCard' does not exist on type 'Readonl... Remove this comment to see the full error message
      hasCard,
      // @ts-expect-error TS(2339) FIXME: Property 'isChangingPlan' does not exist on type '... Remove this comment to see the full error message
      isChangingPlan,
      // @ts-expect-error TS(2339) FIXME: Property 'isSubmittingCreditCard' does not exist o... Remove this comment to see the full error message
      isSubmittingCreditCard,
      // @ts-expect-error TS(2339) FIXME: Property 'planForReview' does not exist on type 'R... Remove this comment to see the full error message
      planForReview,
      // @ts-expect-error TS(2339) FIXME: Property 'product' does not exist on type 'Readonl... Remove this comment to see the full error message
      product,
      // @ts-expect-error TS(2339) FIXME: Property 'saveCardDetails' does not exist on type ... Remove this comment to see the full error message
      saveCardDetails,
      // @ts-expect-error TS(2339) FIXME: Property 'selectPlanForReview' does not exist on t... Remove this comment to see the full error message
      selectPlanForReview,
      // @ts-expect-error TS(2339) FIXME: Property 'stripeCustomerId' does not exist on type... Remove this comment to see the full error message
      stripeCustomerId,
      // @ts-expect-error TS(2339) FIXME: Property 'updateChangePlanModalView' does not exis... Remove this comment to see the full error message
      updateChangePlanModalView,
      // @ts-expect-error TS(2339) FIXME: Property 'currentPlan' does not exist on type 'Rea... Remove this comment to see the full error message
      currentPlan,
    } = this.props

    const { planOptions } = this.state

    const planName = `${product.charAt(0).toUpperCase() + product.substring(1)}`

    return (
      <Modal
        closeModalCallback={closeChangePlanModal}
        confirmChangePlan={confirmChangePlan}
        creditCard={creditCard}
        currentView={currentView}
        cycle={cycle}
        hasCard={hasCard}
        isChangingPlan={isChangingPlan}
        isSubmittingCreditCard={isSubmittingCreditCard}
        planForReview={planForReview}
        planName={planName}
        planOptions={planOptions}
        product={product}
        saveCardDetails={saveCardDetails}
        selectPlanForReview={selectPlanForReview}
        showPlanOptionsCallback={this.showPlanOptions}
        stripeCustomerId={stripeCustomerId}
        toggleCycleCallback={this.toggleCycle}
        updateChangePlanModalView={updateChangePlanModalView}
        currentPlan={currentPlan}
      />
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ChangePlan.propTypes = {
  handleCloseModal: PropTypes.func,
  handleResetPlanForReview: PropTypes.func,
  selectPlanForReview: PropTypes.func,
  isChangingPlan: PropTypes.bool,
  planForReview: PropTypes.object,
  product: PropTypes.string,
}

export default ChangePlan
