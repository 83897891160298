import styled from 'styled-components'

export const Small = styled.span`
  font-size: 12px;
`

export const Bold = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
`

export const Error = styled.span`
  color: #e0364f;
`

export const H3 = styled.h3`
  color: #3d3d3d;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
`
