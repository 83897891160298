import {
  isFreeUser,
  getUserChannelLimit,
  getUserUnlockedChannelCount,
  isTrialUser,
} from '../../../../../shared-utils'

// Rule: We want to show the "Add or Remove" cta for
// All users on Essentials, Team and Agency plans
// And Only Free users who have reached the channel limit
// Additionally, Hide for users on a Trial
// @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export function shouldShowAddOrRemoveAction(user) {
  const currentUnlockedChannels = getUserUnlockedChannelCount(user)
  const channelLimit = getUserChannelLimit(user)

  if (isTrialUser(user)) {
    return false
  }

  if (isFreeUser(user)) {
    return currentUnlockedChannels >= channelLimit
  }

  return true
}
