import React from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { Button } from '@bufferapp/ui'
import CancelPlan from '../../../../cancel-plan'

import * as Styles from './styles'
import { toTitleCase } from '../../../../shared-utils'

const Footer = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'buttonLabel' implicitly has an 'a... Remove this comment to see the full error message
  buttonLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planDetails' implicitly has an 'a... Remove this comment to see the full error message
  planDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
  product,
  // @ts-expect-error TS(7031) FIXME: Binding element 'updatePlanCallback' implicitly ha... Remove this comment to see the full error message
  updatePlanCallback,
  // @ts-expect-error TS(7031) FIXME: Binding element 'noTrialStarted' implicitly has an... Remove this comment to see the full error message
  noTrialStarted,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trialExpired' implicitly has an '... Remove this comment to see the full error message
  trialExpired,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openCancelPlanModal' implicitly h... Remove this comment to see the full error message
  openCancelPlanModal,
}) => {
  const account = useSelector((state) => state.account)
  const { subscriptionIsCanceled, trialEndDate, type } = planDetails

  let cancelCopy

  if (trialEndDate && product === 'publish') {
    cancelCopy = `After canceling, your ${toTitleCase(
      product,
    )} trial will immediately revert to a free plan. If you would like to continue with your cancellation, please confirm below.`
  } else if (trialEndDate) {
    cancelCopy = `If you would like to continue with your cancellation, please confirm below.`
  } else {
    cancelCopy = `After canceling, you can continue to enjoy your paid plan until the end of your current cycle. You will no longer be billed. If you would like to continue with your cancellation, please confirm below.`
  }

  const isPaidPlan = type === 'main'

  const hideSecondaryButtonStates =
    !noTrialStarted && !trialExpired && !subscriptionIsCanceled

  return (
    <React.Fragment>
      <Styles.ActionsWrapper>
        {(product === 'publish' || product === 'analyze') &&
          hideSecondaryButtonStates && (
            <CancelPlan
              disabled={!account.currentOrganization.canEdit}
              buttonLabel={isPaidPlan ? 'Cancel Plan' : 'Cancel Trial'}
              planType={isPaidPlan ? 'paid' : 'trial'}
              product={product}
              copy={cancelCopy}
            />
          )}

        {/* Button that opens change plan modal in Account */}
        {updatePlanCallback && (
          // @ts-expect-error TS(2740) FIXME: Type '{ disabled: boolean; type: string; label: an... Remove this comment to see the full error message
          <Button
            disabled={!account.currentOrganization.canEdit}
            type="primary"
            label={
              noTrialStarted
                ? 'Update Plan'
                : subscriptionIsCanceled
                ? 'Update Plan'
                : buttonLabel
            }
            onClick={updatePlanCallback}
          />
        )}
      </Styles.ActionsWrapper>
    </React.Fragment>
  )
}

Footer.propTypes = {
  buttonLabel: PropTypes.string,
  noTrialStarted: PropTypes.bool,
  product: PropTypes.string,
  planDetails: PropTypes.shape({
    cycle: PropTypes.string,
    subscriptionIsCanceled: PropTypes.bool,
    trialEndDate: PropTypes.number,
    type: PropTypes.string,
  }),
  trialExpired: PropTypes.bool,
  updatePlanCallback: PropTypes.func,
  openCancelPlanModal: PropTypes.func,
}

Footer.defaultProps = {
  buttonLabel: 'Update Plan',
}

export default Footer
