import React, { useState } from 'react'
import { Avatar, DropdownMenu, Text } from '@bufferapp/ui'
import { grayDark } from '@bufferapp/ui/style/colors'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'
import { useSplitEnabled } from '@bufferapp/features'

import StatusSection from '../StatusSection'

import { ChannelRow, TextStyled } from './styles'

import { fallbackAvatarUrl } from '../../ChannelsPage/ChannelsPage'
import { EditChannelModal } from '../EditChannelModal'

import type { CardRowProps } from './types'
import { useGetMenuItems } from './hooks/useGetMenuItems'

export const CardRow = ({
  avatar,
  canEdit,
  descriptor,
  id,
  isDisconnected,
  isLocked,
  name,
  displayName,
  selectChannelToRemove,
  service,
  serviceId,
  setShowChannelUnlockingModal,
  type,
  location,
  serverUrl,
  needsRefreshing,
}: CardRowProps): JSX.Element => {
  const [showEditChannelModal, setShowEditChannelModal] = useState(false)
  const { isEnabled: isHideTwitterConnectionEnabled } = useSplitEnabled(
    'hide-twitter-connection-option',
  )

  const shouldDisableRefreshButton =
    service.toLowerCase() === 'twitter' && isHideTwitterConnectionEnabled

  const onRefreshConnectionClick = (cta: string): void => {
    if (window.appshell?.actions) {
      const { actions } = window.appshell || {}

      actions.connectChannel({
        selectedService: service.toLowerCase(),
        isRefreshingConnection: true,
        cta,
        name,
        selectedRefreshChannelId: id,
      })
    }
  }

  const menuItems = useGetMenuItems({
    isLocked,
    canEdit,
    service,
    type,
    shouldDisableRefresh: shouldDisableRefreshButton,
    onEditClick: () => {
      setShowEditChannelModal(true)
    },
    onUnlockClick: () => {
      setShowChannelUnlockingModal(true)
    },
    onRefreshConnectionClick: (): void => {
      onRefreshConnectionClick(
        `account-channels-channelRefreshDropdown-${service.toLowerCase()}-1`,
      )
    },
    selectChannelToRemove: () =>
      selectChannelToRemove({ id, name, avatar, service, type, descriptor }),
  })

  return (
    <ChannelRow data-testid={`cardrow-${id}`} isLocked={isLocked}>
      <Avatar
        src={avatar}
        alt={displayName || name}
        network={service}
        fallbackUrl={fallbackAvatarUrl}
      />
      <div>
        <TextStyled type="p" title={name}>
          {displayName || name}
        </TextStyled>
        {location && <Text type="p">{location}</Text>}
        {serverUrl && <Text type="p">{serverUrl}</Text>}
        <Text type="p">{descriptor}</Text>
      </div>
      <StatusSection
        canEdit={canEdit}
        isDisconnected={isDisconnected}
        isLocked={isLocked}
        onRefreshConnectionClick={(): void =>
          onRefreshConnectionClick(
            `account-channels-channelRefreshButton-${service.toLowerCase()}-1`,
          )
        }
        needsRefreshing={needsRefreshing}
      />
      <div>
        <DropdownMenu
          // @ts-expect-error TS(2322) FIXME: Type '{ ariaLabel: string; ariaLabelPopup: string;... Remove this comment to see the full error message
          ariaLabel="Channel Menu"
          ariaLabelPopup="Channel Menu"
          menubarItem={<MoreIcon color={grayDark} />}
          items={menuItems}
          xPosition="right"
        />
      </div>
      {showEditChannelModal && (
        <EditChannelModal
          id={id}
          service={service}
          serviceId={serviceId}
          type={type}
          name={name}
          onClose={(): void => setShowEditChannelModal(false)}
        />
      )}
    </ChannelRow>
  )
}
