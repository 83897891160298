import styled from 'styled-components'

export const TagWrapper = styled.span<{
  size: string
  weight: string
  backgroundColor: string
  uppercase: boolean
  color: string
}>`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  background-color: ${(props) => props.backgroundColor};
  padding: 5px 8px;
  border-radius: 4px;
  text-transform: ${(props) => props.uppercase && 'uppercase'};
  color: ${(props) => props.color};

  svg {
    margin-left: 8px;
    margin-right: 2px;
  }
`
