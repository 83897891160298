// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
import { actionTypes as creditCardActionTypes } from '../credit-card'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('BILLING', {
  GET_PLAN_INFORMATION: 'GET_PLAN_INFORMATION',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CHANGE_BILLING_CYCLE: 'CHANGE_BILLING_CYCLE',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  REMOVE_ERROR: 'REMOVE_ERROR',
  REENABLE_SUBSCRIPTION: 'REENABLE_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  OPEN_CANCEL_SUBSCRIPTION_MODAL: 'OPEN_CANCEL_SUBSCRIPTION_MODAL',
  CLOSE_CANCEL_SUBSCRIPTION_MODAL: 'CLOSE_CANCEL_SUBSCRIPTION_MODAL',
  CLOSE_CHURN_SURVEY_MODAL: 'CLOSE_CHURN_SURVEY_MODAL',
  SUBMIT_CHURN_SURVEY: 'SUBMIT_CHURN_SURVEY',
  OPEN_CHANGE_BILLING_CYCLE_MODAL: 'OPEN_CHANGE_BILLING_CYCLE_MODAL',
  CLOSE_CHANGE_BILLING_CYCLE_MODAL: 'CLOSE_CHANGE_BILLING_CYCLE_MODAL',
  CHANGE_PLAN: 'CHANGE_PLAN',
  OPEN_CHANGE_PLAN_MODAL: 'OPEN_CHANGE_PLAN_MODAL',
  CLOSE_CHANGE_PLAN_MODAL: 'CLOSE_CHANGE_PLAN_MODAL',
})

const initialState = {
  modalOpen: false,
  cancelSubscriptionModalOpen: false,
  churnSurveyModalOpen: false,
  changeBillingCycleModalOpen: false,
  changePlanModalOpen: false,
  changingPlan: false,
  changingBillingCycle: false,
  validating: false,
  reenabling: false,
  cancelling: false,
  plan: null,
  trial: null,
  canSwitchPlans: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelling: true,
      }
    case actionTypes.OPEN_CANCEL_SUBSCRIPTION_MODAL:
      return {
        ...state,
        cancelSubscriptionModalOpen: true,
      }
    case actionTypes.CLOSE_CANCEL_SUBSCRIPTION_MODAL:
      return {
        ...state,
        cancelSubscriptionModalOpen: false,
      }
    case `cancelSubscription_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        churnSurveyModalOpen: true,
      }
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        modalOpen: true,
      }
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false,
      }
    case `getBillingInformation_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...action.result,
        churnSurveyModalOpen: state.churnSurveyModalOpen,
        canSwitchPlans: ['8-profiles-pro', '10-profiles'].includes(
          action.result.plan && action.result.plan.id,
        ),
        changePlanModalOpen: state.changePlanModalOpen,
      }
    case creditCardActionTypes.CC_CARD_SAVE_SUCCESS:
      return {
        ...state,
        validating: false,
        modalOpen: false,
      }
    case creditCardActionTypes.CC_CARD_SAVE_FAILURE:
      return {
        ...state,
        validating: false,
      }
    case actionTypes.REENABLE_SUBSCRIPTION:
      return {
        ...state,
        reenabling: true,
      }
    case creditCardActionTypes.CC_CARD_SAVE_REQUEST:
      return {
        ...state,
        validating: true,
      }
    case actionTypes.VALIDATION_ERROR:
      return {
        ...state,
        validating: false,
        error: action.message,
      }
    case actionTypes.REMOVE_ERROR:
      return {
        ...state,
        error: null,
      }
    case actionTypes.CLOSE_CHURN_SURVEY_MODAL:
      return {
        ...state,
        churnSurveyModalOpen: false,
      }
    case actionTypes.SUBMIT_CHURN_SURVEY:
      return {
        ...state,
        churnSurveyModalOpen: false,
      }
    case actionTypes.OPEN_CHANGE_BILLING_CYCLE_MODAL:
      return {
        ...state,
        changeBillingCycleModalOpen: true,
      }
    case actionTypes.CLOSE_CHANGE_BILLING_CYCLE_MODAL:
      return {
        ...state,
        changeBillingCycleModalOpen: false,
      }
    case actionTypes.CHANGE_BILLING_CYCLE:
      return {
        ...state,
        changingBillingCycle: true,
      }
    case actionTypes.OPEN_CHANGE_PLAN_MODAL:
      return {
        ...state,
        changePlanModalOpen: true,
      }
    case actionTypes.CLOSE_CHANGE_PLAN_MODAL:
      return {
        ...state,
        changePlanModalOpen: false,
      }
    case `changePlan_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        changingPlan: true,
      }
    case `changePlan_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        changingPlan: false,
      }
    case `changePlan_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        changePlanModalOpen: false,
        changingPlan: false,
      }
    case `changeBillingCycle_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...action.result,
        changeBillingCycleModalOpen: false,
        changingBillingCycle: false,
      }
    default:
      return state
  }
}

export const actions = {
  getBillingInformation: () => ({
    type: actionTypes.GET_PLAN_INFORMATION,
  }),
  openModal: () => ({
    type: actionTypes.OPEN_MODAL,
  }),
  closeModal: () => ({
    type: actionTypes.CLOSE_MODAL,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  validationError: (message) => ({
    type: actionTypes.VALIDATION_ERROR,
    message,
  }),
  removeError: () => ({
    type: actionTypes.REMOVE_ERROR,
  }),
  reenableSubscription: () => ({
    type: actionTypes.REENABLE_SUBSCRIPTION,
  }),
  openCancelSubscriptionModal: () => ({
    type: actionTypes.OPEN_CANCEL_SUBSCRIPTION_MODAL,
  }),
  closeCancelSubscriptionModal: () => ({
    type: actionTypes.CLOSE_CANCEL_SUBSCRIPTION_MODAL,
  }),
  cancelSubscription: () => ({
    type: actionTypes.CANCEL_SUBSCRIPTION,
  }),
  closeChurnSurveyModal: () => ({
    type: actionTypes.CLOSE_CHURN_SURVEY_MODAL,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  submitChurnSurvey: (data) => ({
    type: actionTypes.SUBMIT_CHURN_SURVEY,
    data,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'cycle' implicitly has an 'any' type.
  changeBillingCycle: (cycle) => ({
    type: actionTypes.CHANGE_BILLING_CYCLE,
    cycle,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'cycle' implicitly has an 'any' type.
  openChangeBillingCycleModal: (cycle) => ({
    type: actionTypes.OPEN_CHANGE_BILLING_CYCLE_MODAL,
    cycle,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'cycle' implicitly has an 'any' type.
  closeChangeBillingCycleModal: (cycle) => ({
    type: actionTypes.CLOSE_CHANGE_BILLING_CYCLE_MODAL,
    cycle,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
  changePlan: (plan, cycle) => ({
    type: actionTypes.CHANGE_PLAN,
    plan,
    cycle,
  }),
  openChangePlanModal: () => ({
    type: actionTypes.OPEN_CHANGE_PLAN_MODAL,
  }),
  closeChangePlanModal: () => ({
    type: actionTypes.CLOSE_CHANGE_PLAN_MODAL,
  }),
}
