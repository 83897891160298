type MenuItemsProps = {
  isLocked: boolean
  canEdit: boolean
  shouldDisableRefresh: boolean
  onEditClick: () => void
  onUnlockClick: () => void
  onRefreshConnectionClick: () => void
  selectChannelToRemove: () => void
  service: string
  type: string
}

type MenuItem = {
  id: string
  title: string
  onItemClick: () => void
  disabled: boolean
}

export function useGetMenuItems({
  isLocked,
  canEdit,
  shouldDisableRefresh,
  onEditClick,
  onUnlockClick,
  onRefreshConnectionClick,
  selectChannelToRemove,
  service,
  type,
}: MenuItemsProps): MenuItem[] {
  const lockedMenuItem = [
    {
      id: '1',
      title: 'Unlock Channel',
      onItemClick: () => onUnlockClick(),
      disabled: !canEdit,
    },
  ]

  const refreshMenuItem = [
    {
      id: '2',
      title: 'Refresh Connection',
      onItemClick: () => onRefreshConnectionClick(),
      disabled: !canEdit || shouldDisableRefresh,
    },
  ]

  const disconnectMenuItem = [
    {
      id: '3',
      title: 'Disconnect Channel',
      onItemClick: () => selectChannelToRemove(),
      disabled: !canEdit,
    },
  ]

  const editMenuItem = [
    {
      id: '4',
      title: 'Edit Group',
      onItemClick: onEditClick,
      disabled: !canEdit,
    },
  ]

  const isFacebookGroup = service === 'facebook' && type === 'group'

  if (isFacebookGroup) {
    return [
      ...(isLocked ? lockedMenuItem : []),
      ...editMenuItem,
      ...disconnectMenuItem,
    ]
  }

  return [
    ...(isLocked ? lockedMenuItem : []),
    ...(!isLocked ? refreshMenuItem : []),
    ...disconnectMenuItem,
  ]
}
