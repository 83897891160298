export const analyze = {
  options: [
    {
      name: 'Pro',
      stripePlanName: 'pro',
      price: { month: 35, year: 336 },
      features: [
        '8 social accounts',
        'In-depth social analytics',
        'Strategy recommendations',
        'Unlimited reports',
      ],
      summary: '',
      image: 'https://static.buffer.com/images/account/analyze-pro@2x.jpg',
    },
    {
      name: 'Premium',
      stripePlanName: 'premium',
      price: { month: 50, year: 480 },
      features: [
        '10 social accounts',
        'Includes Stories analytics',
        'Strategy recommendations',
        'Unlimited reports',
        'Report white labeling',
      ],
      summary: '',
      image: 'https://static.buffer.com/images/account/analyze-premium@2x.jpg',
    },
  ],
  moreInfo: 'https://buffer.com/pricing/analyze',
}

export const publish = {
  options: [
    {
      name: 'Pro',
      stripePlanName: 'pro',
      price: { month: 15, year: 144 },
      features: ['8 social accounts', '100 scheduled posts', '1 user'],
      summary: '',
      image: 'https://static.buffer.com/images/account/publish-pro@2x.png',
    },
    {
      name: 'Premium',
      stripePlanName: 'solo_premium_business',
      price: { month: 35, year: 336 },
      features: ['8 social accounts', '2,000 scheduled posts', '1 user'],
      summary: '',
      image: 'https://static.buffer.com/images/account/publish-premium@2x.png',
    },
    {
      name: 'Premium',
      stripePlanName: 'premium_business',
      price: { month: 65, year: 663 },
      features: ['8 social accounts', '2,000 scheduled posts', '2 users'],
      summary: '',
      image: 'https://static.buffer.com/images/account/publish-premium@2x.png',
    },
    {
      name: 'Business',
      stripePlanName: 'small',
      price: { month: 99, year: 1010 },
      features: ['25 social accounts', '2,000 scheduled posts', '6 users'],
      summary: '',
      image: 'https://static.buffer.com/images/account/publish-business@2x.png',
    },
  ],
  moreInfo: 'https://buffer.com/pricing/publish',
}

const plans = { analyze, publish }

export const SOLO_PREMIUM_BUSINESS = {
  year: 'publish_premium_y_336_201910',
  month: 'publish_premium_m_35_201910',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
const isSoloPremiumBusiness = (plan) =>
  [SOLO_PREMIUM_BUSINESS.year, SOLO_PREMIUM_BUSINESS.month].includes(plan)

// @ts-expect-error TS(7006) FIXME: Parameter 'planName' implicitly has an 'any' type.
const publishPlansWithout = function (planName) {
  return plans.publish.options.filter(
    (plan) => plan.stripePlanName !== planName,
  )
}
// @ts-expect-error TS(7006) FIXME: Parameter 'product' implicitly has an 'any' type.
export const getPlansForProduct = (product, currentPlan = {}) => {
  if (product !== 'publish') {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return plans[product].options
  }

  // @ts-expect-error TS(2339) FIXME: Property 'product' does not exist on type '{}'.
  return isSoloPremiumBusiness(currentPlan.product)
    ? publishPlansWithout('premium_business')
    : publishPlansWithout('solo_premium_business')
}

export default plans
