import { connect } from 'react-redux'
import DeleteAccount from './components/DeleteAccount'
import reducer, { actions, actionTypes } from './reducer'
import middleware from './middleware'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'deleteAccount' does not exist on type 'D... Remove this comment to see the full error message
    deleteAccount: state.deleteAccount,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'password' implicitly has an 'any' type.
    verifyAccount: (password) => dispatch(actions.verifyAccount(password)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'password' implicitly has an 'any' type.
    deleteMyAccount: (password, reason) =>
      dispatch(actions.deleteMyAccount(password, reason)),
    showConfirmModal: () => dispatch(actions.showConfirmModal()),
    hideAllModals: () => dispatch(actions.hideAllModals()),
    clearError: () => dispatch(actions.clearError()),
  }),
)(DeleteAccount)

export { reducer, actions, actionTypes, middleware }
