import React from 'react'
import dayjs from 'dayjs'
import { Strong } from '../Text'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const PlanDate = ({ children }) => (
  <Strong>{dayjs.unix(children).format('MMMM, Do YYYY')}</Strong>
)

export default PlanDate
