import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import { blue } from '@bufferapp/ui/style/colors'

export const NoticeContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  svg {
    margin-top: 1px;
  }

  div[type='primary'] {
    margin-left: 1rem;
  }
`

export const TextStyled = styled(Text)`
  padding-bottom: 8px;
`

export const CustomLink = styled.a`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
  text-decoration: none;
  color: ${blue};
`
