import React from 'react'

import { formatCurrency } from '../../../../../../utils/numbers'
import type { Discount } from './types'

export const handleBalanceCopy = (balance: number): JSX.Element => {
  if (balance > 0) {
    return (
      <li>
        <strong>${formatCurrency(balance)} will be added </strong>
        to your upcoming invoice.
      </li>
    )
  }
  return (
    <li>
      You have a credit of <strong>${formatCurrency(Math.abs(balance))}</strong>{' '}
      which will be automatically applied to your upcoming invoices.
    </li>
  )
}

export const handleDiscountCopy = (discount: Discount): string | undefined => {
  let discountCopy

  if (discount.type === 'percent') {
    discountCopy = `${discount.value}% off`
  } else if (discount.type === 'amount') {
    discountCopy = `$${formatCurrency(discount.value)}`
  }

  switch (discount.duration) {
    case 'forever':
      discountCopy += ' forever'
      break
    case 'once':
      discountCopy += ' on your next invoice'
      break
    case 'repeating':
      discountCopy += ` for ${discount.durationInMonths} months`
      break
  }
  return discountCopy
}
