import styled from 'styled-components'

import { boxShadow } from '../../account-components/style/colors'

export const FormRadioCard = styled.label<{
  selected: boolean
}>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #b8b8b8;
  cursor: pointer;
  padding: 1rem;
  position: relative;
  font-size: 14px;

  &.checked {
    border: 10px solid black;
  }

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  box-shadow: ${(props) => (props.selected ? `0 0 0 3px ${boxShadow}` : '')};

  input:checked ~ .radio-button {
    background-color: #2c4bff;
    border: 1px solid #2c4bff;
    border-radius: 100px;

    &:after {
      content: '';
      position: absolute;
      height: 4px;
      width: 4px;
      top: 5px;
      left: 5px;
      background-color: #ffffff;
      border-radius: 100%;
    }
  }

  .radio-card-header {
    margin-bottom: 1.5rem;
  }

  .details {
    display: grid;
    flex-direction: column;
    user-select: none;
    width: 100%;

    img {
      max-height: 125px;
      margin: 0 auto 2rem;
    }

    span {
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }

    ul {
      list-style: none;
      margin-bottom: 1rem;
    }

    li {
      margin-bottom: 0.5rem;
    }
  }

  .price {
    font-size: 1.5rem;
  }

  .title {
    margin-left: 1.5rem;
    font-weight: 500;
  }

  .radio-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #e8e8e8;

    &:after {
      content: '';
      position: absolute;
    }
  }
`
