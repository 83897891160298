import styled from 'styled-components'
import { grayLight, grayLighter } from '@bufferapp/ui/style/colors'

export const Body = styled.div`
  padding: 16px;
  width: 100%;
  background-color: ${grayLighter};
  border-top: ${`1px solid ${grayLight}`};
  border-bottom: ${`1px solid ${grayLight}`};

  p {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  ul {
    padding: 16px;
  }
`

export const ModalHeader = styled.div`
  padding: 0 16px 16px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  svg {
    fill: #b8b8b8;
  }
`

export const LeftIconWrapper = styled.div`
  padding: 4px 8px 0 0;

  svg {
    fill: #e0364f;
  }
`

export const IllustrationWrapper = styled.div`
  height: 230px;
  background-image: url('https://s3.amazonaws.com/static.buffer.com/images/account/fb-missing-permissions%402x.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`
