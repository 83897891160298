import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Count, ProgressBarStyled } from './styles'

const ChannelsCounter = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'connectedChannels' implicitly has... Remove this comment to see the full error message
  connectedChannels,
  // @ts-expect-error TS(7031) FIXME: Binding element 'availableChannels' implicitly has... Remove this comment to see the full error message
  availableChannels,
  // @ts-expect-error TS(7031) FIXME: Binding element 'lockedChannels' implicitly has an... Remove this comment to see the full error message
  lockedChannels,
}) => {
  const hasLockedChannels = lockedChannels > 0

  // Show progress bar if more than 10 channels
  const shouldShowProgressBar = availableChannels > 10

  // division used to calculate how much green will fill in the progress bar
  const greenProgressBarFraction = hasLockedChannels
    ? availableChannels / connectedChannels
    : connectedChannels / availableChannels
  const percentage = Math.ceil(greenProgressBarFraction * 100).toString() + '%'

  // gets the highest/lowest number between connected and available channels
  const channelsMaxCount = Math.max(connectedChannels, availableChannels)
  const channelsMinCount = Math.min(connectedChannels, availableChannels)

  return (
    <Wrapper>
      {shouldShowProgressBar ? (
        <ProgressBarStyled progress={percentage} />
      ) : (
        Array.from({ length: channelsMaxCount }, (_, i) => (
          <Count channelsMinCount={channelsMinCount} key={i}></Count>
        ))
      )}
    </Wrapper>
  )
}

ChannelsCounter.propTypes = {
  connectedChannels: PropTypes.number,
  availableChannels: PropTypes.number,
  lockedChannels: PropTypes.number,
}

export default ChannelsCounter
