// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('MY_ACCOUNT', {
  VERIFY_ACCOUNT: 'VERIFY_ACCOUNT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  SHOW_CONFIRM_MODAL: 'SHOW_CONFIRM_MODAL',
  HIDE_ALL_MODALS: 'HIDE_ALL_MODALS',
  CLEAR_ERROR: 'CLEAR_ERROR',
})

const initialState = {
  isDeletingAccount: false,
  isVerifying: false,
  showConfirmModal: false,
  showDeleteModal: false,
  verifyAccountSuccess: false,
  deleteAccountSuccess: false,
  password: '',
  error: '',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VERIFY_ACCOUNT:
      return {
        ...state,
        isVerifying: true,
        password: action.password,
      }
    case `verifyAccount_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        isConfirmingPassword: false,
        verifyAccountSuccess: true,
        showConfirmModal: false,
        showDeleteModal: true,
      }
    case `verifyAccount_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        isConfirmingPassword: false,
        error: 'Whoops! The password seems to be wrong. Mind trying again?',
      }
    case actionTypes.DELETE_ACCOUNT:
      return {
        ...state,
        isDeletingAccount: true,
      }
    case `deleteMyAccount_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        isDeletingAccount: false,
        deleteAccountSuccess: true,
      }
    case `deleteMyAccount_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        isDeletingAccount: false,
        deleteAccountSuccess: false,
        error: 'Whoops something went wrong! Please try again.',
      }
    case actionTypes.SHOW_CONFIRM_MODAL:
      return {
        ...state,
        showConfirmModal: true,
      }
    case actionTypes.HIDE_ALL_MODALS:
      return {
        ...state,
        verifyAccountSuccess: false,
        deleteAccountSuccess: false,
        showConfirmModal: false,
        showDeleteModal: false,
        password: '',
      }
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: '',
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'password' implicitly has an 'any' type.
  deleteMyAccount: (password, reason) => ({
    type: actionTypes.DELETE_ACCOUNT,
    password,
    reason,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'password' implicitly has an 'any' type.
  verifyAccount: (password) => ({
    type: actionTypes.VERIFY_ACCOUNT,
    password,
  }),
  showConfirmModal: () => ({
    type: actionTypes.SHOW_CONFIRM_MODAL,
  }),
  hideAllModals: () => ({
    type: actionTypes.HIDE_ALL_MODALS,
  }),
  clearError: () => ({
    type: actionTypes.CLEAR_ERROR,
  }),
}
