import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Notification } from '@bufferapp/ui'

import CancelSubscriptionModal from '../Modals/CancelSubscriptionModal'
import DeprecatedChurnSurveyModal from '../../../cancel-plan/components/DeprecatedChurnSurveyModal'
import ChangeBillingCycleModal from '../Modals/ChangeBillingCycleModal'
import ChangePlanModal from '../Modals/ChangePlanModal'
import PaymentDetailsModal from '../Modals/PaymentDetailsModal'
import Plan from '../Plan'
import CardDetails from '../CardDetails'

const Spacer = styled.section`
  margin-bottom: 24px;
`

export interface BillingDetailsProps {
  getBillingInformation: any
  getBillingDetails: any
  error: any
  removeError: any
  validating: any
  modalOpen: any
  closeModal: any
  plan: any
  trial: any
  cardDetails: any
  canceledAt: any
  reenabling: any
  changeCardDetails: any
  addBillingDetails: any
  changeBillingCycle: any
  openChangeBillingCycleModal: any
  changePlan: any
  closeChangeBillingCycleModal: any
  changeBillingCycleModalOpen: any
  changingBillingCycle: any
  reenableSubscription: any
  cancelling: any
  cancelSubscriptionModalOpen: any
  openCancelSubscriptionModal: any
  closeCancelSubscriptionModal: any
  cancelSubscription: any
  cancelAt: any
  churnSurveyModalOpen: any
  closeChurnSurveyModal: any
  submitChurnSurvey: any
  changePlanModalOpen: any
  openChangePlanModal: any
  closeChangePlanModal: any
  changingPlan: any
  canSwitchPlans: any
  hasAnalyzeAccessThroughPublishSubscription: any
}

class BillingDetails extends React.Component<BillingDetailsProps> {
  static propTypes: {
    getBillingInformation: any
    getBillingDetails: any
    error: any
    removeError: any
    validating: any
    modalOpen: any
    closeModal: any
    plan: any
    trial: any
    cardDetails: any
    canceledAt: any
    reenabling: any
    changeCardDetails: any
    addBillingDetails: any
    changeBillingCycle: any
    openChangeBillingCycleModal: any
    changePlan: any
    closeChangeBillingCycleModal: any
    changeBillingCycleModalOpen: any
    changingBillingCycle: any
    reenableSubscription: any
    cancelling: any
    cancelSubscriptionModalOpen: any
    openCancelSubscriptionModal: any
    closeCancelSubscriptionModal: any
    cancelSubscription: any
    cancelAt: any
    churnSurveyModalOpen: any
    closeChurnSurveyModal: any
    submitChurnSurvey: any
    changePlanModalOpen: any
    openChangePlanModal: any
    closeChangePlanModal: any
    changingPlan: any
    canSwitchPlans: any
    hasAnalyzeAccessThroughPublishSubscription: any
  }

  componentDidMount() {
    this.props.getBillingInformation()
    this.props.getBillingDetails()
  }

  render() {
    const {
      error,
      removeError,
      validating,
      modalOpen,
      closeModal,
      plan,
      trial,
      cardDetails,
      canceledAt,
      reenabling,
      changeCardDetails,
      addBillingDetails,
      changeBillingCycle,
      openChangeBillingCycleModal,
      changePlan,
      closeChangeBillingCycleModal,
      changeBillingCycleModalOpen,
      changingBillingCycle,
      reenableSubscription,
      cancelling,
      cancelSubscriptionModalOpen,
      openCancelSubscriptionModal,
      closeCancelSubscriptionModal,
      cancelSubscription,
      cancelAt,
      churnSurveyModalOpen,
      closeChurnSurveyModal,
      submitChurnSurvey,
      changePlanModalOpen,
      openChangePlanModal,
      closeChangePlanModal,
      changingPlan,
      canSwitchPlans,
      hasAnalyzeAccessThroughPublishSubscription,
    } = this.props

    if (!plan) {
      return null
    }

    return (
      <Fragment>
        {error && <Notification text={error} onClose={removeError} />}
        <Plan
          openChangeBillingCycleModal={openChangeBillingCycleModal}
          changePlan={openChangePlanModal}
          addBillingDetails={addBillingDetails}
          cardDetails={cardDetails}
          trial={trial}
          plan={plan}
          cancelAt={cancelAt}
          canceledAt={canceledAt}
          canSwitchPlans={canSwitchPlans}
          hasAnalyzeAccessThroughPublishSubscription={
            hasAnalyzeAccessThroughPublishSubscription
          }
        />
        <Spacer />
        {!hasAnalyzeAccessThroughPublishSubscription && (
          <CardDetails
            cardDetails={cardDetails}
            cancelAt={cancelAt}
            canceledAt={canceledAt}
            openCancelSubscriptionModal={openCancelSubscriptionModal}
            changeCardDetails={changeCardDetails}
            reenabling={reenabling}
            reenableSubscription={reenableSubscription}
            trialing={!!trial}
          />
        )}
        {modalOpen && (
          <PaymentDetailsModal
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            validating={validating}
            plan={plan}
            cardDetails={cardDetails}
            closeModal={closeModal}
            trial={trial}
          />
        )}
        {cancelSubscriptionModalOpen && (
          <CancelSubscriptionModal
            trialing={trial}
            activeUntil={plan.periodEnd}
            cancelling={cancelling}
            close={closeCancelSubscriptionModal}
            cancelSubscription={cancelSubscription}
          />
        )}
        {churnSurveyModalOpen && (
          <DeprecatedChurnSurveyModal
            submit={submitChurnSurvey}
            dismiss={closeChurnSurveyModal}
            product={plan.name}
            isOnTrial={!!trial}
          />
        )}
        {changeBillingCycleModalOpen && (
          <ChangeBillingCycleModal
            changingBillingCycle={changingBillingCycle}
            close={closeChangeBillingCycleModal}
            changeBillingCycle={changeBillingCycle}
            plan={plan}
          />
        )}
        {changePlanModalOpen && (
          <ChangePlanModal
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            changingPlan={changingPlan}
            changePlan={changePlan}
            close={closeChangePlanModal}
            plan={plan}
          />
        )}
      </Fragment>
    )
  }
}

BillingDetails.propTypes = {
  getBillingInformation: PropTypes.any,
  getBillingDetails: PropTypes.any,
  error: PropTypes.any,
  removeError: PropTypes.any,
  validating: PropTypes.any,
  modalOpen: PropTypes.any,
  closeModal: PropTypes.any,
  plan: PropTypes.any,
  trial: PropTypes.any,
  cardDetails: PropTypes.any,
  canceledAt: PropTypes.any,
  reenabling: PropTypes.any,
  changeCardDetails: PropTypes.any,
  addBillingDetails: PropTypes.any,
  changeBillingCycle: PropTypes.any,
  openChangeBillingCycleModal: PropTypes.any,
  changePlan: PropTypes.any,
  closeChangeBillingCycleModal: PropTypes.any,
  changeBillingCycleModalOpen: PropTypes.any,
  changingBillingCycle: PropTypes.any,
  reenableSubscription: PropTypes.any,
  cancelling: PropTypes.any,
  cancelSubscriptionModalOpen: PropTypes.any,
  openCancelSubscriptionModal: PropTypes.any,
  closeCancelSubscriptionModal: PropTypes.any,
  cancelSubscription: PropTypes.any,
  cancelAt: PropTypes.any,
  churnSurveyModalOpen: PropTypes.any,
  closeChurnSurveyModal: PropTypes.any,
  submitChurnSurvey: PropTypes.any,
  changePlanModalOpen: PropTypes.any,
  openChangePlanModal: PropTypes.any,
  closeChangePlanModal: PropTypes.any,
  changingPlan: PropTypes.any,
  canSwitchPlans: PropTypes.any,
  hasAnalyzeAccessThroughPublishSubscription: PropTypes.any,
}

export default BillingDetails
