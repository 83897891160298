import styled from 'styled-components'
import { grayDarker } from '../style/colors'

export const InputWrapper = styled.div`
  margin-top: 16px;
  cursor: pointer;
`

export const InputStyled = styled.input`
  margin-right: 8px;
  cursor: pointer;
`

export const LabelStyled = styled.label`
  color: ${grayDarker};
  font-size: 14px;
  cursor: pointer;
  user-select: none;
`

export const TextStrongStyled = styled.strong`
  vertical-align: top;
`
