import gql from 'graphql-tag'

export const CHANNELS_CONNECT = gql`
  mutation ChannelsConnect($channels: [ChannelWithCredentialsInput!]) {
    channelsConnect(channels: $channels)
  }
`

export const CHANNELS_CONNECT_WITH_RESPONSE = gql`
  mutation ChannelsConnectWithResponse(
    $channels: [ChannelWithCredentialsInput!]
  ) {
    channelsConnectWithResponse(channels: $channels) {
      ... on ChannelsConnectResponse {
        channels {
          id
          isLocked
        }
      }
      ... on ChannelsConnectError {
        userFriendlyMessage
        cause
      }
    }
  }
`

export const REMOVE_CHANNELS = gql`
  mutation ChannelsRemove($channelIds: [String!]) {
    channelsRemove(channelIds: $channelIds)
  }
`

export const CHANNELS_LOCK = gql`
  mutation ChannelsLock(
    $organizationId: String
    $channelIdsToLock: [ID!]
    $channelIdsToUnlock: [ID!]
  ) {
    channelsLock(
      organizationId: $organizationId
      channelIdsToLock: $channelIdsToLock
      channelIdsToUnlock: $channelIdsToUnlock
    )
  }
`

export const CHANNELS_AUTHORIZATION = gql`
  mutation channelsAuthorization(
    $channelAuthorization: ChannelAuthorizationInput!
  ) {
    channelsAuthorization(channelAuthorization: $channelAuthorization) {
      ... on ChannelAuthorizationResponse {
        channel {
          id
        }
      }
      ... on ChannelAuthorizationError {
        userFriendlyMessage
      }
    }
  }
`

export const CHANNELS_FOR_CONNECTION = gql`
  mutation channelsForConnection(
    $credentialsForChannels: CredentialsForChannelsInput!
  ) {
    channelsForConnection(credentialsForChannels: $credentialsForChannels) {
      ... on CredentialsForChannelsResponse {
        connectableChannels {
          name
          serviceId
          serverUrl
          locationData {
            location
            mapsLink
            googleAccountId
          }
          connectionAvailability
          isLocked
          avatar
        }
      }
      ... on CredentialsForChannelsError {
        cause
      }
    }
  }
`

/** ---------------------------------------
 * New Channel Connection queries 2024
 * - This should replace the need for the above mutations
 * - TODO: remove the above queries after refactor can be fully roleld out
 *    - CHANNELS_AUTHORIZATION
 *    - CHANNELS_CONNECT
 *    - CHANNELS_CONNECT_WITH_RESPONSE
 *
 */

/**
 *
 * Single channel connection mutations
 *
 */

// This handles authorizing the thirdparty authentication was succeful and then connects the single type channel to Buffer
export const AUTHORIZE_AND_CONNECT_SINGLE_TYPE_CHANNEL = gql`
  mutation authorizeAndConnectSingleTypeChannel(
    $channelAuthorization: ChannelAuthorizationInput!
  ) {
    channelsAuthorization(channelAuthorization: $channelAuthorization) {
      ... on ChannelAuthorizationResponse {
        channel {
          id
        }
      }
      ... on ChannelAuthorizationError {
        userFriendlyMessage
      }
    }
  }
`

// This can be used for refreshing your channel connection of channel.
export const CHANNEL_CONNECTION_REFRESH = gql`
  mutation channelConnectionRefresh($input: ChannelConnectionRefreshInput!) {
    channelConnectionRefresh(input: $input) {
      ... on ChannelConnectionRefreshResponse {
        channels {
          id
          isLocked
          isNew
        }
      }
      ... on ChannelConnectionCannotStealChannelError {
        message
      }
      ... on ChannelConnectionNoChannelsError {
        message
      }
      ... on UnauthorizedError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`

// This can be used for connecting a single type channel or also refreshing any type of channel.
// If a multi type channel is selected for reconnecting this mutation will also reconnect all other channel types for that service
export const AUTHORIZE_CHANNELS = gql`
  mutation authorizeChannels(
    $channelAuthorization: ChannelAuthorizationInput!
  ) {
    authorizeChannels(channelAuthorization: $channelAuthorization) {
      ... on AuthorizeChannelsResponse {
        channels {
          id
          isLocked
          isNew
        }
      }
      ... on AuthorizeChannelsError {
        userFriendlyMessage
        errorType
      }
    }
  }
`

/**
 *
 * Multi channel connection mutations
 *
 */

// This handles requesting all the available channels connected to this services account.
// This is used for multi type chanels
// Will return a list of optional channels for the user to choose to connect.
export const REQUEST_AVAILABLE_CHANNELS_FOR_ACCOUNT = gql`
  mutation requestAvailableChannelsForServiceAccount(
    $input: ChannelConnectionListInput!
  ) {
    channelConnectionList(input: $input) {
      ... on ChannelConnectionListResponse {
        channels {
          name
          serviceId
          serverUrl
          locationData {
            location
            mapsLink
            googleAccountId
          }
          connectionAvailability
          isLocked
          avatar
          displayName
          type
        }
      }
      ... on ChannelConnectionNoChannelsError {
        message
      }
      ... on UnauthorizedError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`

// This handles connecting channels to Buffer
export const CONNECT_CHANNELS = gql`
  mutation connectChannels($channels: [ChannelWithCredentialsInput!]) {
    channelsConnectWithResponse(channels: $channels) {
      ... on ChannelsConnectResponse {
        channels {
          id
          isLocked
          isNew
        }
      }
      ... on ChannelsConnectError {
        userFriendlyMessage
        cause
      }
    }
  }
`

export const UPDATE_CUSTOM_CHANNEL = gql`
  mutation UpdateCustomChannel($input: UpdateCustomChannelInput!) {
    updateCustomChannel(input: $input) {
      ... on UpdateCustomChannelResponse {
        channel {
          id
        }
      }
      ... on CustomChannelError {
        message
      }
    }
  }
`
