import React from 'react'
import PropTypes from 'prop-types'
import { Button, Text } from '@bufferapp/ui'
import { SkeletonText } from '../../../../../../../../account-components'

import { formatDate } from '../../../../../../utils/dates'

import * as Styles from '../../styles'
import { numberWithCurrency } from '../../../../utils/numberWithCurrency'

// @ts-expect-error TS(7006) FIXME: Parameter 'invoices' implicitly has an 'any' type.
const isLastInvoice = (invoices, index) => invoices.length - 1 === index

// @ts-expect-error TS(7031) FIXME: Binding element 'invoices' implicitly has an 'any'... Remove this comment to see the full error message
const Table = ({ invoices, isLoading }) => {
  return (
    <Styles.Table>
      <tbody>
        <Styles.TableRow border>
          <Styles.TableHeader size={50}>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
            <Text>Date</Text>
          </Styles.TableHeader>
          <Styles.TableHeader size={35}>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
            <Text>Amount</Text>
          </Styles.TableHeader>
          <Styles.TableHeader size={15}></Styles.TableHeader>
        </Styles.TableRow>
        {isLoading && (
          <React.Fragment>
            <Styles.TableRow border={true}>
              <Styles.TableColumn size={40}>
                <SkeletonText margin="0.2rem 0" width="40%" />
              </Styles.TableColumn>
              <Styles.TableColumn size={30}>
                <SkeletonText margin="0.2rem 0" width="27%" />
              </Styles.TableColumn>
              <Styles.TableColumn size={30} align="right">
                <SkeletonText margin="0.2rem 0" width="100%" />
              </Styles.TableColumn>
            </Styles.TableRow>

            <Styles.TableRow border={true}>
              <Styles.TableColumn size={40}>
                <SkeletonText margin="0.2rem 0" width="40%" />
              </Styles.TableColumn>
              <Styles.TableColumn size={30}>
                <SkeletonText margin="0.2rem 0" width="27%" />
              </Styles.TableColumn>
              <Styles.TableColumn size={30} align="right">
                <SkeletonText margin="0.2rem 0" width="100%" />
              </Styles.TableColumn>
            </Styles.TableRow>

            <Styles.TableRow border={true}>
              <Styles.TableColumn size={40}>
                <SkeletonText margin="0.2rem 0" width="40%" />
              </Styles.TableColumn>
              <Styles.TableColumn size={30}>
                <SkeletonText margin="0.2rem 0" width="27%" />
              </Styles.TableColumn>
              <Styles.TableColumn size={30} align="right">
                <SkeletonText margin="0.2rem 0" width="100%" />
              </Styles.TableColumn>
            </Styles.TableRow>
          </React.Fragment>
        )}

        {!isLoading &&
          // @ts-expect-error TS(7006) FIXME: Parameter 'invoice' implicitly has an 'any' type.
          invoices.map((invoice, index) => (
            <Styles.TableRow
              border={!isLastInvoice(invoices, index)}
              key={index}
            >
              <Styles.TableColumn size={40}>
                <Text type="p">{formatDate(invoice.node.date)}</Text>
              </Styles.TableColumn>
              <Styles.TableColumn size={30}>
                <Text type="p">
                  {numberWithCurrency(
                    invoice.node.amount,
                    invoice.node.currency,
                  )}
                </Text>
              </Styles.TableColumn>
              <Styles.TableColumn size={30} align="right">
                {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; onClick: () =>... Remove this comment to see the full error message */}
                <Button
                  type="text"
                  size="small"
                  onClick={() =>
                    window.open(invoice.node.downloadUrl, '_blank')
                  }
                  label="View"
                />
              </Styles.TableColumn>
            </Styles.TableRow>
          ))}
      </tbody>
    </Styles.Table>
  )
}

Table.propTypes = {
  invoices: PropTypes.array,
  isLoading: PropTypes.bool,
}

export default Table
