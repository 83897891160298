import styled from 'styled-components'
import { blue, green, red, grayDark } from '@bufferapp/ui/style/colors'
import { Text } from '@bufferapp/ui'
import { grayLight } from '../../../../styles'

export const ModalBody = styled.div`
  width: 100%;
  padding: 0 1rem;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
`

export const DetailsWrapper = styled.div`
  margin-top: 1rem;
  h3 {
    font-size: 1rem;
    margin-bottom: 0rem;
  }

  p:last-child {
    font-size: 0.75rem;
    color: ${grayDark};
    margin-top: 1rem;
  }

  a {
    color: ${blue};
    text-decoration: none;
  }
`

export const TaxStatusText = styled(Text)`
  margin-bottom: 1rem;
`
export const VerifiedStatus = styled.span<{
  status: string
}>`
  text-transform: capitalize;
  padding-left: 5px;
  color: ${(props): string => (props.status === 'verified' ? green : red)};
`

export const AddressBlockWrapper = styled.div`
  h3 {
    font-size: 1rem;
  }
  border-bottom: 1px solid ${grayLight};

  button {
    color: ${blue};
  }
`

export const AddressWrapper = styled.div`
  margin-bottom: 1rem;
`

export const BusinessInformationFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const TextCta = styled.a`
  cursor: pointer;
`
