import React from 'react'
import { Text, Modal, Button } from '@bufferapp/ui'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import { H3 } from '../Text'
import { Wrapper, ModalContent, ModalFooter, Title } from './Modal'

dayjs.extend(advancedFormat)

const CancelSubscriptionModal = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'trialing' implicitly has an 'any'... Remove this comment to see the full error message
  trialing,
  // @ts-expect-error TS(7031) FIXME: Binding element 'activeUntil' implicitly has an 'a... Remove this comment to see the full error message
  activeUntil,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cancelSubscription' implicitly ha... Remove this comment to see the full error message
  cancelSubscription,
  // @ts-expect-error TS(7031) FIXME: Binding element 'close' implicitly has an 'any' ty... Remove this comment to see the full error message
  close,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cancelling' implicitly has an 'an... Remove this comment to see the full error message
  cancelling,
}) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; action: {}; }' is not a... Remove this comment to see the full error message
  <Modal action={{}}>
    <Wrapper>
      <Title>
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
        <Text>
          <H3>
            Are you sure you want to cancel your{' '}
            {trialing ? 'trial' : 'subscription'}?
          </H3>
        </Text>
      </Title>
      <ModalContent>
        {!trialing && (
          // @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message
          <Text>
            If you cancel your subscription today your Analyze account will
            remain active until{' '}
            {dayjs.unix(activeUntil).format('MMMM Do, YYYY')}
          </Text>
        )}
        {trialing && (
          // @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message
          <Text>
            If you cancel your subscription your Analyze account will be
            disabled immediately.
          </Text>
        )}
      </ModalContent>
      <ModalFooter>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
        <Button type="text" label="No I Don't Want To Cancel" onClick={close} />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
        <Button
          type="primary"
          label={cancelling ? 'One moment...' : 'Cancel Subscription'}
          onClick={cancelSubscription}
          disabled={cancelling}
        />
      </ModalFooter>
    </Wrapper>
  </Modal>
)

export default CancelSubscriptionModal
