import React from 'react'
import PropTypes from 'prop-types'

import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'callback' implicitly has an 'any'... Remove this comment to see the full error message
const FormRadioCard = ({ callback, children, name, title, selected }) => {
  return (
    <Styles.FormRadioCard onClick={callback} selected={selected}>
      <div className="radio-card-header">
        <input type="radio" id={title} name={name} />
        <div className="radio-button" />
        <div className="title">{title}</div>
      </div>
      {children}
    </Styles.FormRadioCard>
  )
}

FormRadioCard.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  name: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.string,
}

export default FormRadioCard
