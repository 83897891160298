import styled from 'styled-components'

export const CardWrapper = styled.div<{
  itemCount: number
}>`
  width: 100%;
  display: grid;
  /* grid-template-columns dynamically adjusts card sizing based on the number of items
  we need to style */
  grid-template-columns: ${(props) => '1fr '.repeat(props.itemCount)};
  grid-gap: 1rem;
  padding: 1rem;
  background-color: #f5f5f5;
`
