import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 515px;
`

export const Heading = styled.div`
  padding: 20px 20px;
`

export const Subheading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  max-height: 250px;
  overflow-y: scroll;
`

export const CopyWrapper = styled.div`
  padding: 20px 20px 0;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`
