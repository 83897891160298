export function handleChannelConnectionSuccessModalFlow(
  channelIds: Array<string>,
): void {
  const { MODALS, actions } = window?.appshell || {}
  actions &&
    actions.openModal(MODALS.channelConnectionSuccessModal, {
      channelIds,
      ctaButton: 'connectChannel',
      ctaView: 'connectChannel',
      ctaLocation: 'channels',
      ctaVersion: '1',
      upgradePathName: 'connectChannel-upgrade',
    })
}
