const helpCenterUrl =
  'https://support.buffer.com/?openContact=true&utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more'

function updateHelpScoutBeacon(currentPath = '/'): void {
  if (window.Beacon) {
    window.Beacon('event', {
      type: 'page-viewed',
      url: document.location.href,
      title: `${document.title}: ${currentPath}`,
    })
    window.Beacon('suggest')

    if (currentPath === '/billing') {
      window.Beacon('config', {
        messaging: {
          chatEnabled: true,
        },
      })
    } else {
      window.Beacon('config', {
        messaging: {
          chatEnabled: false,
        },
      })
    }
  }
}

function displayBeaconChatOptions(): void {
  if (window.Beacon) {
    window.Beacon('config', {
      messaging: {
        chatEnabled: true,
      },
    })

    window.Beacon('navigate', '/answer/')
    window.Beacon('navigate', '/ask/')
    window.Beacon('close')
    window.Beacon('open')
  } else {
    window.open(helpCenterUrl, '_blank')
  }
}

function openLegacyMigrationForm(): void {
  if (window.Beacon) {
    window.Beacon('config', {
      messaging: {
        contactForm: {
          customFieldsEnabled: false,
        },
      },
    })

    window.Beacon('prefill', {
      subject: 'Moving from a legacy plan to a current plan',
    })
    window.Beacon('navigate', '/ask/message/')
    window.Beacon('open')
  } else {
    window.open(helpCenterUrl, '_blank')
  }
}

export const HelpScoutBeacon = {
  updateHelpScoutBeacon,
  openLegacyMigrationForm,
  displayBeaconChatOptions,
}
