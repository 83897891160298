import React, { Fragment } from 'react'
import { Text } from '@bufferapp/ui'

import { Grey, Strong } from '../Text'
import Date from './Date'

// @ts-expect-error TS(7031) FIXME: Binding element 'daysRemaining' implicitly has an ... Remove this comment to see the full error message
const DaysRemainingOnTrial = ({ daysRemaining, name }) => (
  <Fragment>
    You have{' '}
    <Strong>
      {daysRemaining} {daysRemaining > 1 ? 'days' : 'day'}
    </Strong>{' '}
    remaining on your <Strong>{name} trial</Strong>
  </Fragment>
)

// @ts-expect-error TS(7031) FIXME: Binding element 'canceledAt' implicitly has an 'an... Remove this comment to see the full error message
const SubscriptionEnded = ({ canceledAt }) => (
  <Fragment>
    Your last Analyze subscription ended on <Date>{canceledAt}</Date>. To regain
    access, re-enable your subscription.
  </Fragment>
)

const PlanInformation = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'daysRemaining' implicitly has an ... Remove this comment to see the full error message
  daysRemaining,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cancelAt' implicitly has an 'any'... Remove this comment to see the full error message
  cancelAt,
  // @ts-expect-error TS(7031) FIXME: Binding element 'canceledAt' implicitly has an 'an... Remove this comment to see the full error message
  canceledAt,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cardDetails' implicitly has an 'a... Remove this comment to see the full error message
  cardDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'amount' implicitly has an 'any' t... Remove this comment to see the full error message
  amount,
  // @ts-expect-error TS(7031) FIXME: Binding element 'interval' implicitly has an 'any'... Remove this comment to see the full error message
  interval,
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  name,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasAnalyzeAccessThroughPublishSub... Remove this comment to see the full error message
  hasAnalyzeAccessThroughPublishSubscription,
}) => {
  let content
  if (hasAnalyzeAccessThroughPublishSubscription) {
    content = 'Your Analyze access is included in your Publish subscription.'
  } else if (canceledAt) {
    content = <SubscriptionEnded key={1} canceledAt={canceledAt} />
  } else if (cancelAt) {
    return (
      // @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message
      <Text>
        <Grey>
          Your Analyze subscription will end on <Date>{cancelAt}</Date>. To
          continue accessing it after that date, re-enable your subscription.
        </Grey>
      </Text>
    )
  } else if (daysRemaining) {
    content = [
      <DaysRemainingOnTrial
        key={2}
        daysRemaining={daysRemaining}
        name={name}
      />,
    ]
    if (cardDetails) {
      content.push(
        <Fragment key={3}>
          {' '}
          and will be billed{' '}
          <Strong>
            ${amount}/{interval}
          </Strong>{' '}
          once the trial finishes.
        </Fragment>,
      )
    }
  } else {
    content = (
      <Fragment>
        You are paying{' '}
        <Strong>
          ${amount}/{interval}
        </Strong>{' '}
        on the <Strong>{name} Plan</Strong>
      </Fragment>
    )
  }

  return (
    // @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message
    <Text>
      <Grey>{content}</Grey>
    </Text>
  )
}

export default PlanInformation
