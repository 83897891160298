// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('CANCEL_PLAN', {
  CANCEL_PLAN_REQUEST: 'CANCEL_PLAN_REQUEST',
  OPEN_CANCEL_PLAN_MODAL: 'OPEN_CANCEL_PLAN_MODAL',
  CLOSE_CANCEL_PLAN_MODAL: 'CLOSE_CANCEL_PLAN_MODAL',
  SHOW_CANCEL_PLAN_NOTIFICATION: 'SHOW_CANCEL_PLAN_NOTIFICATION',
  RESET_CANCEL_PLAN_NOTIFICATION: 'RESET_CANCEL_PLAN_NOTIFICATION',
  CLOSE_CHURN_SURVEY_MODAL: 'CLOSE_CHURN_SURVEY_MODAL',
  OPEN_CHURN_SURVEY_MODAL: 'OPEN_CHURN_SURVEY_MODAL',
  SUBMIT_CHURN_SURVEY: 'SUBMIT_CHURN_SURVEY',
})

export const initialState = {
  isSubmitting: false,
  error: false,
  showModal: false,
  productModal: '',
  notificationMessage: '',
  churnSurveyModalOpen: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CANCEL_PLAN_REQUEST:
      return {
        ...state,
        error: false,
        isSubmitting: true,
      }
    case `cancelPlan_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        isSubmitting: false,
      }
    case `cancelPlan_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        error: true,
        isSubmitting: false,
      }
    case actionTypes.OPEN_CANCEL_PLAN_MODAL:
      return {
        ...state,
        showModal: true,
        productModal: action.product,
      }
    case actionTypes.CLOSE_CANCEL_PLAN_MODAL:
      return {
        ...state,
        showModal: false,
        productModal: '',
        error: false,
      }
    case actionTypes.CLOSE_CHURN_SURVEY_MODAL:
      return {
        ...state,
        churnSurveyModalOpen: false,
        showModal: false,
        productModal: '',
        error: false,
      }
    case actionTypes.OPEN_CHURN_SURVEY_MODAL:
      return {
        ...state,
        churnSurveyModalOpen: true,
      }
    case actionTypes.SHOW_CANCEL_PLAN_NOTIFICATION:
      return {
        ...state,
        notificationMessage: action.message,
      }
    case actionTypes.RESET_CANCEL_PLAN_NOTIFICATION:
      return {
        ...state,
        notificationMessage: '',
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
  cancelPlan: ({ product, planType }) => ({
    type: actionTypes.CANCEL_PLAN_REQUEST,
    product,
    planType,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
  openCancelPlanModal: ({ product }) => ({
    type: actionTypes.OPEN_CANCEL_PLAN_MODAL,
    product,
  }),
  closeCancelPlanModal: () => ({
    type: actionTypes.CLOSE_CANCEL_PLAN_MODAL,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  showNotification: (message) => ({
    type: actionTypes.SHOW_CANCEL_PLAN_NOTIFICATION,
    message,
  }),
  resetNotification: () => ({
    type: actionTypes.RESET_CANCEL_PLAN_NOTIFICATION,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  submitChurnSurvey: (data) => ({
    type: actionTypes.SUBMIT_CHURN_SURVEY,
    data,
  }),
  closeChurnSurveyModal: () => ({
    type: actionTypes.CLOSE_CHURN_SURVEY_MODAL,
  }),
  openChurnSurveyModal: () => ({
    type: actionTypes.OPEN_CHURN_SURVEY_MODAL,
  }),
}
